import { Room } from 'utils/types';

export type MapTemplate = {
	name: string;
	sprite?: string;
	spawn_x: number;
	spawn_y: number;
	rooms: Room[];
};

const GeneratedMaps: MapTemplate[] = [
	{
		name: 'Blank',
		spawn_x: 50,
		spawn_y: 50,
		rooms: [
			{
				room_id: 0,
				type: 69,
				biome_type: 1,
				x: 50,
				y: 50,
				seed: 0,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 4,
				border_direction: -1
			},
			{
				room_id: 1,
				type: 2,
				biome_type: 4,
				x: 50,
				y: 51,
				seed: 0,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 42,
				border_direction: -1
			},
			{
				room_id: 2,
				type: 0,
				biome_type: 3,
				x: 50,
				y: 52,
				seed: 0,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 4,
				border_direction: -1
			}
		]
	},
	{
		name: 'Worldgen 0',
		sprite: 's_mapgen_test_0',
		spawn_x: 46,
		spawn_y: 61,
		rooms: [
			{
				room_id: 0,
				type: 10,
				biome_type: 13,
				x: 21,
				y: 48,
				seed: 96611316,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 1,
				type: 4,
				biome_type: 13,
				x: 22,
				y: 48,
				seed: 97757259,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000095'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 2,
				type: 0,
				biome_type: 29,
				x: 23,
				y: 35,
				seed: 86619103,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 3,
				type: 0,
				biome_type: 29,
				x: 23,
				y: 36,
				seed: 45754590,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 4,
				type: 4,
				biome_type: 29,
				x: 23,
				y: 37,
				seed: 34779128,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF00000000B2'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 5,
				type: 0,
				biome_type: 29,
				x: 23,
				y: 38,
				seed: 53621129,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 6,
				type: 0,
				biome_type: 29,
				x: 23,
				y: 39,
				seed: 58431408,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 7,
				type: 0,
				biome_type: 29,
				x: 23,
				y: 41,
				seed: 35819603,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 8,
				type: 0,
				biome_type: 29,
				x: 23,
				y: 42,
				seed: 94175804,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 9,
				type: 0,
				biome_type: 29,
				x: 23,
				y: 43,
				seed: 76957810,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 10,
				type: 0,
				biome_type: 13,
				x: 23,
				y: 48,
				seed: 20360977,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 11,
				type: 0,
				biome_type: 29,
				x: 24,
				y: 37,
				seed: 13996646,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 12,
				type: 10,
				biome_type: 29,
				x: 24,
				y: 39,
				seed: 23716183,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 13,
				type: 0,
				biome_type: 29,
				x: 24,
				y: 40,
				seed: 52698126,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 14,
				type: 0,
				biome_type: 29,
				x: 24,
				y: 41,
				seed: 57371782,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 15,
				type: 0,
				biome_type: 29,
				x: 24,
				y: 43,
				seed: 34797555,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 16,
				type: 0,
				biome_type: 13,
				x: 24,
				y: 48,
				seed: 75078007,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 17,
				type: 47,
				biome_type: 13,
				x: 24,
				y: 52,
				seed: 78575386,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 13,
				border_direction: -1
			},
			{
				room_id: 18,
				type: 0,
				biome_type: 13,
				x: 24,
				y: 53,
				seed: 98523416,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 19,
				type: 10,
				biome_type: 13,
				x: 24,
				y: 57,
				seed: 52986905,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 20,
				type: 0,
				biome_type: 29,
				x: 25,
				y: 39,
				seed: 68174966,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 21,
				type: 0,
				biome_type: 29,
				x: 25,
				y: 41,
				seed: 89619821,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 22,
				type: 0,
				biome_type: 29,
				x: 25,
				y: 43,
				seed: 6665007,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 23,
				type: 10,
				biome_type: 29,
				x: 25,
				y: 44,
				seed: 56682054,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 24,
				type: 0,
				biome_type: 13,
				x: 25,
				y: 48,
				seed: 71481458,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 25,
				type: 26,
				biome_type: 13,
				x: 25,
				y: 53,
				seed: 12641499,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 26,
				type: 0,
				biome_type: 13,
				x: 25,
				y: 54,
				seed: 1725220,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 27,
				type: 0,
				biome_type: 13,
				x: 25,
				y: 55,
				seed: 44298614,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 28,
				type: 0,
				biome_type: 13,
				x: 25,
				y: 56,
				seed: 49382512,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 29,
				type: 0,
				biome_type: 13,
				x: 25,
				y: 57,
				seed: 47786036,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 30,
				type: 20,
				biome_type: 13,
				x: 25,
				y: 58,
				seed: 27887339,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 31,
				type: 0,
				biome_type: 29,
				x: 26,
				y: 38,
				seed: 71475770,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 32,
				type: 0,
				biome_type: 29,
				x: 26,
				y: 39,
				seed: 96429720,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 33,
				type: 27,
				biome_type: 29,
				x: 26,
				y: 40,
				seed: 31287384,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 34,
				type: 0,
				biome_type: 29,
				x: 26,
				y: 41,
				seed: 94947,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 35,
				type: 0,
				biome_type: 29,
				x: 26,
				y: 43,
				seed: 66200867,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 36,
				type: 0,
				biome_type: 29,
				x: 26,
				y: 46,
				seed: 33338296,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 37,
				type: 0,
				biome_type: 13,
				x: 26,
				y: 48,
				seed: 45811954,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 38,
				type: 0,
				biome_type: 13,
				x: 26,
				y: 51,
				seed: 11502559,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 39,
				type: 0,
				biome_type: 13,
				x: 26,
				y: 52,
				seed: 24041199,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 40,
				type: 10,
				biome_type: 13,
				x: 26,
				y: 53,
				seed: 50220312,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 41,
				type: 0,
				biome_type: 13,
				x: 26,
				y: 56,
				seed: 94485612,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 42,
				type: 4,
				biome_type: 13,
				x: 26,
				y: 58,
				seed: 84585592,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000097'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 43,
				type: 47,
				biome_type: 29,
				x: 27,
				y: 36,
				seed: 11752566,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 29,
				border_direction: -1
			},
			{
				room_id: 44,
				type: 0,
				biome_type: 29,
				x: 27,
				y: 37,
				seed: 10805295,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 45,
				type: 0,
				biome_type: 29,
				x: 27,
				y: 38,
				seed: 29429006,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 46,
				type: 0,
				biome_type: 29,
				x: 27,
				y: 41,
				seed: 75172530,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 47,
				type: 0,
				biome_type: 29,
				x: 27,
				y: 43,
				seed: 85678897,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 48,
				type: 0,
				biome_type: 29,
				x: 27,
				y: 46,
				seed: 3501308,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 49,
				type: 10,
				biome_type: 13,
				x: 27,
				y: 48,
				seed: 33127716,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 50,
				type: 0,
				biome_type: 13,
				x: 27,
				y: 49,
				seed: 71401092,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 51,
				type: 0,
				biome_type: 13,
				x: 27,
				y: 50,
				seed: 84021290,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 52,
				type: 0,
				biome_type: 13,
				x: 27,
				y: 51,
				seed: 25246007,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 53,
				type: 0,
				biome_type: 13,
				x: 27,
				y: 56,
				seed: 85772758,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 54,
				type: 0,
				biome_type: 13,
				x: 27,
				y: 57,
				seed: 15924557,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 55,
				type: 0,
				biome_type: 13,
				x: 27,
				y: 58,
				seed: 23881430,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 56,
				type: 0,
				biome_type: 29,
				x: 28,
				y: 38,
				seed: 40297849,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 57,
				type: 10,
				biome_type: 29,
				x: 28,
				y: 41,
				seed: 84435841,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 58,
				type: 20,
				biome_type: 29,
				x: 28,
				y: 42,
				seed: 63791237,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 59,
				type: 0,
				biome_type: 29,
				x: 28,
				y: 43,
				seed: 36993214,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 60,
				type: 0,
				biome_type: 29,
				x: 28,
				y: 44,
				seed: 84401813,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 61,
				type: 0,
				biome_type: 29,
				x: 28,
				y: 45,
				seed: 6152073,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 62,
				type: 0,
				biome_type: 29,
				x: 28,
				y: 46,
				seed: 75572099,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 63,
				type: 0,
				biome_type: 13,
				x: 28,
				y: 48,
				seed: 90284836,
				flags: '04000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 64,
				type: 20,
				biome_type: 13,
				x: 28,
				y: 51,
				seed: 57628121,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 65,
				type: 10,
				biome_type: 13,
				x: 28,
				y: 57,
				seed: 98498881,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 66,
				type: 0,
				biome_type: 29,
				x: 29,
				y: 37,
				seed: 59320886,
				flags: '08000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 67,
				type: 0,
				biome_type: 29,
				x: 29,
				y: 38,
				seed: 87151514,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 68,
				type: 0,
				biome_type: 29,
				x: 29,
				y: 39,
				seed: 56732191,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 69,
				type: 0,
				biome_type: 29,
				x: 29,
				y: 40,
				seed: 32249948,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 70,
				type: 4,
				biome_type: 29,
				x: 29,
				y: 41,
				seed: 263146,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00081FFFFFFFF00000000AC'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 71,
				type: 10,
				biome_type: 29,
				x: 29,
				y: 46,
				seed: 66278115,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 72,
				type: 0,
				biome_type: 13,
				x: 29,
				y: 49,
				seed: 98264420,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 73,
				type: 10,
				biome_type: 13,
				x: 29,
				y: 50,
				seed: 95096297,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 74,
				type: 4,
				biome_type: 13,
				x: 29,
				y: 51,
				seed: 21696753,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000093'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 75,
				type: 0,
				biome_type: 13,
				x: 29,
				y: 52,
				seed: 76114484,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 76,
				type: 21,
				biome_type: 46,
				x: 29,
				y: 54,
				seed: 92777136,
				flags: '09000',
				is_double: 1,
				master_room_x: 29,
				master_room_y: 54,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 77,
				type: 21,
				biome_type: 46,
				x: 29,
				y: 55,
				seed: 30718193,
				flags: '0B000',
				master_room_x: 29,
				master_room_y: 54,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 13,
				border_direction: 3
			},
			{
				room_id: 78,
				type: 12,
				biome_type: 13,
				x: 29,
				y: 56,
				seed: 89280941,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 46,
				border_direction: -1
			},
			{
				room_id: 79,
				type: 0,
				biome_type: 13,
				x: 29,
				y: 57,
				seed: 76639411,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 80,
				type: 0,
				biome_type: 29,
				x: 30,
				y: 39,
				seed: 64317638,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 81,
				type: 0,
				biome_type: 29,
				x: 30,
				y: 41,
				seed: 78873411,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 82,
				type: 12,
				biome_type: 29,
				x: 30,
				y: 42,
				seed: 51868533,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 83,
				type: 0,
				biome_type: 29,
				x: 30,
				y: 43,
				seed: 52580115,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 84,
				type: 0,
				biome_type: 13,
				x: 30,
				y: 52,
				seed: 27612197,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 85,
				type: 0,
				biome_type: 13,
				x: 30,
				y: 53,
				seed: 99475964,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 1,
				border: 46,
				border_direction: -1
			},
			{
				room_id: 86,
				type: 21,
				biome_type: 46,
				x: 30,
				y: 54,
				seed: 82714093,
				flags: '0E000',
				master_room_x: 29,
				master_room_y: 54,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 13,
				border_direction: 1
			},
			{
				room_id: 87,
				type: 21,
				biome_type: 46,
				x: 30,
				y: 55,
				seed: 69163560,
				flags: '06000',
				master_room_x: 29,
				master_room_y: 54,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 88,
				type: 0,
				biome_type: 29,
				x: 31,
				y: 36,
				seed: 77608808,
				flags: '08000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 89,
				type: 0,
				biome_type: 29,
				x: 31,
				y: 37,
				seed: 27031746,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 90,
				type: 0,
				biome_type: 29,
				x: 31,
				y: 39,
				seed: 14180781,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 91,
				type: 0,
				biome_type: 29,
				x: 31,
				y: 41,
				seed: 44509758,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 92,
				type: 0,
				biome_type: 29,
				x: 31,
				y: 43,
				seed: 65442607,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 93,
				type: 0,
				biome_type: 10,
				x: 31,
				y: 50,
				seed: 78989552,
				flags: '01000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 94,
				type: 0,
				biome_type: 13,
				x: 31,
				y: 52,
				seed: 30552423,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 95,
				type: 26,
				biome_type: 29,
				x: 32,
				y: 37,
				seed: 88511559,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 96,
				type: 0,
				biome_type: 29,
				x: 32,
				y: 38,
				seed: 67722836,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 97,
				type: 10,
				biome_type: 29,
				x: 32,
				y: 39,
				seed: 88525316,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 98,
				type: 0,
				biome_type: 29,
				x: 32,
				y: 40,
				seed: 5883829,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 99,
				type: 20,
				biome_type: 29,
				x: 32,
				y: 41,
				seed: 56477785,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 100,
				type: 0,
				biome_type: 29,
				x: 32,
				y: 43,
				seed: 36207322,
				flags: '0C000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 101,
				type: 63,
				biome_type: 29,
				x: 32,
				y: 44,
				seed: 59608895,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 10,
				border_direction: 3
			},
			{
				room_id: 102,
				type: 0,
				biome_type: 10,
				x: 32,
				y: 45,
				seed: 52571307,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 2,
				border: 29,
				border_direction: 1
			},
			{
				room_id: 103,
				type: 0,
				biome_type: 10,
				x: 32,
				y: 46,
				seed: 27447740,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 104,
				type: 0,
				biome_type: 10,
				x: 32,
				y: 49,
				seed: 60089981,
				flags: '09000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 105,
				type: 0,
				biome_type: 10,
				x: 32,
				y: 50,
				seed: 1573901,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 106,
				type: 0,
				biome_type: 13,
				x: 32,
				y: 52,
				seed: 89552884,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 107,
				type: 0,
				biome_type: 29,
				x: 33,
				y: 37,
				seed: 53363882,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 108,
				type: 0,
				biome_type: 29,
				x: 33,
				y: 40,
				seed: 16665744,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 109,
				type: 4,
				biome_type: 10,
				x: 33,
				y: 46,
				seed: 32560277,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000008E'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 110,
				type: 0,
				biome_type: 10,
				x: 33,
				y: 47,
				seed: 55850162,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 111,
				type: 0,
				biome_type: 10,
				x: 33,
				y: 48,
				seed: 93008026,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00141FFFFFFFF0',
					'00141FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 112,
				type: 20,
				biome_type: 10,
				x: 33,
				y: 49,
				seed: 16423001,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 113,
				type: 10,
				biome_type: 13,
				x: 33,
				y: 52,
				seed: 5206143,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 114,
				type: 63,
				biome_type: 13,
				x: 33,
				y: 53,
				seed: 56572560,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 10,
				border_direction: 3
			},
			{
				room_id: 115,
				type: 65,
				biome_type: 10,
				x: 33,
				y: 54,
				seed: 53356123,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 13,
				border_direction: 1
			},
			{
				room_id: 116,
				type: 0,
				biome_type: 10,
				x: 33,
				y: 55,
				seed: 16047693,
				flags: '03000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0', '00141FFFFFFFF0'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 117,
				type: 4,
				biome_type: 10,
				x: 33,
				y: 58,
				seed: 90888851,
				flags: '01000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000008D'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 118,
				type: 70,
				biome_type: 29,
				x: 34,
				y: 36,
				seed: 93126345,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 119,
				type: 4,
				biome_type: 29,
				x: 34,
				y: 37,
				seed: 63402420,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00081FFFFFFFF00000000B3'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 120,
				type: 0,
				biome_type: 29,
				x: 34,
				y: 38,
				seed: 95417139,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 121,
				type: 0,
				biome_type: 29,
				x: 34,
				y: 39,
				seed: 83750825,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 122,
				type: 0,
				biome_type: 29,
				x: 34,
				y: 40,
				seed: 46352984,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 123,
				type: 0,
				biome_type: 10,
				x: 34,
				y: 46,
				seed: 86502731,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 124,
				type: 4,
				biome_type: 10,
				x: 34,
				y: 55,
				seed: 51787146,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000008A'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 125,
				type: 20,
				biome_type: 10,
				x: 34,
				y: 56,
				seed: 73621890,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 126,
				type: 0,
				biome_type: 10,
				x: 34,
				y: 57,
				seed: 74129954,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 127,
				type: 10,
				biome_type: 10,
				x: 34,
				y: 58,
				seed: 85559265,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 128,
				type: 0,
				biome_type: 10,
				x: 34,
				y: 59,
				seed: 80865475,
				flags: '02000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 129,
				type: 21,
				biome_type: 16,
				x: 35,
				y: 32,
				seed: 17622615,
				flags: '09000',
				is_double: 1,
				master_room_x: 35,
				master_room_y: 32,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 130,
				type: 21,
				biome_type: 16,
				x: 35,
				y: 33,
				seed: 91694106,
				flags: '03000',
				master_room_x: 35,
				master_room_y: 32,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 131,
				type: 0,
				biome_type: 29,
				x: 35,
				y: 40,
				seed: 94488674,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 132,
				type: 0,
				biome_type: 29,
				x: 35,
				y: 41,
				seed: 17114113,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 133,
				type: 0,
				biome_type: 10,
				x: 35,
				y: 44,
				seed: 40694504,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00141FFFFFFFF0',
					'00141FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 134,
				type: 0,
				biome_type: 10,
				x: 35,
				y: 45,
				seed: 93414692,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 135,
				type: 0,
				biome_type: 10,
				x: 35,
				y: 46,
				seed: 81949097,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 136,
				type: 21,
				biome_type: 12,
				x: 35,
				y: 49,
				seed: 17313176,
				flags: '09000',
				is_double: 1,
				master_room_x: 35,
				master_room_y: 49,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 137,
				type: 21,
				biome_type: 12,
				x: 35,
				y: 50,
				seed: 71687946,
				flags: '0B000',
				master_room_x: 35,
				master_room_y: 49,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 10,
				border_direction: 3
			},
			{
				room_id: 138,
				type: 0,
				biome_type: 10,
				x: 35,
				y: 51,
				seed: 39271667,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 1,
				border: 12,
				border_direction: -1
			},
			{
				room_id: 139,
				type: 0,
				biome_type: 10,
				x: 35,
				y: 52,
				seed: 15774725,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0', '00141FFFFFFFF0'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 140,
				type: 12,
				biome_type: 10,
				x: 35,
				y: 55,
				seed: 53746488,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 141,
				type: 21,
				biome_type: 16,
				x: 36,
				y: 32,
				seed: 24917944,
				flags: '0C000',
				master_room_x: 35,
				master_room_y: 32,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 142,
				type: 21,
				biome_type: 16,
				x: 36,
				y: 33,
				seed: 43298900,
				flags: '0E000',
				master_room_x: 35,
				master_room_y: 32,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 25,
				border_direction: 3
			},
			{
				room_id: 143,
				type: 20,
				biome_type: 25,
				x: 36,
				y: 34,
				seed: 4333219,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 16,
				border_direction: 1
			},
			{
				room_id: 144,
				type: 0,
				biome_type: 25,
				x: 36,
				y: 35,
				seed: 50791220,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 145,
				type: 4,
				biome_type: 25,
				x: 36,
				y: 38,
				seed: 64735134,
				flags: '01000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF00000000A0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 146,
				type: 0,
				biome_type: 10,
				x: 36,
				y: 43,
				seed: 91024787,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 147,
				type: 0,
				biome_type: 10,
				x: 36,
				y: 44,
				seed: 85742973,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 148,
				type: 0,
				biome_type: 10,
				x: 36,
				y: 46,
				seed: 86844713,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 149,
				type: 26,
				biome_type: 10,
				x: 36,
				y: 47,
				seed: 11291565,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 150,
				type: 0,
				biome_type: 10,
				x: 36,
				y: 48,
				seed: 96166672,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00141FFFFFFFF0'
				],
				difficulty: 1,
				border: 12,
				border_direction: -1
			},
			{
				room_id: 151,
				type: 21,
				biome_type: 12,
				x: 36,
				y: 49,
				seed: 42820572,
				flags: '0E000',
				master_room_x: 35,
				master_room_y: 49,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 10,
				border_direction: 1
			},
			{
				room_id: 152,
				type: 21,
				biome_type: 12,
				x: 36,
				y: 50,
				seed: 57794034,
				flags: '06000',
				master_room_x: 35,
				master_room_y: 49,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 153,
				type: 0,
				biome_type: 10,
				x: 36,
				y: 52,
				seed: 74279993,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0', '00141FFFFFFFF0'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 154,
				type: 0,
				biome_type: 10,
				x: 36,
				y: 53,
				seed: 42795574,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 155,
				type: 10,
				biome_type: 10,
				x: 36,
				y: 54,
				seed: 10285221,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 156,
				type: 27,
				biome_type: 10,
				x: 36,
				y: 55,
				seed: 60585310,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 157,
				type: 0,
				biome_type: 25,
				x: 37,
				y: 35,
				seed: 13372558,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 158,
				type: 0,
				biome_type: 25,
				x: 37,
				y: 36,
				seed: 24182267,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 159,
				type: 0,
				biome_type: 25,
				x: 37,
				y: 37,
				seed: 45772757,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 160,
				type: 10,
				biome_type: 25,
				x: 37,
				y: 38,
				seed: 65951825,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 161,
				type: 20,
				biome_type: 25,
				x: 37,
				y: 39,
				seed: 74533793,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 162,
				type: 0,
				biome_type: 25,
				x: 37,
				y: 40,
				seed: 24294867,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 163,
				type: 0,
				biome_type: 25,
				x: 37,
				y: 41,
				seed: 62035695,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 164,
				type: 0,
				biome_type: 10,
				x: 37,
				y: 44,
				seed: 60658752,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 165,
				type: 1,
				biome_type: 10,
				x: 37,
				y: 45,
				seed: 67498337,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 166,
				type: 0,
				biome_type: 10,
				x: 37,
				y: 46,
				seed: 5348873,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 167,
				type: 0,
				biome_type: 10,
				x: 37,
				y: 52,
				seed: 55555068,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 168,
				type: 0,
				biome_type: 25,
				x: 38,
				y: 35,
				seed: 30733278,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 169,
				type: 0,
				biome_type: 25,
				x: 38,
				y: 39,
				seed: 36150711,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 170,
				type: 0,
				biome_type: 25,
				x: 38,
				y: 41,
				seed: 40459601,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 171,
				type: 0,
				biome_type: 10,
				x: 38,
				y: 43,
				seed: 39294157,
				flags: '08000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0', '00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 172,
				type: 0,
				biome_type: 10,
				x: 38,
				y: 44,
				seed: 82884942,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 173,
				type: 0,
				biome_type: 10,
				x: 38,
				y: 52,
				seed: 12325947,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 174,
				type: 0,
				biome_type: 25,
				x: 39,
				y: 29,
				seed: 58725256,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 175,
				type: 0,
				biome_type: 25,
				x: 39,
				y: 30,
				seed: 62985339,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 176,
				type: 0,
				biome_type: 25,
				x: 39,
				y: 31,
				seed: 70225458,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 177,
				type: 0,
				biome_type: 25,
				x: 39,
				y: 35,
				seed: 97476586,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 178,
				type: 0,
				biome_type: 25,
				x: 39,
				y: 38,
				seed: 54586501,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 179,
				type: 0,
				biome_type: 25,
				x: 39,
				y: 39,
				seed: 39629282,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 180,
				type: 0,
				biome_type: 25,
				x: 39,
				y: 40,
				seed: 42456527,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 181,
				type: 27,
				biome_type: 25,
				x: 39,
				y: 41,
				seed: 36297246,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 182,
				type: 4,
				biome_type: 8,
				x: 39,
				y: 46,
				seed: 58030438,
				flags: '09000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000007A'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 183,
				type: 0,
				biome_type: 8,
				x: 39,
				y: 47,
				seed: 5401719,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 184,
				type: 0,
				biome_type: 8,
				x: 39,
				y: 48,
				seed: 61666953,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 185,
				type: 0,
				biome_type: 10,
				x: 39,
				y: 52,
				seed: 92751389,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 186,
				type: 10,
				biome_type: 10,
				x: 39,
				y: 53,
				seed: 21437954,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 23,
				border_direction: 3
			},
			{
				room_id: 187,
				type: 0,
				biome_type: 23,
				x: 39,
				y: 54,
				seed: 82879161,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: 10,
				border_direction: 1
			},
			{
				room_id: 188,
				type: 0,
				biome_type: 23,
				x: 39,
				y: 55,
				seed: 47055889,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 189,
				type: 1,
				biome_type: 23,
				x: 39,
				y: 56,
				seed: 89414826,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 23,
				border_direction: -1
			},
			{
				room_id: 190,
				type: 41,
				biome_type: 23,
				x: 39,
				y: 57,
				seed: 44154698,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 191,
				type: 10,
				biome_type: 25,
				x: 40,
				y: 29,
				seed: 53990761,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 192,
				type: 0,
				biome_type: 25,
				x: 40,
				y: 31,
				seed: 26439000,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 193,
				type: 0,
				biome_type: 25,
				x: 40,
				y: 34,
				seed: 4233624,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 194,
				type: 0,
				biome_type: 25,
				x: 40,
				y: 35,
				seed: 24746687,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 195,
				type: 0,
				biome_type: 25,
				x: 40,
				y: 36,
				seed: 38799204,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 196,
				type: 10,
				biome_type: 25,
				x: 40,
				y: 37,
				seed: 41607340,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 197,
				type: 0,
				biome_type: 25,
				x: 40,
				y: 38,
				seed: 87061661,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 198,
				type: 10,
				biome_type: 25,
				x: 40,
				y: 41,
				seed: 72205429,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 199,
				type: 20,
				biome_type: 25,
				x: 40,
				y: 42,
				seed: 28142933,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 200,
				type: 0,
				biome_type: 8,
				x: 40,
				y: 44,
				seed: 66858168,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 201,
				type: 0,
				biome_type: 8,
				x: 40,
				y: 45,
				seed: 48278799,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 202,
				type: 0,
				biome_type: 8,
				x: 40,
				y: 46,
				seed: 39262200,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 203,
				type: 4,
				biome_type: 23,
				x: 40,
				y: 57,
				seed: 90493085,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000070'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 204,
				type: 10,
				biome_type: 25,
				x: 41,
				y: 30,
				seed: 83154807,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 205,
				type: 0,
				biome_type: 25,
				x: 41,
				y: 31,
				seed: 8127052,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 206,
				type: 0,
				biome_type: 25,
				x: 41,
				y: 32,
				seed: 63139687,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 207,
				type: 0,
				biome_type: 25,
				x: 41,
				y: 33,
				seed: 16582160,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 208,
				type: 0,
				biome_type: 25,
				x: 41,
				y: 34,
				seed: 62054869,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 209,
				type: 0,
				biome_type: 25,
				x: 41,
				y: 37,
				seed: 31895235,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 210,
				type: 4,
				biome_type: 8,
				x: 41,
				y: 46,
				seed: 60494441,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000077'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 211,
				type: 41,
				biome_type: 23,
				x: 41,
				y: 48,
				seed: 72129244,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 212,
				type: 41,
				biome_type: 23,
				x: 41,
				y: 50,
				seed: 40712006,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 213,
				type: 10,
				biome_type: 23,
				x: 41,
				y: 55,
				seed: 90626042,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 214,
				type: 0,
				biome_type: 23,
				x: 41,
				y: 56,
				seed: 44433977,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 215,
				type: 0,
				biome_type: 23,
				x: 41,
				y: 57,
				seed: 2905209,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 216,
				type: 47,
				biome_type: 25,
				x: 42,
				y: 26,
				seed: 24221067,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 25,
				border_direction: -1
			},
			{
				room_id: 217,
				type: 0,
				biome_type: 25,
				x: 42,
				y: 27,
				seed: 48680169,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 218,
				type: 0,
				biome_type: 25,
				x: 42,
				y: 28,
				seed: 94246793,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 219,
				type: 0,
				biome_type: 25,
				x: 42,
				y: 29,
				seed: 98691008,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 220,
				type: 0,
				biome_type: 25,
				x: 42,
				y: 30,
				seed: 79258617,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 221,
				type: 0,
				biome_type: 25,
				x: 42,
				y: 34,
				seed: 5494908,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 222,
				type: 26,
				biome_type: 25,
				x: 42,
				y: 37,
				seed: 84956739,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: 26,
				border_direction: -1
			},
			{
				room_id: 223,
				type: 10,
				biome_type: 8,
				x: 42,
				y: 46,
				seed: 9578320,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 224,
				type: 26,
				biome_type: 23,
				x: 42,
				y: 48,
				seed: 99148476,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 225,
				type: 0,
				biome_type: 23,
				x: 42,
				y: 49,
				seed: 32272681,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 226,
				type: 10,
				biome_type: 23,
				x: 42,
				y: 50,
				seed: 10650757,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 227,
				type: 0,
				biome_type: 23,
				x: 42,
				y: 51,
				seed: 9971277,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 228,
				type: 27,
				biome_type: 23,
				x: 42,
				y: 52,
				seed: 47489716,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 229,
				type: 20,
				biome_type: 23,
				x: 42,
				y: 53,
				seed: 72864283,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 230,
				type: 4,
				biome_type: 23,
				x: 42,
				y: 54,
				seed: 35134204,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000006E'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 231,
				type: 0,
				biome_type: 23,
				x: 42,
				y: 55,
				seed: 44465602,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 232,
				type: 10,
				biome_type: 25,
				x: 43,
				y: 34,
				seed: 15897156,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 233,
				type: 21,
				biome_type: 26,
				x: 43,
				y: 37,
				seed: 27842370,
				flags: '0D000',
				is_double: 1,
				master_room_x: 43,
				master_room_y: 37,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 25,
				border_direction: 2
			},
			{
				room_id: 234,
				type: 21,
				biome_type: 26,
				x: 43,
				y: 38,
				seed: 4799819,
				flags: '03000',
				master_room_x: 43,
				master_room_y: 37,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 235,
				type: 4,
				biome_type: 25,
				x: 43,
				y: 40,
				seed: 68420211,
				flags: '09000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF00000000A1'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 236,
				type: 1,
				biome_type: 25,
				x: 43,
				y: 41,
				seed: 94712979,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 25,
				border_direction: -1
			},
			{
				room_id: 237,
				type: 10,
				biome_type: 25,
				x: 43,
				y: 42,
				seed: 58929289,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 8,
				border_direction: 3
			},
			{
				room_id: 238,
				type: 66,
				biome_type: 8,
				x: 43,
				y: 43,
				seed: 98903202,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 25,
				border_direction: 1
			},
			{
				room_id: 239,
				type: 0,
				biome_type: 8,
				x: 43,
				y: 44,
				seed: 7136598,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 240,
				type: 0,
				biome_type: 8,
				x: 43,
				y: 45,
				seed: 60048800,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 241,
				type: 4,
				biome_type: 8,
				x: 43,
				y: 46,
				seed: 33020748,
				flags: '07000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000079'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 242,
				type: 0,
				biome_type: 23,
				x: 43,
				y: 51,
				seed: 54467852,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 243,
				type: 0,
				biome_type: 23,
				x: 43,
				y: 53,
				seed: 99224371,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 244,
				type: 0,
				biome_type: 49,
				x: 44,
				y: 29,
				seed: 47584593,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 245,
				type: 10,
				biome_type: 49,
				x: 44,
				y: 31,
				seed: 47348897,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 246,
				type: 0,
				biome_type: 25,
				x: 44,
				y: 33,
				seed: 31691156,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 247,
				type: 0,
				biome_type: 25,
				x: 44,
				y: 34,
				seed: 66114551,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 248,
				type: 21,
				biome_type: 26,
				x: 44,
				y: 37,
				seed: 91142187,
				flags: '0D000',
				master_room_x: 43,
				master_room_y: 37,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 27,
				border_direction: 0
			},
			{
				room_id: 249,
				type: 21,
				biome_type: 26,
				x: 44,
				y: 38,
				seed: 60489748,
				flags: '0E000',
				master_room_x: 43,
				master_room_y: 37,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 25,
				border_direction: 3
			},
			{
				room_id: 250,
				type: 0,
				biome_type: 25,
				x: 44,
				y: 39,
				seed: 71306039,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 26,
				border_direction: -1
			},
			{
				room_id: 251,
				type: 0,
				biome_type: 25,
				x: 44,
				y: 40,
				seed: 2970507,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 252,
				type: 0,
				biome_type: 8,
				x: 44,
				y: 44,
				seed: 7214298,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 253,
				type: 0,
				biome_type: 8,
				x: 44,
				y: 46,
				seed: 5438270,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 254,
				type: 0,
				biome_type: 23,
				x: 44,
				y: 51,
				seed: 20764154,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 255,
				type: 20,
				biome_type: 23,
				x: 44,
				y: 52,
				seed: 10348653,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 256,
				type: 0,
				biome_type: 23,
				x: 44,
				y: 53,
				seed: 91943247,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 257,
				type: 10,
				biome_type: 23,
				x: 44,
				y: 54,
				seed: 69323558,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 1,
				border_direction: 3
			},
			{
				room_id: 258,
				type: 59,
				biome_type: 1,
				x: 44,
				y: 55,
				seed: 62765139,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 23,
				border_direction: 1
			},
			{
				room_id: 259,
				type: 47,
				biome_type: 1,
				x: 44,
				y: 56,
				seed: 10667517,
				flags: '0A003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 260,
				type: 27,
				biome_type: 1,
				x: 44,
				y: 57,
				seed: 50217672,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 261,
				type: 0,
				biome_type: 49,
				x: 45,
				y: 27,
				seed: 76267678,
				flags: '08000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 262,
				type: 0,
				biome_type: 49,
				x: 45,
				y: 28,
				seed: 63836724,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 263,
				type: 20,
				biome_type: 49,
				x: 45,
				y: 29,
				seed: 46729128,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 264,
				type: 0,
				biome_type: 49,
				x: 45,
				y: 30,
				seed: 78350609,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 265,
				type: 26,
				biome_type: 49,
				x: 45,
				y: 31,
				seed: 59946680,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 266,
				type: 0,
				biome_type: 27,
				x: 45,
				y: 37,
				seed: 91162402,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 0,
				border: 26,
				border_direction: -1
			},
			{
				room_id: 267,
				type: 0,
				biome_type: 8,
				x: 45,
				y: 43,
				seed: 2439942,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 268,
				type: 1,
				biome_type: 8,
				x: 45,
				y: 44,
				seed: 91019365,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 269,
				type: 4,
				biome_type: 8,
				x: 45,
				y: 46,
				seed: 5308538,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000078'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 270,
				type: 0,
				biome_type: 23,
				x: 45,
				y: 51,
				seed: 33389921,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 271,
				type: 4,
				biome_type: 1,
				x: 45,
				y: 57,
				seed: 60719427,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000067'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 272,
				type: 0,
				biome_type: 49,
				x: 46,
				y: 28,
				seed: 47681529,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 273,
				type: 0,
				biome_type: 49,
				x: 46,
				y: 30,
				seed: 88134559,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 274,
				type: 0,
				biome_type: 27,
				x: 46,
				y: 33,
				seed: 78038038,
				flags: '08000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 275,
				type: 0,
				biome_type: 27,
				x: 46,
				y: 34,
				seed: 93700416,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 276,
				type: 0,
				biome_type: 27,
				x: 46,
				y: 37,
				seed: 30383844,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 277,
				type: 10,
				biome_type: 27,
				x: 46,
				y: 38,
				seed: 55200918,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 278,
				type: 20,
				biome_type: 8,
				x: 46,
				y: 43,
				seed: 13078424,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 279,
				type: 0,
				biome_type: 8,
				x: 46,
				y: 46,
				seed: 52670647,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 280,
				type: 0,
				biome_type: 8,
				x: 46,
				y: 47,
				seed: 37420162,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 281,
				type: 10,
				biome_type: 8,
				x: 46,
				y: 48,
				seed: 43203223,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 23,
				border_direction: 3
			},
			{
				room_id: 282,
				type: 0,
				biome_type: 23,
				x: 46,
				y: 49,
				seed: 91707222,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00001'],
				difficulty: 2,
				border: 8,
				border_direction: 1
			},
			{
				room_id: 283,
				type: 4,
				biome_type: 23,
				x: 46,
				y: 50,
				seed: 55659692,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000071'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 284,
				type: 0,
				biome_type: 23,
				x: 46,
				y: 51,
				seed: 59150774,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 285,
				type: 0,
				biome_type: 1,
				x: 46,
				y: 56,
				seed: 96380013,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 286,
				type: 0,
				biome_type: 1,
				x: 46,
				y: 57,
				seed: 24703163,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 287,
				type: 0,
				biome_type: 1,
				x: 46,
				y: 58,
				seed: 73856607,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 288,
				type: 69,
				biome_type: 1,
				x: 46,
				y: 61,
				seed: 52477386,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 4,
				border_direction: -1
			},
			{
				room_id: 289,
				type: 2,
				biome_type: 4,
				x: 46,
				y: 62,
				seed: 1239672,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 42,
				border_direction: -1
			},
			{
				room_id: 290,
				type: 0,
				biome_type: 3,
				x: 46,
				y: 63,
				seed: 56572964,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 0,
				border: 4,
				border_direction: -1
			},
			{
				room_id: 291,
				type: 0,
				biome_type: 49,
				x: 47,
				y: 28,
				seed: 1664073,
				flags: '0C000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 292,
				type: 0,
				biome_type: 49,
				x: 47,
				y: 29,
				seed: 59958686,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 293,
				type: 4,
				biome_type: 49,
				x: 47,
				y: 30,
				seed: 58009618,
				flags: '07000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF00000000C3'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 294,
				type: 0,
				biome_type: 27,
				x: 47,
				y: 34,
				seed: 92821884,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 295,
				type: 0,
				biome_type: 27,
				x: 47,
				y: 38,
				seed: 82428898,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 296,
				type: 0,
				biome_type: 27,
				x: 47,
				y: 39,
				seed: 21772776,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 297,
				type: 0,
				biome_type: 27,
				x: 47,
				y: 40,
				seed: 22346424,
				flags: '02000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 298,
				type: 0,
				biome_type: 8,
				x: 47,
				y: 43,
				seed: 55715844,
				flags: '0C000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 299,
				type: 0,
				biome_type: 8,
				x: 47,
				y: 44,
				seed: 102936,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 300,
				type: 0,
				biome_type: 8,
				x: 47,
				y: 45,
				seed: 42899407,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 301,
				type: 0,
				biome_type: 8,
				x: 47,
				y: 46,
				seed: 6458932,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 302,
				type: 0,
				biome_type: 1,
				x: 47,
				y: 53,
				seed: 75693071,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 303,
				type: 0,
				biome_type: 1,
				x: 47,
				y: 54,
				seed: 41758852,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 304,
				type: 10,
				biome_type: 1,
				x: 47,
				y: 55,
				seed: 53325627,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 305,
				type: 20,
				biome_type: 1,
				x: 47,
				y: 56,
				seed: 10826775,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 306,
				type: 0,
				biome_type: 1,
				x: 47,
				y: 58,
				seed: 53313029,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 307,
				type: 0,
				biome_type: 3,
				x: 47,
				y: 63,
				seed: 58012400,
				flags: '05000',
				objective_complete: 0,
				objects: ['00011FFFFFFFF0', '00011FFFFFFFF0', '00001FFFFFFFF00000'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 308,
				type: 0,
				biome_type: 49,
				x: 48,
				y: 30,
				seed: 76321442,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 309,
				type: 0,
				biome_type: 49,
				x: 48,
				y: 31,
				seed: 43207886,
				flags: '02000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 310,
				type: 0,
				biome_type: 27,
				x: 48,
				y: 34,
				seed: 58668227,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 311,
				type: 12,
				biome_type: 27,
				x: 48,
				y: 35,
				seed: 26119657,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 312,
				type: 10,
				biome_type: 27,
				x: 48,
				y: 36,
				seed: 24692234,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 313,
				type: 0,
				biome_type: 27,
				x: 48,
				y: 39,
				seed: 16550400,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 314,
				type: 10,
				biome_type: 8,
				x: 48,
				y: 44,
				seed: 38274728,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 315,
				type: 0,
				biome_type: 8,
				x: 48,
				y: 46,
				seed: 68954531,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00002'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 316,
				type: 70,
				biome_type: 8,
				x: 48,
				y: 47,
				seed: 19001424,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 317,
				type: 4,
				biome_type: 1,
				x: 48,
				y: 53,
				seed: 3988204,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000068'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 318,
				type: 0,
				biome_type: 1,
				x: 48,
				y: 58,
				seed: 6309386,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 319,
				type: 21,
				biome_type: 44,
				x: 48,
				y: 60,
				seed: 15493008,
				flags: '09000',
				is_double: 1,
				master_room_x: 48,
				master_room_y: 60,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 320,
				type: 21,
				biome_type: 44,
				x: 48,
				y: 61,
				seed: 35922790,
				flags: '0B000',
				master_room_x: 48,
				master_room_y: 60,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 3,
				border_direction: 3
			},
			{
				room_id: 321,
				type: 0,
				biome_type: 3,
				x: 48,
				y: 62,
				seed: 81356415,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000', '00001FFFFFFFF00000'],
				difficulty: 0,
				border: 44,
				border_direction: -1
			},
			{
				room_id: 322,
				type: 0,
				biome_type: 3,
				x: 48,
				y: 63,
				seed: 86812237,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 323,
				type: 10,
				biome_type: 49,
				x: 49,
				y: 30,
				seed: 4680428,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 324,
				type: 0,
				biome_type: 27,
				x: 49,
				y: 34,
				seed: 70759439,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: 49,
				border_direction: 0
			},
			{
				room_id: 325,
				type: 0,
				biome_type: 27,
				x: 49,
				y: 36,
				seed: 53262843,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 326,
				type: 0,
				biome_type: 27,
				x: 49,
				y: 37,
				seed: 83656091,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 327,
				type: 0,
				biome_type: 27,
				x: 49,
				y: 38,
				seed: 45514550,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 328,
				type: 0,
				biome_type: 27,
				x: 49,
				y: 39,
				seed: 26393546,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 329,
				type: 0,
				biome_type: 8,
				x: 49,
				y: 44,
				seed: 91356116,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 330,
				type: 0,
				biome_type: 8,
				x: 49,
				y: 47,
				seed: 30412191,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 331,
				type: 26,
				biome_type: 1,
				x: 49,
				y: 53,
				seed: 99061993,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 332,
				type: 0,
				biome_type: 1,
				x: 49,
				y: 58,
				seed: 18331312,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 333,
				type: 4,
				biome_type: 1,
				x: 49,
				y: 59,
				seed: 27514669,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000190'],
				difficulty: 0,
				border: 44,
				border_direction: -1
			},
			{
				room_id: 334,
				type: 21,
				biome_type: 44,
				x: 49,
				y: 60,
				seed: 87553346,
				flags: '0E000',
				master_room_x: 48,
				master_room_y: 60,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 1,
				border_direction: 1
			},
			{
				room_id: 335,
				type: 21,
				biome_type: 44,
				x: 49,
				y: 61,
				seed: 21467181,
				flags: '06000',
				master_room_x: 48,
				master_room_y: 60,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 336,
				type: 29,
				biome_type: 50,
				x: 50,
				y: 26,
				seed: 57002313,
				flags: '09000',
				is_double: 1,
				master_room_x: 50,
				master_room_y: 26,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 337,
				type: 29,
				biome_type: 50,
				x: 50,
				y: 27,
				seed: 23078165,
				flags: '0B000',
				master_room_x: 50,
				master_room_y: 26,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 49,
				border_direction: 3
			},
			{
				room_id: 338,
				type: 0,
				biome_type: 49,
				x: 50,
				y: 28,
				seed: 2654855,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 50,
				border_direction: 1
			},
			{
				room_id: 339,
				type: 0,
				biome_type: 49,
				x: 50,
				y: 29,
				seed: 91504227,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 340,
				type: 0,
				biome_type: 49,
				x: 50,
				y: 30,
				seed: 69099372,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 341,
				type: 0,
				biome_type: 49,
				x: 50,
				y: 31,
				seed: 37636992,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 342,
				type: 66,
				biome_type: 49,
				x: 50,
				y: 34,
				seed: 95749024,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 27,
				border_direction: 2
			},
			{
				room_id: 343,
				type: 0,
				biome_type: 27,
				x: 50,
				y: 37,
				seed: 32917580,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 344,
				type: 0,
				biome_type: 27,
				x: 50,
				y: 39,
				seed: 9536996,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 345,
				type: 0,
				biome_type: 8,
				x: 50,
				y: 42,
				seed: 29261393,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 346,
				type: 0,
				biome_type: 8,
				x: 50,
				y: 43,
				seed: 67883190,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 347,
				type: 0,
				biome_type: 8,
				x: 50,
				y: 44,
				seed: 61178108,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 348,
				type: 20,
				biome_type: 8,
				x: 50,
				y: 47,
				seed: 27911708,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 5,
				border_direction: 3
			},
			{
				room_id: 349,
				type: 10,
				biome_type: 5,
				x: 50,
				y: 48,
				seed: 85871885,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 8,
				border_direction: 1
			},
			{
				room_id: 350,
				type: 0,
				biome_type: 5,
				x: 50,
				y: 49,
				seed: 93158291,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 351,
				type: 0,
				biome_type: 5,
				x: 50,
				y: 50,
				seed: 13052565,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 352,
				type: 0,
				biome_type: 5,
				x: 50,
				y: 51,
				seed: 79973478,
				flags: '02000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 353,
				type: 0,
				biome_type: 1,
				x: 50,
				y: 53,
				seed: 60641736,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 354,
				type: 22,
				biome_type: 45,
				x: 50,
				y: 55,
				seed: 39519861,
				flags: '09000',
				is_double: 1,
				master_room_x: 50,
				master_room_y: 55,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 355,
				type: 22,
				biome_type: 45,
				x: 50,
				y: 56,
				seed: 8368469,
				flags: '0B000',
				master_room_x: 50,
				master_room_y: 55,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 1,
				border_direction: 3
			},
			{
				room_id: 356,
				type: 0,
				biome_type: 1,
				x: 50,
				y: 57,
				seed: 78091560,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 1,
				border: 45,
				border_direction: -1
			},
			{
				room_id: 357,
				type: 0,
				biome_type: 1,
				x: 50,
				y: 58,
				seed: 62220076,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 358,
				type: 29,
				biome_type: 50,
				x: 51,
				y: 26,
				seed: 40615782,
				flags: '0C000',
				master_room_x: 50,
				master_room_y: 26,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 359,
				type: 29,
				biome_type: 50,
				x: 51,
				y: 27,
				seed: 70283655,
				flags: '06000',
				master_room_x: 50,
				master_room_y: 26,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 360,
				type: 20,
				biome_type: 49,
				x: 51,
				y: 31,
				seed: 36637562,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 361,
				type: 10,
				biome_type: 49,
				x: 51,
				y: 34,
				seed: 98956111,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 362,
				type: 0,
				biome_type: 27,
				x: 51,
				y: 37,
				seed: 30598976,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 363,
				type: 0,
				biome_type: 27,
				x: 51,
				y: 38,
				seed: 59013624,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 364,
				type: 10,
				biome_type: 27,
				x: 51,
				y: 39,
				seed: 10882644,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 365,
				type: 10,
				biome_type: 8,
				x: 51,
				y: 41,
				seed: 40238432,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 366,
				type: 0,
				biome_type: 8,
				x: 51,
				y: 42,
				seed: 1063218,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 367,
				type: 0,
				biome_type: 8,
				x: 51,
				y: 44,
				seed: 14883902,
				flags: '0C000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 368,
				type: 0,
				biome_type: 8,
				x: 51,
				y: 45,
				seed: 57180650,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 369,
				type: 0,
				biome_type: 8,
				x: 51,
				y: 46,
				seed: 43220472,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 370,
				type: 0,
				biome_type: 8,
				x: 51,
				y: 47,
				seed: 2104829,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 371,
				type: 0,
				biome_type: 5,
				x: 51,
				y: 50,
				seed: 60470914,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 372,
				type: 1,
				biome_type: 1,
				x: 51,
				y: 53,
				seed: 27323783,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 373,
				type: 4,
				biome_type: 1,
				x: 51,
				y: 54,
				seed: 33759840,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000065'],
				difficulty: 2,
				border: 45,
				border_direction: -1
			},
			{
				room_id: 374,
				type: 22,
				biome_type: 45,
				x: 51,
				y: 55,
				seed: 38051732,
				flags: '0E000',
				master_room_x: 50,
				master_room_y: 55,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 1,
				border_direction: 1
			},
			{
				room_id: 375,
				type: 22,
				biome_type: 45,
				x: 51,
				y: 56,
				seed: 14299209,
				flags: '06000',
				master_room_x: 50,
				master_room_y: 55,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 376,
				type: 0,
				biome_type: 49,
				x: 52,
				y: 31,
				seed: 28780729,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 377,
				type: 0,
				biome_type: 49,
				x: 52,
				y: 33,
				seed: 11331548,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 378,
				type: 0,
				biome_type: 49,
				x: 52,
				y: 34,
				seed: 93333045,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 379,
				type: 0,
				biome_type: 49,
				x: 52,
				y: 35,
				seed: 26806473,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 380,
				type: 0,
				biome_type: 27,
				x: 52,
				y: 39,
				seed: 63691031,
				flags: '04000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 381,
				type: 12,
				biome_type: 8,
				x: 52,
				y: 45,
				seed: 28655858,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 382,
				type: 0,
				biome_type: 5,
				x: 52,
				y: 50,
				seed: 32648346,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 383,
				type: 0,
				biome_type: 49,
				x: 53,
				y: 31,
				seed: 93413163,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 384,
				type: 0,
				biome_type: 49,
				x: 53,
				y: 33,
				seed: 40681060,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 385,
				type: 0,
				biome_type: 49,
				x: 53,
				y: 35,
				seed: 93473456,
				flags: '0C000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 386,
				type: 0,
				biome_type: 49,
				x: 53,
				y: 36,
				seed: 80719382,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 387,
				type: 0,
				biome_type: 49,
				x: 53,
				y: 37,
				seed: 27453224,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 388,
				type: 10,
				biome_type: 8,
				x: 53,
				y: 45,
				seed: 92738927,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 389,
				type: 0,
				biome_type: 8,
				x: 53,
				y: 46,
				seed: 68337499,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 390,
				type: 0,
				biome_type: 8,
				x: 53,
				y: 47,
				seed: 24468808,
				flags: '02000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 391,
				type: 0,
				biome_type: 5,
				x: 53,
				y: 50,
				seed: 43800958,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 392,
				type: 10,
				biome_type: 49,
				x: 54,
				y: 31,
				seed: 13509649,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 393,
				type: 0,
				biome_type: 49,
				x: 54,
				y: 32,
				seed: 60464808,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 394,
				type: 0,
				biome_type: 49,
				x: 54,
				y: 33,
				seed: 36027433,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 395,
				type: 0,
				biome_type: 49,
				x: 54,
				y: 34,
				seed: 39172022,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 396,
				type: 10,
				biome_type: 49,
				x: 54,
				y: 37,
				seed: 8675594,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 397,
				type: 0,
				biome_type: 49,
				x: 54,
				y: 38,
				seed: 15979022,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 398,
				type: 0,
				biome_type: 49,
				x: 54,
				y: 39,
				seed: 84883968,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 399,
				type: 4,
				biome_type: 49,
				x: 54,
				y: 40,
				seed: 13525418,
				flags: '02000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00081FFFFFFFF00000000C2'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 400,
				type: 0,
				biome_type: 5,
				x: 54,
				y: 49,
				seed: 17179034,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 401,
				type: 10,
				biome_type: 5,
				x: 54,
				y: 50,
				seed: 92821650,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 402,
				type: 0,
				biome_type: 5,
				x: 54,
				y: 51,
				seed: 41883938,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 403,
				type: 20,
				biome_type: 5,
				x: 54,
				y: 52,
				seed: 39137405,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 404,
				type: 0,
				biome_type: 5,
				x: 54,
				y: 53,
				seed: 95930216,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 405,
				type: 26,
				biome_type: 5,
				x: 54,
				y: 54,
				seed: 27771550,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 406,
				type: 0,
				biome_type: 5,
				x: 54,
				y: 55,
				seed: 94673752,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 407,
				type: 10,
				biome_type: 5,
				x: 54,
				y: 56,
				seed: 78185386,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 408,
				type: 4,
				biome_type: 5,
				x: 54,
				y: 57,
				seed: 11103351,
				flags: '03000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000007E'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 409,
				type: 0,
				biome_type: 49,
				x: 55,
				y: 32,
				seed: 72237308,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 410,
				type: 4,
				biome_type: 49,
				x: 55,
				y: 34,
				seed: 16613596,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00081FFFFFFFF00000000C0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 411,
				type: 0,
				biome_type: 47,
				x: 55,
				y: 42,
				seed: 32091095,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 412,
				type: 0,
				biome_type: 5,
				x: 55,
				y: 47,
				seed: 30035612,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 413,
				type: 0,
				biome_type: 5,
				x: 55,
				y: 48,
				seed: 19271338,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 414,
				type: 0,
				biome_type: 5,
				x: 55,
				y: 49,
				seed: 56889715,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 415,
				type: 0,
				biome_type: 5,
				x: 55,
				y: 52,
				seed: 61230845,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004', '00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 416,
				type: 0,
				biome_type: 5,
				x: 55,
				y: 55,
				seed: 39601183,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 417,
				type: 0,
				biome_type: 5,
				x: 55,
				y: 57,
				seed: 99524810,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 418,
				type: 10,
				biome_type: 5,
				x: 55,
				y: 59,
				seed: 95183881,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 419,
				type: 47,
				biome_type: 49,
				x: 56,
				y: 29,
				seed: 63220303,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 49,
				border_direction: -1
			},
			{
				room_id: 420,
				type: 0,
				biome_type: 49,
				x: 56,
				y: 30,
				seed: 90812187,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 421,
				type: 0,
				biome_type: 49,
				x: 56,
				y: 31,
				seed: 57175919,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 422,
				type: 0,
				biome_type: 49,
				x: 56,
				y: 32,
				seed: 88708379,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 423,
				type: 0,
				biome_type: 49,
				x: 56,
				y: 34,
				seed: 39097118,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 424,
				type: 10,
				biome_type: 49,
				x: 56,
				y: 35,
				seed: 75825616,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 425,
				type: 0,
				biome_type: 47,
				x: 56,
				y: 42,
				seed: 54120715,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 426,
				type: 21,
				biome_type: 7,
				x: 56,
				y: 45,
				seed: 47927785,
				flags: '09000',
				is_double: 1,
				master_room_x: 56,
				master_room_y: 45,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 427,
				type: 21,
				biome_type: 7,
				x: 56,
				y: 46,
				seed: 53388742,
				flags: '0B000',
				master_room_x: 56,
				master_room_y: 45,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 5,
				border_direction: 3
			},
			{
				room_id: 428,
				type: 0,
				biome_type: 5,
				x: 56,
				y: 47,
				seed: 81757421,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00004'],
				difficulty: 2,
				border: 7,
				border_direction: -1
			},
			{
				room_id: 429,
				type: 0,
				biome_type: 5,
				x: 56,
				y: 52,
				seed: 93262996,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 430,
				type: 4,
				biome_type: 5,
				x: 56,
				y: 53,
				seed: 26704040,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000007C'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 431,
				type: 0,
				biome_type: 5,
				x: 56,
				y: 54,
				seed: 15852881,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 432,
				type: 0,
				biome_type: 5,
				x: 56,
				y: 55,
				seed: 42319685,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 433,
				type: 0,
				biome_type: 5,
				x: 56,
				y: 57,
				seed: 78286891,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 434,
				type: 1,
				biome_type: 5,
				x: 56,
				y: 58,
				seed: 12548410,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 435,
				type: 0,
				biome_type: 5,
				x: 56,
				y: 59,
				seed: 88426047,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 436,
				type: 0,
				biome_type: 49,
				x: 57,
				y: 32,
				seed: 70235573,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 437,
				type: 0,
				biome_type: 49,
				x: 57,
				y: 35,
				seed: 16154586,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 438,
				type: 21,
				biome_type: 48,
				x: 57,
				y: 38,
				seed: 22550536,
				flags: '09000',
				is_double: 1,
				master_room_x: 57,
				master_room_y: 38,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 439,
				type: 21,
				biome_type: 48,
				x: 57,
				y: 39,
				seed: 36807923,
				flags: '0B000',
				master_room_x: 57,
				master_room_y: 38,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 47,
				border_direction: 3
			},
			{
				room_id: 440,
				type: 0,
				biome_type: 47,
				x: 57,
				y: 40,
				seed: 58072880,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: 48,
				border_direction: -1
			},
			{
				room_id: 441,
				type: 12,
				biome_type: 47,
				x: 57,
				y: 41,
				seed: 83487753,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 442,
				type: 4,
				biome_type: 47,
				x: 57,
				y: 42,
				seed: 34573649,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00081FFFFFFFF00000000B7'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 443,
				type: 21,
				biome_type: 7,
				x: 57,
				y: 45,
				seed: 52807747,
				flags: '0C000',
				master_room_x: 56,
				master_room_y: 45,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 444,
				type: 21,
				biome_type: 7,
				x: 57,
				y: 46,
				seed: 33615824,
				flags: '07000',
				master_room_x: 56,
				master_room_y: 45,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 47,
				border_direction: 0
			},
			{
				room_id: 445,
				type: 0,
				biome_type: 6,
				x: 57,
				y: 50,
				seed: 68439964,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 446,
				type: 10,
				biome_type: 6,
				x: 57,
				y: 51,
				seed: 69453456,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 5,
				border_direction: -1
			},
			{
				room_id: 447,
				type: 0,
				biome_type: 5,
				x: 57,
				y: 52,
				seed: 59280220,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: 6,
				border_direction: -1
			},
			{
				room_id: 448,
				type: 0,
				biome_type: 5,
				x: 57,
				y: 55,
				seed: 75281003,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 449,
				type: 4,
				biome_type: 5,
				x: 57,
				y: 56,
				seed: 67977928,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000080'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 450,
				type: 0,
				biome_type: 5,
				x: 57,
				y: 57,
				seed: 81391572,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 451,
				type: 10,
				biome_type: 49,
				x: 58,
				y: 31,
				seed: 4578356,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 452,
				type: 0,
				biome_type: 49,
				x: 58,
				y: 32,
				seed: 63805064,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 453,
				type: 0,
				biome_type: 49,
				x: 58,
				y: 33,
				seed: 4928276,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 454,
				type: 0,
				biome_type: 49,
				x: 58,
				y: 34,
				seed: 38656860,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 455,
				type: 0,
				biome_type: 49,
				x: 58,
				y: 35,
				seed: 58108560,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 456,
				type: 21,
				biome_type: 48,
				x: 58,
				y: 38,
				seed: 51016874,
				flags: '0D000',
				master_room_x: 57,
				master_room_y: 38,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 47,
				border_direction: 0
			},
			{
				room_id: 457,
				type: 21,
				biome_type: 48,
				x: 58,
				y: 39,
				seed: 74085337,
				flags: '06000',
				master_room_x: 57,
				master_room_y: 38,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 458,
				type: 0,
				biome_type: 47,
				x: 58,
				y: 42,
				seed: 24140957,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 459,
				type: 62,
				biome_type: 47,
				x: 58,
				y: 46,
				seed: 3400490,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 7,
				border_direction: -1
			},
			{
				room_id: 460,
				type: 0,
				biome_type: 6,
				x: 58,
				y: 50,
				seed: 10473761,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 461,
				type: 0,
				biome_type: 5,
				x: 58,
				y: 54,
				seed: 2079076,
				flags: '08000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 462,
				type: 12,
				biome_type: 5,
				x: 58,
				y: 55,
				seed: 32826216,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 463,
				type: 10,
				biome_type: 5,
				x: 58,
				y: 56,
				seed: 16321169,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 464,
				type: 0,
				biome_type: 49,
				x: 59,
				y: 31,
				seed: 20459272,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 465,
				type: 0,
				biome_type: 47,
				x: 59,
				y: 38,
				seed: 85876723,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: 48,
				border_direction: -1
			},
			{
				room_id: 466,
				type: 10,
				biome_type: 47,
				x: 59,
				y: 42,
				seed: 51845230,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 467,
				type: 0,
				biome_type: 47,
				x: 59,
				y: 43,
				seed: 30884241,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 468,
				type: 10,
				biome_type: 47,
				x: 59,
				y: 46,
				seed: 89541645,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 469,
				type: 0,
				biome_type: 6,
				x: 59,
				y: 49,
				seed: 82395087,
				flags: '08000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 470,
				type: 0,
				biome_type: 6,
				x: 59,
				y: 50,
				seed: 72794270,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 471,
				type: 0,
				biome_type: 6,
				x: 59,
				y: 51,
				seed: 63700322,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 472,
				type: 0,
				biome_type: 6,
				x: 59,
				y: 52,
				seed: 48164286,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 473,
				type: 0,
				biome_type: 49,
				x: 60,
				y: 28,
				seed: 3723022,
				flags: '08000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 474,
				type: 0,
				biome_type: 49,
				x: 60,
				y: 29,
				seed: 20375245,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 475,
				type: 0,
				biome_type: 49,
				x: 60,
				y: 30,
				seed: 35802797,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 476,
				type: 0,
				biome_type: 49,
				x: 60,
				y: 31,
				seed: 5497742,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 477,
				type: 0,
				biome_type: 49,
				x: 60,
				y: 32,
				seed: 636277,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 478,
				type: 0,
				biome_type: 49,
				x: 60,
				y: 33,
				seed: 74619481,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 479,
				type: 0,
				biome_type: 47,
				x: 60,
				y: 36,
				seed: 71756725,
				flags: '08000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 480,
				type: 0,
				biome_type: 47,
				x: 60,
				y: 37,
				seed: 12832095,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 481,
				type: 0,
				biome_type: 47,
				x: 60,
				y: 38,
				seed: 74028644,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 482,
				type: 0,
				biome_type: 47,
				x: 60,
				y: 39,
				seed: 89271510,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 483,
				type: 0,
				biome_type: 47,
				x: 60,
				y: 43,
				seed: 16019539,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 484,
				type: 20,
				biome_type: 47,
				x: 60,
				y: 44,
				seed: 9254251,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 485,
				type: 0,
				biome_type: 47,
				x: 60,
				y: 45,
				seed: 25509977,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 486,
				type: 0,
				biome_type: 47,
				x: 60,
				y: 46,
				seed: 66254861,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 487,
				type: 0,
				biome_type: 47,
				x: 60,
				y: 47,
				seed: 35330330,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 488,
				type: 10,
				biome_type: 6,
				x: 60,
				y: 52,
				seed: 17316004,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 489,
				type: 0,
				biome_type: 6,
				x: 60,
				y: 53,
				seed: 51100713,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 490,
				type: 0,
				biome_type: 6,
				x: 60,
				y: 54,
				seed: 51539269,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 491,
				type: 0,
				biome_type: 49,
				x: 61,
				y: 33,
				seed: 72791716,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 492,
				type: 0,
				biome_type: 47,
				x: 61,
				y: 37,
				seed: 78946241,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 493,
				type: 0,
				biome_type: 47,
				x: 61,
				y: 39,
				seed: 97538224,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 494,
				type: 4,
				biome_type: 47,
				x: 61,
				y: 43,
				seed: 64564166,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00081FFFFFFFF00000000B6'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 495,
				type: 0,
				biome_type: 47,
				x: 61,
				y: 47,
				seed: 89794718,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 496,
				type: 0,
				biome_type: 6,
				x: 61,
				y: 52,
				seed: 87583006,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 497,
				type: 10,
				biome_type: 49,
				x: 62,
				y: 33,
				seed: 40653694,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 498,
				type: 47,
				biome_type: 47,
				x: 62,
				y: 36,
				seed: 66657960,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 47,
				border_direction: -1
			},
			{
				room_id: 499,
				type: 0,
				biome_type: 47,
				x: 62,
				y: 37,
				seed: 11690552,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 500,
				type: 10,
				biome_type: 47,
				x: 62,
				y: 39,
				seed: 38867404,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 501,
				type: 0,
				biome_type: 47,
				x: 62,
				y: 40,
				seed: 76520812,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 502,
				type: 0,
				biome_type: 47,
				x: 62,
				y: 41,
				seed: 32694446,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 503,
				type: 0,
				biome_type: 47,
				x: 62,
				y: 42,
				seed: 10248574,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 504,
				type: 0,
				biome_type: 47,
				x: 62,
				y: 43,
				seed: 56299402,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 505,
				type: 26,
				biome_type: 47,
				x: 62,
				y: 44,
				seed: 94721664,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 506,
				type: 10,
				biome_type: 47,
				x: 62,
				y: 45,
				seed: 44653449,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 507,
				type: 0,
				biome_type: 47,
				x: 62,
				y: 46,
				seed: 53652809,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 508,
				type: 0,
				biome_type: 47,
				x: 62,
				y: 47,
				seed: 64321563,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 509,
				type: 47,
				biome_type: 6,
				x: 62,
				y: 50,
				seed: 43935679,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 6,
				border_direction: -1
			},
			{
				room_id: 510,
				type: 0,
				biome_type: 6,
				x: 62,
				y: 51,
				seed: 10051552,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 511,
				type: 0,
				biome_type: 6,
				x: 62,
				y: 52,
				seed: 24823055,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 512,
				type: 0,
				biome_type: 47,
				x: 63,
				y: 37,
				seed: 28151269,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 513,
				type: 4,
				biome_type: 47,
				x: 63,
				y: 38,
				seed: 92108958,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00081FFFFFFFF00000000B9'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 514,
				type: 12,
				biome_type: 47,
				x: 63,
				y: 39,
				seed: 65882952,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 515,
				type: 0,
				biome_type: 47,
				x: 63,
				y: 42,
				seed: 59073904,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 516,
				type: 0,
				biome_type: 47,
				x: 63,
				y: 45,
				seed: 23960055,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 517,
				type: 0,
				biome_type: 47,
				x: 64,
				y: 42,
				seed: 29924743,
				flags: '04000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 518,
				type: 0,
				biome_type: 47,
				x: 64,
				y: 45,
				seed: 58946162,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 519,
				type: 10,
				biome_type: 47,
				x: 64,
				y: 47,
				seed: 32920867,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 520,
				type: 0,
				biome_type: 47,
				x: 65,
				y: 45,
				seed: 72310875,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 521,
				type: 0,
				biome_type: 47,
				x: 65,
				y: 46,
				seed: 54141780,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 522,
				type: 0,
				biome_type: 47,
				x: 65,
				y: 47,
				seed: 27538651,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 523,
				type: 0,
				biome_type: 47,
				x: 66,
				y: 44,
				seed: 70287355,
				flags: '08000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 524,
				type: 20,
				biome_type: 47,
				x: 66,
				y: 45,
				seed: 64797733,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			}
		]
	},
	{
		name: 'Worldgen 1',
		sprite: 's_mapgen_test_1',
		spawn_x: 57,
		spawn_y: 60,
		rooms: [
			{
				room_id: 0,
				type: 0,
				biome_type: 25,
				x: 26,
				y: 36,
				seed: 96885487,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 1,
				type: 0,
				biome_type: 25,
				x: 26,
				y: 37,
				seed: 82465457,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 2,
				type: 10,
				biome_type: 25,
				x: 26,
				y: 38,
				seed: 82569203,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 3,
				type: 0,
				biome_type: 25,
				x: 26,
				y: 39,
				seed: 7108180,
				flags: '02000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 4,
				type: 0,
				biome_type: 25,
				x: 26,
				y: 43,
				seed: 54497431,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 5,
				type: 0,
				biome_type: 25,
				x: 26,
				y: 44,
				seed: 43325104,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 6,
				type: 0,
				biome_type: 47,
				x: 26,
				y: 54,
				seed: 12675939,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 7,
				type: 0,
				biome_type: 47,
				x: 26,
				y: 55,
				seed: 37204531,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 8,
				type: 0,
				biome_type: 47,
				x: 26,
				y: 56,
				seed: 27313785,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 9,
				type: 26,
				biome_type: 5,
				x: 26,
				y: 59,
				seed: 24284913,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 10,
				type: 20,
				biome_type: 25,
				x: 27,
				y: 35,
				seed: 83454887,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 11,
				type: 0,
				biome_type: 25,
				x: 27,
				y: 36,
				seed: 13174754,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 12,
				type: 12,
				biome_type: 25,
				x: 27,
				y: 44,
				seed: 72218449,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 13,
				type: 4,
				biome_type: 47,
				x: 27,
				y: 52,
				seed: 86288342,
				flags: '08000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00081FFFFFFFF00000000B6'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 14,
				type: 10,
				biome_type: 47,
				x: 27,
				y: 53,
				seed: 83076492,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 15,
				type: 0,
				biome_type: 47,
				x: 27,
				y: 54,
				seed: 12776559,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 16,
				type: 4,
				biome_type: 47,
				x: 27,
				y: 56,
				seed: 56900619,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF00000000B9'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 17,
				type: 0,
				biome_type: 5,
				x: 27,
				y: 59,
				seed: 11560523,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 18,
				type: 10,
				biome_type: 5,
				x: 27,
				y: 60,
				seed: 81493108,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 19,
				type: 0,
				biome_type: 5,
				x: 27,
				y: 61,
				seed: 1311622,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 20,
				type: 10,
				biome_type: 5,
				x: 27,
				y: 64,
				seed: 93686357,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 21,
				type: 0,
				biome_type: 25,
				x: 28,
				y: 35,
				seed: 8462538,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 22,
				type: 0,
				biome_type: 25,
				x: 28,
				y: 44,
				seed: 87775993,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 23,
				type: 0,
				biome_type: 25,
				x: 28,
				y: 45,
				seed: 65381601,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 24,
				type: 10,
				biome_type: 25,
				x: 28,
				y: 46,
				seed: 19057239,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 25,
				type: 0,
				biome_type: 25,
				x: 28,
				y: 47,
				seed: 68532310,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 26,
				type: 0,
				biome_type: 47,
				x: 28,
				y: 53,
				seed: 51324267,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 27,
				type: 10,
				biome_type: 47,
				x: 28,
				y: 56,
				seed: 78035376,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 28,
				type: 0,
				biome_type: 5,
				x: 28,
				y: 61,
				seed: 73984749,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 29,
				type: 0,
				biome_type: 5,
				x: 28,
				y: 62,
				seed: 42884652,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004', '00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 30,
				type: 0,
				biome_type: 5,
				x: 28,
				y: 63,
				seed: 28900411,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 31,
				type: 4,
				biome_type: 5,
				x: 28,
				y: 64,
				seed: 23766347,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000007E'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 32,
				type: 0,
				biome_type: 5,
				x: 28,
				y: 65,
				seed: 73034233,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004', '00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 33,
				type: 0,
				biome_type: 25,
				x: 29,
				y: 34,
				seed: 91739826,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 34,
				type: 10,
				biome_type: 25,
				x: 29,
				y: 35,
				seed: 71349797,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 35,
				type: 0,
				biome_type: 25,
				x: 29,
				y: 36,
				seed: 44461467,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 36,
				type: 0,
				biome_type: 25,
				x: 29,
				y: 45,
				seed: 42895254,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 37,
				type: 20,
				biome_type: 25,
				x: 29,
				y: 47,
				seed: 6075881,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 38,
				type: 0,
				biome_type: 47,
				x: 29,
				y: 53,
				seed: 74743350,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 39,
				type: 0,
				biome_type: 5,
				x: 29,
				y: 62,
				seed: 62333656,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 40,
				type: 0,
				biome_type: 5,
				x: 29,
				y: 65,
				seed: 55582006,
				flags: '04000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 41,
				type: 0,
				biome_type: 25,
				x: 30,
				y: 34,
				seed: 62864717,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 42,
				type: 0,
				biome_type: 25,
				x: 30,
				y: 36,
				seed: 26786789,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 43,
				type: 21,
				biome_type: 16,
				x: 30,
				y: 40,
				seed: 65476221,
				flags: '09000',
				is_double: 1,
				master_room_x: 30,
				master_room_y: 40,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 44,
				type: 21,
				biome_type: 16,
				x: 30,
				y: 41,
				seed: 84305087,
				flags: '03000',
				master_room_x: 30,
				master_room_y: 40,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 45,
				type: 0,
				biome_type: 25,
				x: 30,
				y: 45,
				seed: 50490560,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 46,
				type: 0,
				biome_type: 25,
				x: 30,
				y: 47,
				seed: 1075424,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 47,
				type: 0,
				biome_type: 47,
				x: 30,
				y: 53,
				seed: 26839033,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 48,
				type: 0,
				biome_type: 47,
				x: 30,
				y: 54,
				seed: 31235345,
				flags: '02000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 49,
				type: 0,
				biome_type: 5,
				x: 30,
				y: 62,
				seed: 74475087,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 50,
				type: 0,
				biome_type: 5,
				x: 30,
				y: 63,
				seed: 95525330,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004', '00011FFFFFFFF0', '00011FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 51,
				type: 0,
				biome_type: 5,
				x: 30,
				y: 64,
				seed: 40185377,
				flags: '02000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 52,
				type: 0,
				biome_type: 25,
				x: 31,
				y: 33,
				seed: 3827844,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 53,
				type: 0,
				biome_type: 25,
				x: 31,
				y: 34,
				seed: 84395413,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 54,
				type: 0,
				biome_type: 25,
				x: 31,
				y: 35,
				seed: 4093718,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 55,
				type: 20,
				biome_type: 25,
				x: 31,
				y: 36,
				seed: 16426989,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 56,
				type: 0,
				biome_type: 25,
				x: 31,
				y: 37,
				seed: 19695206,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 57,
				type: 21,
				biome_type: 16,
				x: 31,
				y: 40,
				seed: 59116000,
				flags: '0C000',
				master_room_x: 30,
				master_room_y: 40,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 58,
				type: 21,
				biome_type: 16,
				x: 31,
				y: 41,
				seed: 41679761,
				flags: '0E000',
				master_room_x: 30,
				master_room_y: 40,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 25,
				border_direction: 3
			},
			{
				room_id: 59,
				type: 0,
				biome_type: 25,
				x: 31,
				y: 42,
				seed: 90781336,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: 16,
				border_direction: 1
			},
			{
				room_id: 60,
				type: 0,
				biome_type: 25,
				x: 31,
				y: 43,
				seed: 4764851,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 61,
				type: 0,
				biome_type: 25,
				x: 31,
				y: 44,
				seed: 85660407,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 62,
				type: 4,
				biome_type: 25,
				x: 31,
				y: 45,
				seed: 35536946,
				flags: '06000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF00000000A0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 63,
				type: 0,
				biome_type: 25,
				x: 31,
				y: 47,
				seed: 38876823,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 64,
				type: 21,
				biome_type: 48,
				x: 31,
				y: 50,
				seed: 46118363,
				flags: '09000',
				is_double: 1,
				master_room_x: 31,
				master_room_y: 50,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 65,
				type: 21,
				biome_type: 48,
				x: 31,
				y: 51,
				seed: 90929491,
				flags: '0B000',
				master_room_x: 31,
				master_room_y: 50,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 47,
				border_direction: 3
			},
			{
				room_id: 66,
				type: 0,
				biome_type: 47,
				x: 31,
				y: 52,
				seed: 97363328,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: 48,
				border_direction: -1
			},
			{
				room_id: 67,
				type: 0,
				biome_type: 47,
				x: 31,
				y: 53,
				seed: 97804008,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 68,
				type: 21,
				biome_type: 7,
				x: 31,
				y: 57,
				seed: 15531630,
				flags: '09000',
				is_double: 1,
				master_room_x: 31,
				master_room_y: 57,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 69,
				type: 21,
				biome_type: 7,
				x: 31,
				y: 58,
				seed: 97156026,
				flags: '0B000',
				master_room_x: 31,
				master_room_y: 57,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 5,
				border_direction: 3
			},
			{
				room_id: 70,
				type: 0,
				biome_type: 5,
				x: 31,
				y: 59,
				seed: 74980288,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: 7,
				border_direction: -1
			},
			{
				room_id: 71,
				type: 0,
				biome_type: 5,
				x: 31,
				y: 60,
				seed: 32666354,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 72,
				type: 10,
				biome_type: 5,
				x: 31,
				y: 62,
				seed: 87689248,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 73,
				type: 47,
				biome_type: 25,
				x: 32,
				y: 31,
				seed: 19534831,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 25,
				border_direction: -1
			},
			{
				room_id: 74,
				type: 0,
				biome_type: 25,
				x: 32,
				y: 32,
				seed: 86566364,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 75,
				type: 0,
				biome_type: 25,
				x: 32,
				y: 33,
				seed: 83698971,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 76,
				type: 0,
				biome_type: 25,
				x: 32,
				y: 37,
				seed: 63851982,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 77,
				type: 10,
				biome_type: 25,
				x: 32,
				y: 44,
				seed: 30920477,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 78,
				type: 21,
				biome_type: 48,
				x: 32,
				y: 50,
				seed: 99214848,
				flags: '0D000',
				master_room_x: 31,
				master_room_y: 50,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 47,
				border_direction: 0
			},
			{
				room_id: 79,
				type: 21,
				biome_type: 48,
				x: 32,
				y: 51,
				seed: 10639751,
				flags: '06000',
				master_room_x: 31,
				master_room_y: 50,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 80,
				type: 0,
				biome_type: 47,
				x: 32,
				y: 53,
				seed: 88933158,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 81,
				type: 21,
				biome_type: 7,
				x: 32,
				y: 57,
				seed: 52873150,
				flags: '0C000',
				master_room_x: 31,
				master_room_y: 57,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 82,
				type: 21,
				biome_type: 7,
				x: 32,
				y: 58,
				seed: 39812123,
				flags: '07000',
				master_room_x: 31,
				master_room_y: 57,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 47,
				border_direction: 0
			},
			{
				room_id: 83,
				type: 0,
				biome_type: 5,
				x: 32,
				y: 60,
				seed: 11168939,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 84,
				type: 0,
				biome_type: 5,
				x: 32,
				y: 61,
				seed: 85968683,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 85,
				type: 0,
				biome_type: 5,
				x: 32,
				y: 62,
				seed: 39706514,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 86,
				type: 0,
				biome_type: 5,
				x: 32,
				y: 63,
				seed: 92948462,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 87,
				type: 0,
				biome_type: 5,
				x: 32,
				y: 64,
				seed: 804165,
				flags: '02000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004', '00011FFFFFFFF0', '00011FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 88,
				type: 10,
				biome_type: 25,
				x: 33,
				y: 37,
				seed: 73920387,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 89,
				type: 27,
				biome_type: 25,
				x: 33,
				y: 44,
				seed: 38704210,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 90,
				type: 0,
				biome_type: 47,
				x: 33,
				y: 50,
				seed: 59030649,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: 48,
				border_direction: -1
			},
			{
				room_id: 91,
				type: 10,
				biome_type: 47,
				x: 33,
				y: 53,
				seed: 36298884,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 92,
				type: 0,
				biome_type: 47,
				x: 33,
				y: 54,
				seed: 22059661,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 93,
				type: 62,
				biome_type: 47,
				x: 33,
				y: 58,
				seed: 23364754,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 7,
				border_direction: -1
			},
			{
				room_id: 94,
				type: 0,
				biome_type: 5,
				x: 33,
				y: 60,
				seed: 29061177,
				flags: '05000',
				objective_complete: 0,
				objects: ['00011FFFFFFFF0', '00011FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 95,
				type: 0,
				biome_type: 25,
				x: 34,
				y: 37,
				seed: 55156890,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 96,
				type: 0,
				biome_type: 25,
				x: 34,
				y: 38,
				seed: 13686498,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 97,
				type: 0,
				biome_type: 25,
				x: 34,
				y: 39,
				seed: 18985172,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 98,
				type: 0,
				biome_type: 25,
				x: 34,
				y: 40,
				seed: 36329901,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 99,
				type: 0,
				biome_type: 25,
				x: 34,
				y: 41,
				seed: 10320207,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 100,
				type: 0,
				biome_type: 25,
				x: 34,
				y: 42,
				seed: 11651019,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 101,
				type: 4,
				biome_type: 25,
				x: 34,
				y: 43,
				seed: 70885261,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF00000000A1'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 102,
				type: 0,
				biome_type: 25,
				x: 34,
				y: 44,
				seed: 35778907,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 103,
				type: 0,
				biome_type: 47,
				x: 34,
				y: 48,
				seed: 42228800,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 104,
				type: 10,
				biome_type: 47,
				x: 34,
				y: 49,
				seed: 64333322,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 105,
				type: 0,
				biome_type: 47,
				x: 34,
				y: 50,
				seed: 47053191,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 106,
				type: 20,
				biome_type: 47,
				x: 34,
				y: 54,
				seed: 54307085,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 107,
				type: 4,
				biome_type: 47,
				x: 34,
				y: 55,
				seed: 62304052,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00081FFFFFFFF00000000B7'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 108,
				type: 0,
				biome_type: 47,
				x: 34,
				y: 56,
				seed: 82689128,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 109,
				type: 0,
				biome_type: 47,
				x: 34,
				y: 57,
				seed: 44808499,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 110,
				type: 10,
				biome_type: 47,
				x: 34,
				y: 58,
				seed: 19581782,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 111,
				type: 0,
				biome_type: 5,
				x: 34,
				y: 60,
				seed: 66404404,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 112,
				type: 10,
				biome_type: 5,
				x: 34,
				y: 61,
				seed: 46676343,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 113,
				type: 0,
				biome_type: 5,
				x: 34,
				y: 62,
				seed: 80107272,
				flags: '02000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 114,
				type: 0,
				biome_type: 25,
				x: 35,
				y: 36,
				seed: 50998756,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 115,
				type: 0,
				biome_type: 25,
				x: 35,
				y: 37,
				seed: 88295928,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 116,
				type: 10,
				biome_type: 25,
				x: 35,
				y: 41,
				seed: 66166050,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 117,
				type: 0,
				biome_type: 25,
				x: 35,
				y: 44,
				seed: 14754345,
				flags: '04000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 118,
				type: 47,
				biome_type: 47,
				x: 35,
				y: 47,
				seed: 64624730,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 47,
				border_direction: -1
			},
			{
				room_id: 119,
				type: 20,
				biome_type: 47,
				x: 35,
				y: 48,
				seed: 93452555,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 120,
				type: 0,
				biome_type: 47,
				x: 35,
				y: 50,
				seed: 80703000,
				flags: '0C000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 121,
				type: 26,
				biome_type: 47,
				x: 35,
				y: 51,
				seed: 81790687,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 122,
				type: 0,
				biome_type: 47,
				x: 35,
				y: 55,
				seed: 60411210,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 123,
				type: 0,
				biome_type: 5,
				x: 35,
				y: 61,
				seed: 14851896,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 124,
				type: 29,
				biome_type: 50,
				x: 36,
				y: 29,
				seed: 78644419,
				flags: '09000',
				is_double: 1,
				master_room_x: 36,
				master_room_y: 29,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 125,
				type: 29,
				biome_type: 50,
				x: 36,
				y: 30,
				seed: 44247615,
				flags: '0B000',
				master_room_x: 36,
				master_room_y: 29,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 49,
				border_direction: 3
			},
			{
				room_id: 126,
				type: 0,
				biome_type: 49,
				x: 36,
				y: 31,
				seed: 24426193,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 2,
				border: 50,
				border_direction: 1
			},
			{
				room_id: 127,
				type: 0,
				biome_type: 49,
				x: 36,
				y: 32,
				seed: 41267606,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 128,
				type: 10,
				biome_type: 49,
				x: 36,
				y: 33,
				seed: 97080434,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 129,
				type: 0,
				biome_type: 25,
				x: 36,
				y: 36,
				seed: 90318332,
				flags: '04000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 130,
				type: 26,
				biome_type: 25,
				x: 36,
				y: 41,
				seed: 76083170,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 131,
				type: 0,
				biome_type: 47,
				x: 36,
				y: 51,
				seed: 77026092,
				flags: '0C000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 132,
				type: 12,
				biome_type: 47,
				x: 36,
				y: 52,
				seed: 81934549,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 133,
				type: 12,
				biome_type: 47,
				x: 36,
				y: 53,
				seed: 19885296,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 134,
				type: 0,
				biome_type: 47,
				x: 36,
				y: 54,
				seed: 68712008,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 135,
				type: 0,
				biome_type: 47,
				x: 36,
				y: 55,
				seed: 2705751,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 136,
				type: 0,
				biome_type: 5,
				x: 36,
				y: 57,
				seed: 61007109,
				flags: '08000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 137,
				type: 0,
				biome_type: 5,
				x: 36,
				y: 58,
				seed: 58462389,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 138,
				type: 0,
				biome_type: 5,
				x: 36,
				y: 59,
				seed: 15534629,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 139,
				type: 0,
				biome_type: 5,
				x: 36,
				y: 61,
				seed: 37604116,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 140,
				type: 29,
				biome_type: 50,
				x: 37,
				y: 29,
				seed: 5284618,
				flags: '0C000',
				master_room_x: 36,
				master_room_y: 29,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 141,
				type: 29,
				biome_type: 50,
				x: 37,
				y: 30,
				seed: 57711648,
				flags: '06000',
				master_room_x: 36,
				master_room_y: 29,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 142,
				type: 20,
				biome_type: 49,
				x: 37,
				y: 33,
				seed: 23492836,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 143,
				type: 0,
				biome_type: 49,
				x: 37,
				y: 38,
				seed: 75628165,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 144,
				type: 0,
				biome_type: 25,
				x: 37,
				y: 41,
				seed: 87163556,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 1,
				border: 26,
				border_direction: -1
			},
			{
				room_id: 145,
				type: 0,
				biome_type: 47,
				x: 37,
				y: 52,
				seed: 14485889,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 146,
				type: 10,
				biome_type: 47,
				x: 37,
				y: 55,
				seed: 55855236,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 147,
				type: 4,
				biome_type: 5,
				x: 37,
				y: 59,
				seed: 88812337,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000007C'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 148,
				type: 0,
				biome_type: 5,
				x: 37,
				y: 60,
				seed: 75195589,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 149,
				type: 0,
				biome_type: 5,
				x: 37,
				y: 61,
				seed: 87309507,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 150,
				type: 1,
				biome_type: 5,
				x: 37,
				y: 64,
				seed: 85178307,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 151,
				type: 0,
				biome_type: 49,
				x: 38,
				y: 33,
				seed: 57145984,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 152,
				type: 0,
				biome_type: 49,
				x: 38,
				y: 34,
				seed: 99689057,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 153,
				type: 0,
				biome_type: 49,
				x: 38,
				y: 35,
				seed: 20996935,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 154,
				type: 0,
				biome_type: 49,
				x: 38,
				y: 36,
				seed: 80334095,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 155,
				type: 10,
				biome_type: 49,
				x: 38,
				y: 37,
				seed: 74627072,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 156,
				type: 0,
				biome_type: 49,
				x: 38,
				y: 38,
				seed: 70193618,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 157,
				type: 21,
				biome_type: 26,
				x: 38,
				y: 41,
				seed: 83196764,
				flags: '0D000',
				is_double: 1,
				master_room_x: 38,
				master_room_y: 41,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 25,
				border_direction: 2
			},
			{
				room_id: 158,
				type: 21,
				biome_type: 26,
				x: 38,
				y: 42,
				seed: 28865674,
				flags: '03000',
				master_room_x: 38,
				master_room_y: 41,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 159,
				type: 0,
				biome_type: 25,
				x: 38,
				y: 45,
				seed: 77284922,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 160,
				type: 10,
				biome_type: 25,
				x: 38,
				y: 46,
				seed: 77256437,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 161,
				type: 0,
				biome_type: 25,
				x: 38,
				y: 47,
				seed: 69246821,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 162,
				type: 0,
				biome_type: 25,
				x: 38,
				y: 48,
				seed: 56439023,
				flags: '02000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 163,
				type: 10,
				biome_type: 47,
				x: 38,
				y: 52,
				seed: 43253530,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 164,
				type: 0,
				biome_type: 5,
				x: 38,
				y: 57,
				seed: 24093383,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 165,
				type: 0,
				biome_type: 5,
				x: 38,
				y: 58,
				seed: 22655432,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 166,
				type: 10,
				biome_type: 5,
				x: 38,
				y: 59,
				seed: 36308128,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 167,
				type: 0,
				biome_type: 5,
				x: 38,
				y: 61,
				seed: 52337814,
				flags: '0C000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 168,
				type: 0,
				biome_type: 5,
				x: 38,
				y: 62,
				seed: 85471587,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 169,
				type: 0,
				biome_type: 5,
				x: 38,
				y: 64,
				seed: 96325197,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 170,
				type: 0,
				biome_type: 49,
				x: 39,
				y: 31,
				seed: 52786051,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 171,
				type: 0,
				biome_type: 49,
				x: 39,
				y: 32,
				seed: 89397264,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 172,
				type: 0,
				biome_type: 49,
				x: 39,
				y: 33,
				seed: 13850163,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 173,
				type: 0,
				biome_type: 49,
				x: 39,
				y: 34,
				seed: 88078444,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 174,
				type: 21,
				biome_type: 26,
				x: 39,
				y: 41,
				seed: 26050385,
				flags: '0D000',
				master_room_x: 38,
				master_room_y: 41,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 27,
				border_direction: 0
			},
			{
				room_id: 175,
				type: 21,
				biome_type: 26,
				x: 39,
				y: 42,
				seed: 13458962,
				flags: '0E000',
				master_room_x: 38,
				master_room_y: 41,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 25,
				border_direction: 3
			},
			{
				room_id: 176,
				type: 0,
				biome_type: 25,
				x: 39,
				y: 43,
				seed: 61818397,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 26,
				border_direction: -1
			},
			{
				room_id: 177,
				type: 0,
				biome_type: 25,
				x: 39,
				y: 44,
				seed: 2528969,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 178,
				type: 0,
				biome_type: 25,
				x: 39,
				y: 45,
				seed: 83483105,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 179,
				type: 0,
				biome_type: 8,
				x: 39,
				y: 50,
				seed: 82177043,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 180,
				type: 4,
				biome_type: 5,
				x: 39,
				y: 56,
				seed: 25023425,
				flags: '09000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000080'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 181,
				type: 0,
				biome_type: 5,
				x: 39,
				y: 57,
				seed: 17304105,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 182,
				type: 10,
				biome_type: 5,
				x: 39,
				y: 62,
				seed: 43415045,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 183,
				type: 12,
				biome_type: 5,
				x: 39,
				y: 63,
				seed: 73704752,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 184,
				type: 0,
				biome_type: 5,
				x: 39,
				y: 64,
				seed: 48283633,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004', '00011FFFFFFFF0', '00011FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 185,
				type: 0,
				biome_type: 49,
				x: 40,
				y: 31,
				seed: 4926849,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 186,
				type: 4,
				biome_type: 49,
				x: 40,
				y: 34,
				seed: 53411194,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00081FFFFFFFF00000000C3'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 187,
				type: 0,
				biome_type: 27,
				x: 40,
				y: 36,
				seed: 15239690,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 188,
				type: 10,
				biome_type: 27,
				x: 40,
				y: 37,
				seed: 8137923,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 189,
				type: 0,
				biome_type: 27,
				x: 40,
				y: 38,
				seed: 97272464,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 190,
				type: 0,
				biome_type: 27,
				x: 40,
				y: 41,
				seed: 29806900,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: 26,
				border_direction: -1
			},
			{
				room_id: 191,
				type: 0,
				biome_type: 25,
				x: 40,
				y: 45,
				seed: 9012600,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 192,
				type: 0,
				biome_type: 8,
				x: 40,
				y: 49,
				seed: 97392145,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 193,
				type: 0,
				biome_type: 8,
				x: 40,
				y: 50,
				seed: 48614041,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 194,
				type: 0,
				biome_type: 8,
				x: 40,
				y: 51,
				seed: 30248196,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 195,
				type: 0,
				biome_type: 8,
				x: 40,
				y: 53,
				seed: 52809751,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 196,
				type: 0,
				biome_type: 8,
				x: 40,
				y: 54,
				seed: 32413599,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 5,
				border_direction: 3
			},
			{
				room_id: 197,
				type: 10,
				biome_type: 5,
				x: 40,
				y: 55,
				seed: 4121710,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 8,
				border_direction: 1
			},
			{
				room_id: 198,
				type: 20,
				biome_type: 5,
				x: 40,
				y: 56,
				seed: 83361413,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 199,
				type: 0,
				biome_type: 6,
				x: 40,
				y: 59,
				seed: 30611162,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 200,
				type: 0,
				biome_type: 6,
				x: 40,
				y: 60,
				seed: 18859042,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 5,
				border_direction: -1
			},
			{
				room_id: 201,
				type: 0,
				biome_type: 5,
				x: 40,
				y: 61,
				seed: 98409297,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: 6,
				border_direction: -1
			},
			{
				room_id: 202,
				type: 0,
				biome_type: 5,
				x: 40,
				y: 62,
				seed: 51578807,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 203,
				type: 10,
				biome_type: 49,
				x: 41,
				y: 30,
				seed: 89914642,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 204,
				type: 4,
				biome_type: 49,
				x: 41,
				y: 31,
				seed: 67142490,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00081FFFFFFFF00000000C2'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 205,
				type: 0,
				biome_type: 49,
				x: 41,
				y: 32,
				seed: 21743624,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 206,
				type: 10,
				biome_type: 49,
				x: 41,
				y: 34,
				seed: 40134153,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 207,
				type: 0,
				biome_type: 27,
				x: 41,
				y: 36,
				seed: 5008073,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 49,
				border_direction: 0
			},
			{
				room_id: 208,
				type: 0,
				biome_type: 27,
				x: 41,
				y: 38,
				seed: 33671452,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 209,
				type: 0,
				biome_type: 27,
				x: 41,
				y: 41,
				seed: 99949560,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 210,
				type: 0,
				biome_type: 25,
				x: 41,
				y: 45,
				seed: 22281630,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 211,
				type: 1,
				biome_type: 25,
				x: 41,
				y: 46,
				seed: 84684075,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 25,
				border_direction: -1
			},
			{
				room_id: 212,
				type: 10,
				biome_type: 25,
				x: 41,
				y: 47,
				seed: 51400666,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 8,
				border_direction: 3
			},
			{
				room_id: 213,
				type: 66,
				biome_type: 8,
				x: 41,
				y: 48,
				seed: 17417205,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 25,
				border_direction: 1
			},
			{
				room_id: 214,
				type: 20,
				biome_type: 8,
				x: 41,
				y: 49,
				seed: 40884854,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 215,
				type: 4,
				biome_type: 8,
				x: 41,
				y: 51,
				seed: 88425558,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000079'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 216,
				type: 20,
				biome_type: 8,
				x: 41,
				y: 53,
				seed: 96467826,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 217,
				type: 0,
				biome_type: 6,
				x: 41,
				y: 59,
				seed: 15056527,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 218,
				type: 0,
				biome_type: 49,
				x: 42,
				y: 32,
				seed: 54666399,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 219,
				type: 0,
				biome_type: 49,
				x: 42,
				y: 33,
				seed: 17571791,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 220,
				type: 0,
				biome_type: 49,
				x: 42,
				y: 34,
				seed: 44249948,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 221,
				type: 66,
				biome_type: 49,
				x: 42,
				y: 36,
				seed: 87674479,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 27,
				border_direction: 2
			},
			{
				room_id: 222,
				type: 0,
				biome_type: 27,
				x: 42,
				y: 38,
				seed: 44390268,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 223,
				type: 0,
				biome_type: 27,
				x: 42,
				y: 39,
				seed: 32902855,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 224,
				type: 0,
				biome_type: 27,
				x: 42,
				y: 40,
				seed: 24768918,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 225,
				type: 10,
				biome_type: 27,
				x: 42,
				y: 41,
				seed: 63781261,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 226,
				type: 4,
				biome_type: 8,
				x: 42,
				y: 49,
				seed: 93344035,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000077'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 227,
				type: 0,
				biome_type: 8,
				x: 42,
				y: 51,
				seed: 837261,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00002'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 228,
				type: 10,
				biome_type: 8,
				x: 42,
				y: 52,
				seed: 84034462,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 229,
				type: 0,
				biome_type: 8,
				x: 42,
				y: 53,
				seed: 50948537,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 230,
				type: 0,
				biome_type: 6,
				x: 42,
				y: 58,
				seed: 92253580,
				flags: '08000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 231,
				type: 10,
				biome_type: 6,
				x: 42,
				y: 59,
				seed: 50988702,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 232,
				type: 0,
				biome_type: 6,
				x: 42,
				y: 60,
				seed: 50452549,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004', '00011FFFFFFFF0', '00011FFFFFFFF0'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 233,
				type: 0,
				biome_type: 6,
				x: 42,
				y: 61,
				seed: 85355305,
				flags: '03000',
				objective_complete: 0,
				objects: ['00011FFFFFFFF0', '00011FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 234,
				type: 0,
				biome_type: 49,
				x: 43,
				y: 31,
				seed: 16292634,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 235,
				type: 0,
				biome_type: 49,
				x: 43,
				y: 32,
				seed: 4402048,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 236,
				type: 0,
				biome_type: 49,
				x: 43,
				y: 33,
				seed: 32650211,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 237,
				type: 10,
				biome_type: 49,
				x: 43,
				y: 36,
				seed: 86950389,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 238,
				type: 0,
				biome_type: 27,
				x: 43,
				y: 39,
				seed: 18925910,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 239,
				type: 0,
				biome_type: 27,
				x: 43,
				y: 41,
				seed: 71985400,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 240,
				type: 0,
				biome_type: 27,
				x: 43,
				y: 42,
				seed: 52703952,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 241,
				type: 0,
				biome_type: 27,
				x: 43,
				y: 43,
				seed: 70574371,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 242,
				type: 0,
				biome_type: 8,
				x: 43,
				y: 49,
				seed: 70614561,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 243,
				type: 4,
				biome_type: 8,
				x: 43,
				y: 51,
				seed: 29469236,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000007A'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 244,
				type: 0,
				biome_type: 8,
				x: 43,
				y: 53,
				seed: 25754297,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 245,
				type: 0,
				biome_type: 8,
				x: 43,
				y: 54,
				seed: 44811335,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 246,
				type: 70,
				biome_type: 8,
				x: 43,
				y: 55,
				seed: 79942069,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 247,
				type: 0,
				biome_type: 6,
				x: 43,
				y: 61,
				seed: 82710771,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 248,
				type: 0,
				biome_type: 6,
				x: 43,
				y: 62,
				seed: 43205526,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 249,
				type: 0,
				biome_type: 6,
				x: 43,
				y: 63,
				seed: 51792575,
				flags: '02000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 250,
				type: 47,
				biome_type: 49,
				x: 44,
				y: 29,
				seed: 49693352,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 49,
				border_direction: -1
			},
			{
				room_id: 251,
				type: 0,
				biome_type: 49,
				x: 44,
				y: 30,
				seed: 42193358,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 252,
				type: 0,
				biome_type: 49,
				x: 44,
				y: 31,
				seed: 41117203,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 253,
				type: 0,
				biome_type: 49,
				x: 44,
				y: 33,
				seed: 86861359,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 254,
				type: 0,
				biome_type: 49,
				x: 44,
				y: 36,
				seed: 47062531,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 255,
				type: 0,
				biome_type: 27,
				x: 44,
				y: 39,
				seed: 10570864,
				flags: '04000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 256,
				type: 0,
				biome_type: 27,
				x: 44,
				y: 43,
				seed: 41432720,
				flags: '04000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 257,
				type: 0,
				biome_type: 8,
				x: 44,
				y: 47,
				seed: 98096829,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 258,
				type: 0,
				biome_type: 8,
				x: 44,
				y: 48,
				seed: 4251687,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 259,
				type: 0,
				biome_type: 8,
				x: 44,
				y: 49,
				seed: 61660926,
				flags: '0F000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 260,
				type: 0,
				biome_type: 8,
				x: 44,
				y: 50,
				seed: 26921934,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00001'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 261,
				type: 0,
				biome_type: 8,
				x: 44,
				y: 51,
				seed: 4920181,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00002'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 262,
				type: 0,
				biome_type: 6,
				x: 44,
				y: 61,
				seed: 86313693,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 263,
				type: 0,
				biome_type: 49,
				x: 45,
				y: 31,
				seed: 80592256,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 264,
				type: 4,
				biome_type: 49,
				x: 45,
				y: 33,
				seed: 20609642,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00081FFFFFFFF00000000C0'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 265,
				type: 0,
				biome_type: 49,
				x: 45,
				y: 34,
				seed: 98855335,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 266,
				type: 0,
				biome_type: 49,
				x: 45,
				y: 35,
				seed: 31777209,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 267,
				type: 26,
				biome_type: 49,
				x: 45,
				y: 36,
				seed: 27845919,
				flags: '0F000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 268,
				type: 20,
				biome_type: 49,
				x: 45,
				y: 37,
				seed: 97253228,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 269,
				type: 4,
				biome_type: 8,
				x: 45,
				y: 47,
				seed: 6875390,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000078'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 270,
				type: 10,
				biome_type: 8,
				x: 45,
				y: 49,
				seed: 28266290,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 271,
				type: 0,
				biome_type: 8,
				x: 45,
				y: 51,
				seed: 39464328,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 272,
				type: 4,
				biome_type: 23,
				x: 45,
				y: 57,
				seed: 82537559,
				flags: '01000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000070'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 273,
				type: 47,
				biome_type: 6,
				x: 45,
				y: 60,
				seed: 83350523,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 6,
				border_direction: -1
			},
			{
				room_id: 274,
				type: 0,
				biome_type: 6,
				x: 45,
				y: 61,
				seed: 73647274,
				flags: '06000',
				objective_complete: 0,
				objects: ['00011FFFFFFFF0', '00011FFFFFFFF0'],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 275,
				type: 0,
				biome_type: 49,
				x: 46,
				y: 31,
				seed: 18812117,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 276,
				type: 0,
				biome_type: 49,
				x: 46,
				y: 32,
				seed: 28244070,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 277,
				type: 10,
				biome_type: 49,
				x: 46,
				y: 33,
				seed: 10860741,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 278,
				type: 0,
				biome_type: 49,
				x: 46,
				y: 36,
				seed: 95569466,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 279,
				type: 0,
				biome_type: 10,
				x: 46,
				y: 41,
				seed: 75895876,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 280,
				type: 0,
				biome_type: 8,
				x: 46,
				y: 47,
				seed: 50316384,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 281,
				type: 1,
				biome_type: 8,
				x: 46,
				y: 48,
				seed: 90327542,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 282,
				type: 0,
				biome_type: 8,
				x: 46,
				y: 49,
				seed: 81199761,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 283,
				type: 12,
				biome_type: 8,
				x: 46,
				y: 51,
				seed: 99332759,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 284,
				type: 10,
				biome_type: 8,
				x: 46,
				y: 52,
				seed: 67069171,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 23,
				border_direction: 3
			},
			{
				room_id: 285,
				type: 0,
				biome_type: 23,
				x: 46,
				y: 53,
				seed: 24556449,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00001'],
				difficulty: 2,
				border: 8,
				border_direction: 1
			},
			{
				room_id: 286,
				type: 0,
				biome_type: 23,
				x: 46,
				y: 54,
				seed: 39987694,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 287,
				type: 0,
				biome_type: 23,
				x: 46,
				y: 55,
				seed: 41331105,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 288,
				type: 41,
				biome_type: 23,
				x: 46,
				y: 57,
				seed: 26047050,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 289,
				type: 0,
				biome_type: 49,
				x: 47,
				y: 32,
				seed: 30394259,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 290,
				type: 0,
				biome_type: 49,
				x: 47,
				y: 36,
				seed: 57028963,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 291,
				type: 0,
				biome_type: 49,
				x: 47,
				y: 37,
				seed: 61016243,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 292,
				type: 10,
				biome_type: 49,
				x: 47,
				y: 38,
				seed: 28582278,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 293,
				type: 0,
				biome_type: 10,
				x: 47,
				y: 41,
				seed: 50872895,
				flags: '05000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 294,
				type: 0,
				biome_type: 10,
				x: 47,
				y: 43,
				seed: 92700979,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 295,
				type: 0,
				biome_type: 8,
				x: 47,
				y: 47,
				seed: 242914,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 296,
				type: 0,
				biome_type: 23,
				x: 47,
				y: 55,
				seed: 85735575,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 297,
				type: 10,
				biome_type: 23,
				x: 47,
				y: 56,
				seed: 73461282,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 298,
				type: 0,
				biome_type: 23,
				x: 47,
				y: 57,
				seed: 71455828,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 299,
				type: 0,
				biome_type: 29,
				x: 48,
				y: 30,
				seed: 23560794,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 300,
				type: 0,
				biome_type: 49,
				x: 48,
				y: 32,
				seed: 70873415,
				flags: '0C000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 301,
				type: 0,
				biome_type: 49,
				x: 48,
				y: 33,
				seed: 22514141,
				flags: '02000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 302,
				type: 0,
				biome_type: 10,
				x: 48,
				y: 41,
				seed: 17292043,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 303,
				type: 0,
				biome_type: 10,
				x: 48,
				y: 42,
				seed: 53984258,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 304,
				type: 0,
				biome_type: 10,
				x: 48,
				y: 43,
				seed: 12393492,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 305,
				type: 10,
				biome_type: 10,
				x: 48,
				y: 44,
				seed: 92413554,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 306,
				type: 0,
				biome_type: 10,
				x: 48,
				y: 45,
				seed: 54516927,
				flags: '02000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00141FFFFFFFF0',
					'00141FFFFFFFF0',
					'00141FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 307,
				type: 0,
				biome_type: 8,
				x: 48,
				y: 47,
				seed: 78034556,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 308,
				type: 0,
				biome_type: 23,
				x: 48,
				y: 49,
				seed: 22747184,
				flags: '01000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 309,
				type: 20,
				biome_type: 23,
				x: 48,
				y: 54,
				seed: 52683137,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 310,
				type: 41,
				biome_type: 23,
				x: 48,
				y: 55,
				seed: 47308053,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 311,
				type: 0,
				biome_type: 1,
				x: 48,
				y: 61,
				seed: 85430621,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000', '00001FFFFFFFF00000'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 312,
				type: 10,
				biome_type: 29,
				x: 49,
				y: 30,
				seed: 83284569,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 313,
				type: 0,
				biome_type: 29,
				x: 49,
				y: 35,
				seed: 61558237,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 314,
				type: 10,
				biome_type: 29,
				x: 49,
				y: 36,
				seed: 48243320,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 315,
				type: 27,
				biome_type: 10,
				x: 49,
				y: 39,
				seed: 84524630,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 316,
				type: 0,
				biome_type: 10,
				x: 49,
				y: 42,
				seed: 14581895,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 317,
				type: 0,
				biome_type: 10,
				x: 49,
				y: 44,
				seed: 24925388,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00141FFFFFFFF0',
					'00141FFFFFFFF0',
					'00141FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 318,
				type: 10,
				biome_type: 8,
				x: 49,
				y: 47,
				seed: 14538628,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 319,
				type: 4,
				biome_type: 23,
				x: 49,
				y: 49,
				seed: 73289732,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000071'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 320,
				type: 41,
				biome_type: 23,
				x: 49,
				y: 50,
				seed: 84915672,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 321,
				type: 0,
				biome_type: 23,
				x: 49,
				y: 51,
				seed: 8149135,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 322,
				type: 10,
				biome_type: 23,
				x: 49,
				y: 52,
				seed: 90950519,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 323,
				type: 0,
				biome_type: 23,
				x: 49,
				y: 53,
				seed: 59614294,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 324,
				type: 0,
				biome_type: 23,
				x: 49,
				y: 54,
				seed: 78398120,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 325,
				type: 0,
				biome_type: 1,
				x: 49,
				y: 60,
				seed: 60353539,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 326,
				type: 0,
				biome_type: 1,
				x: 49,
				y: 61,
				seed: 36922002,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 327,
				type: 0,
				biome_type: 29,
				x: 50,
				y: 30,
				seed: 8799068,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 328,
				type: 4,
				biome_type: 29,
				x: 50,
				y: 31,
				seed: 11934494,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00081FFFFFFFF00000000AC'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 329,
				type: 0,
				biome_type: 29,
				x: 50,
				y: 32,
				seed: 23181737,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 330,
				type: 0,
				biome_type: 29,
				x: 50,
				y: 33,
				seed: 44891180,
				flags: '02000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 331,
				type: 0,
				biome_type: 29,
				x: 50,
				y: 35,
				seed: 24602767,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 332,
				type: 20,
				biome_type: 10,
				x: 50,
				y: 38,
				seed: 14006925,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 333,
				type: 0,
				biome_type: 10,
				x: 50,
				y: 39,
				seed: 99548313,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 334,
				type: 10,
				biome_type: 10,
				x: 50,
				y: 40,
				seed: 84419063,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 335,
				type: 4,
				biome_type: 10,
				x: 50,
				y: 41,
				seed: 50225356,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000008A'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 336,
				type: 0,
				biome_type: 10,
				x: 50,
				y: 42,
				seed: 57995004,
				flags: '0F000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 337,
				type: 0,
				biome_type: 10,
				x: 50,
				y: 43,
				seed: 18664991,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 338,
				type: 0,
				biome_type: 10,
				x: 50,
				y: 44,
				seed: 93951589,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 339,
				type: 0,
				biome_type: 23,
				x: 50,
				y: 51,
				seed: 42422660,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 340,
				type: 27,
				biome_type: 23,
				x: 50,
				y: 54,
				seed: 14479726,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 341,
				type: 0,
				biome_type: 1,
				x: 50,
				y: 60,
				seed: 83589825,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 342,
				type: 26,
				biome_type: 29,
				x: 51,
				y: 30,
				seed: 2698221,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 343,
				type: 0,
				biome_type: 29,
				x: 51,
				y: 32,
				seed: 96955653,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 344,
				type: 0,
				biome_type: 29,
				x: 51,
				y: 34,
				seed: 59356711,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 345,
				type: 0,
				biome_type: 29,
				x: 51,
				y: 35,
				seed: 43463797,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 346,
				type: 20,
				biome_type: 29,
				x: 51,
				y: 36,
				seed: 65127551,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 347,
				type: 0,
				biome_type: 10,
				x: 51,
				y: 40,
				seed: 41410692,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 348,
				type: 0,
				biome_type: 10,
				x: 51,
				y: 42,
				seed: 19962097,
				flags: '04000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 349,
				type: 0,
				biome_type: 10,
				x: 51,
				y: 44,
				seed: 1994367,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 350,
				type: 20,
				biome_type: 23,
				x: 51,
				y: 50,
				seed: 35944808,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 351,
				type: 0,
				biome_type: 23,
				x: 51,
				y: 51,
				seed: 71609822,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 352,
				type: 4,
				biome_type: 23,
				x: 51,
				y: 54,
				seed: 28363221,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000006E'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 353,
				type: 0,
				biome_type: 23,
				x: 51,
				y: 55,
				seed: 29003923,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 354,
				type: 10,
				biome_type: 23,
				x: 51,
				y: 56,
				seed: 39106067,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 1,
				border_direction: 3
			},
			{
				room_id: 355,
				type: 59,
				biome_type: 1,
				x: 51,
				y: 57,
				seed: 86278577,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 23,
				border_direction: 1
			},
			{
				room_id: 356,
				type: 4,
				biome_type: 1,
				x: 51,
				y: 58,
				seed: 2069831,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000065'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 357,
				type: 0,
				biome_type: 1,
				x: 51,
				y: 59,
				seed: 10075618,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 358,
				type: 10,
				biome_type: 1,
				x: 51,
				y: 60,
				seed: 98742504,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 359,
				type: 0,
				biome_type: 29,
				x: 52,
				y: 30,
				seed: 7800318,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 360,
				type: 0,
				biome_type: 29,
				x: 52,
				y: 31,
				seed: 37973745,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 361,
				type: 0,
				biome_type: 29,
				x: 52,
				y: 32,
				seed: 15807024,
				flags: '0F000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 362,
				type: 10,
				biome_type: 29,
				x: 52,
				y: 33,
				seed: 44029717,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 363,
				type: 0,
				biome_type: 29,
				x: 52,
				y: 34,
				seed: 7595676,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 364,
				type: 0,
				biome_type: 29,
				x: 52,
				y: 36,
				seed: 72185263,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 365,
				type: 0,
				biome_type: 10,
				x: 52,
				y: 39,
				seed: 21300296,
				flags: '09000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 366,
				type: 0,
				biome_type: 10,
				x: 52,
				y: 40,
				seed: 69158689,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 367,
				type: 4,
				biome_type: 10,
				x: 52,
				y: 44,
				seed: 52905180,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000008D'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 368,
				type: 0,
				biome_type: 10,
				x: 52,
				y: 45,
				seed: 83183219,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 369,
				type: 10,
				biome_type: 10,
				x: 52,
				y: 46,
				seed: 30387533,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 23,
				border_direction: 3
			},
			{
				room_id: 370,
				type: 0,
				biome_type: 23,
				x: 52,
				y: 47,
				seed: 36425411,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: 10,
				border_direction: 1
			},
			{
				room_id: 371,
				type: 0,
				biome_type: 23,
				x: 52,
				y: 48,
				seed: 24575414,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 372,
				type: 1,
				biome_type: 23,
				x: 52,
				y: 49,
				seed: 21701230,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 23,
				border_direction: -1
			},
			{
				room_id: 373,
				type: 26,
				biome_type: 23,
				x: 52,
				y: 50,
				seed: 82790569,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 374,
				type: 0,
				biome_type: 1,
				x: 52,
				y: 58,
				seed: 80107244,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 375,
				type: 0,
				biome_type: 1,
				x: 52,
				y: 60,
				seed: 42619491,
				flags: '04000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 376,
				type: 12,
				biome_type: 29,
				x: 53,
				y: 32,
				seed: 70297237,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 377,
				type: 0,
				biome_type: 29,
				x: 53,
				y: 34,
				seed: 31397906,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 378,
				type: 0,
				biome_type: 29,
				x: 53,
				y: 36,
				seed: 77564592,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 379,
				type: 63,
				biome_type: 29,
				x: 53,
				y: 37,
				seed: 77516254,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 10,
				border_direction: 3
			},
			{
				room_id: 380,
				type: 4,
				biome_type: 10,
				x: 53,
				y: 38,
				seed: 72957426,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000008E'],
				difficulty: 2,
				border: 29,
				border_direction: 1
			},
			{
				room_id: 381,
				type: 1,
				biome_type: 10,
				x: 53,
				y: 39,
				seed: 84670279,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 382,
				type: 12,
				biome_type: 10,
				x: 53,
				y: 45,
				seed: 79196677,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 383,
				type: 27,
				biome_type: 1,
				x: 53,
				y: 58,
				seed: 39397112,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 384,
				type: 0,
				biome_type: 29,
				x: 54,
				y: 31,
				seed: 13026036,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 385,
				type: 4,
				biome_type: 29,
				x: 54,
				y: 32,
				seed: 3510192,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00081FFFFFFFF00000000B2'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 386,
				type: 0,
				biome_type: 29,
				x: 54,
				y: 34,
				seed: 55117068,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 387,
				type: 70,
				biome_type: 29,
				x: 54,
				y: 35,
				seed: 84447877,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 388,
				type: 0,
				biome_type: 29,
				x: 54,
				y: 36,
				seed: 80069099,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 389,
				type: 0,
				biome_type: 10,
				x: 54,
				y: 39,
				seed: 52836731,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 390,
				type: 20,
				biome_type: 10,
				x: 54,
				y: 40,
				seed: 17555937,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 391,
				type: 21,
				biome_type: 12,
				x: 54,
				y: 42,
				seed: 65738462,
				flags: '09000',
				is_double: 1,
				master_room_x: 54,
				master_room_y: 42,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 392,
				type: 21,
				biome_type: 12,
				x: 54,
				y: 43,
				seed: 41987052,
				flags: '0B000',
				master_room_x: 54,
				master_room_y: 42,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 10,
				border_direction: 3
			},
			{
				room_id: 393,
				type: 0,
				biome_type: 10,
				x: 54,
				y: 44,
				seed: 44206915,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 1,
				border: 12,
				border_direction: -1
			},
			{
				room_id: 394,
				type: 0,
				biome_type: 10,
				x: 54,
				y: 45,
				seed: 16837786,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 395,
				type: 22,
				biome_type: 45,
				x: 54,
				y: 54,
				seed: 31090697,
				flags: '09000',
				is_double: 1,
				master_room_x: 54,
				master_room_y: 54,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 396,
				type: 22,
				biome_type: 45,
				x: 54,
				y: 55,
				seed: 65720430,
				flags: '0B000',
				master_room_x: 54,
				master_room_y: 54,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 1,
				border_direction: 3
			},
			{
				room_id: 397,
				type: 0,
				biome_type: 1,
				x: 54,
				y: 56,
				seed: 38029008,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 2,
				border: 45,
				border_direction: -1
			},
			{
				room_id: 398,
				type: 1,
				biome_type: 1,
				x: 54,
				y: 57,
				seed: 44878244,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 399,
				type: 0,
				biome_type: 1,
				x: 54,
				y: 58,
				seed: 23263248,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00001'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 400,
				type: 47,
				biome_type: 29,
				x: 55,
				y: 29,
				seed: 23424573,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 29,
				border_direction: -1
			},
			{
				room_id: 401,
				type: 0,
				biome_type: 29,
				x: 55,
				y: 30,
				seed: 8560193,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 402,
				type: 0,
				biome_type: 29,
				x: 55,
				y: 31,
				seed: 31036642,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 403,
				type: 0,
				biome_type: 29,
				x: 55,
				y: 35,
				seed: 78325182,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 404,
				type: 0,
				biome_type: 10,
				x: 55,
				y: 40,
				seed: 50852461,
				flags: '0C000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00141FFFFFFFF0',
					'00141FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 405,
				type: 0,
				biome_type: 10,
				x: 55,
				y: 41,
				seed: 17659208,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 2,
				border: 12,
				border_direction: -1
			},
			{
				room_id: 406,
				type: 21,
				biome_type: 12,
				x: 55,
				y: 42,
				seed: 31604863,
				flags: '0E000',
				master_room_x: 54,
				master_room_y: 42,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 10,
				border_direction: 1
			},
			{
				room_id: 407,
				type: 21,
				biome_type: 12,
				x: 55,
				y: 43,
				seed: 17374832,
				flags: '06000',
				master_room_x: 54,
				master_room_y: 42,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 408,
				type: 0,
				biome_type: 10,
				x: 55,
				y: 45,
				seed: 14251499,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 409,
				type: 0,
				biome_type: 10,
				x: 55,
				y: 46,
				seed: 1664149,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 410,
				type: 26,
				biome_type: 10,
				x: 55,
				y: 47,
				seed: 68333602,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 411,
				type: 0,
				biome_type: 1,
				x: 55,
				y: 52,
				seed: 21457638,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 412,
				type: 0,
				biome_type: 1,
				x: 55,
				y: 53,
				seed: 39037607,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000'
				],
				difficulty: 2,
				border: 45,
				border_direction: -1
			},
			{
				room_id: 413,
				type: 22,
				biome_type: 45,
				x: 55,
				y: 54,
				seed: 76720851,
				flags: '0E000',
				master_room_x: 54,
				master_room_y: 54,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 1,
				border_direction: 1
			},
			{
				room_id: 414,
				type: 22,
				biome_type: 45,
				x: 55,
				y: 55,
				seed: 12693047,
				flags: '06000',
				master_room_x: 54,
				master_room_y: 54,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 415,
				type: 10,
				biome_type: 1,
				x: 55,
				y: 57,
				seed: 54652438,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 416,
				type: 10,
				biome_type: 29,
				x: 56,
				y: 31,
				seed: 15486521,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 417,
				type: 0,
				biome_type: 29,
				x: 56,
				y: 32,
				seed: 24991547,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 418,
				type: 0,
				biome_type: 29,
				x: 56,
				y: 35,
				seed: 90845174,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 419,
				type: 0,
				biome_type: 29,
				x: 56,
				y: 36,
				seed: 78534574,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 420,
				type: 10,
				biome_type: 29,
				x: 56,
				y: 37,
				seed: 32543665,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 421,
				type: 0,
				biome_type: 10,
				x: 56,
				y: 47,
				seed: 8137816,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 422,
				type: 47,
				biome_type: 1,
				x: 56,
				y: 50,
				seed: 39383235,
				flags: '09003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 423,
				type: 0,
				biome_type: 1,
				x: 56,
				y: 51,
				seed: 61275445,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 424,
				type: 4,
				biome_type: 1,
				x: 56,
				y: 52,
				seed: 20629745,
				flags: '07000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000190'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 425,
				type: 0,
				biome_type: 1,
				x: 56,
				y: 57,
				seed: 31418698,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 426,
				type: 0,
				biome_type: 29,
				x: 57,
				y: 32,
				seed: 57807313,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 427,
				type: 0,
				biome_type: 29,
				x: 57,
				y: 33,
				seed: 26585811,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 428,
				type: 0,
				biome_type: 29,
				x: 57,
				y: 34,
				seed: 51495245,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 429,
				type: 0,
				biome_type: 29,
				x: 57,
				y: 35,
				seed: 35839116,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 430,
				type: 0,
				biome_type: 13,
				x: 57,
				y: 40,
				seed: 46376410,
				flags: '01000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 431,
				type: 10,
				biome_type: 13,
				x: 57,
				y: 44,
				seed: 97132137,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 432,
				type: 63,
				biome_type: 13,
				x: 57,
				y: 45,
				seed: 72929514,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 10,
				border_direction: 3
			},
			{
				room_id: 433,
				type: 65,
				biome_type: 10,
				x: 57,
				y: 46,
				seed: 67473940,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 13,
				border_direction: 1
			},
			{
				room_id: 434,
				type: 0,
				biome_type: 10,
				x: 57,
				y: 47,
				seed: 92082277,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 435,
				type: 0,
				biome_type: 1,
				x: 57,
				y: 50,
				seed: 47745409,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 436,
				type: 0,
				biome_type: 1,
				x: 57,
				y: 52,
				seed: 57682122,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 437,
				type: 0,
				biome_type: 1,
				x: 57,
				y: 56,
				seed: 27355566,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 438,
				type: 4,
				biome_type: 1,
				x: 57,
				y: 57,
				seed: 23592421,
				flags: '06000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000068'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 439,
				type: 69,
				biome_type: 1,
				x: 57,
				y: 60,
				seed: 88839431,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 4,
				border_direction: -1
			},
			{
				room_id: 440,
				type: 2,
				biome_type: 4,
				x: 57,
				y: 61,
				seed: 10937035,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 42,
				border_direction: -1
			},
			{
				room_id: 441,
				type: 0,
				biome_type: 3,
				x: 57,
				y: 62,
				seed: 8648613,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000', '00001FFFFFFFF00000'],
				difficulty: 0,
				border: 4,
				border_direction: -1
			},
			{
				room_id: 442,
				type: 0,
				biome_type: 29,
				x: 58,
				y: 32,
				seed: 59218467,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 443,
				type: 0,
				biome_type: 13,
				x: 58,
				y: 39,
				seed: 38551304,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 444,
				type: 0,
				biome_type: 13,
				x: 58,
				y: 40,
				seed: 30956871,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 445,
				type: 10,
				biome_type: 13,
				x: 58,
				y: 41,
				seed: 85904053,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 446,
				type: 0,
				biome_type: 13,
				x: 58,
				y: 44,
				seed: 41453112,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 447,
				type: 0,
				biome_type: 1,
				x: 58,
				y: 49,
				seed: 76880889,
				flags: '08000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 448,
				type: 0,
				biome_type: 1,
				x: 58,
				y: 50,
				seed: 74023030,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 449,
				type: 0,
				biome_type: 1,
				x: 58,
				y: 51,
				seed: 28246365,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000', '00001FFFFFFFF00000'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 450,
				type: 26,
				biome_type: 1,
				x: 58,
				y: 52,
				seed: 86908972,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 451,
				type: 0,
				biome_type: 1,
				x: 58,
				y: 53,
				seed: 30590469,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 452,
				type: 20,
				biome_type: 1,
				x: 58,
				y: 54,
				seed: 8402754,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 453,
				type: 0,
				biome_type: 1,
				x: 58,
				y: 55,
				seed: 74678397,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 454,
				type: 0,
				biome_type: 1,
				x: 58,
				y: 56,
				seed: 3050561,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 455,
				type: 0,
				biome_type: 3,
				x: 58,
				y: 62,
				seed: 24609798,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 456,
				type: 0,
				biome_type: 29,
				x: 59,
				y: 31,
				seed: 71025702,
				flags: '08000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 457,
				type: 0,
				biome_type: 29,
				x: 59,
				y: 32,
				seed: 16977666,
				flags: '0F000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 458,
				type: 0,
				biome_type: 29,
				x: 59,
				y: 33,
				seed: 8652653,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 459,
				type: 0,
				biome_type: 29,
				x: 59,
				y: 34,
				seed: 56391560,
				flags: '02000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 460,
				type: 4,
				biome_type: 13,
				x: 59,
				y: 39,
				seed: 96053397,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000095'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 461,
				type: 20,
				biome_type: 13,
				x: 59,
				y: 41,
				seed: 96491127,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 462,
				type: 0,
				biome_type: 13,
				x: 59,
				y: 42,
				seed: 35250982,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 463,
				type: 0,
				biome_type: 13,
				x: 59,
				y: 43,
				seed: 81276476,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 464,
				type: 0,
				biome_type: 13,
				x: 59,
				y: 44,
				seed: 25769518,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 465,
				type: 26,
				biome_type: 13,
				x: 59,
				y: 45,
				seed: 4437112,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 466,
				type: 10,
				biome_type: 1,
				x: 59,
				y: 51,
				seed: 59957299,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 467,
				type: 0,
				biome_type: 1,
				x: 59,
				y: 53,
				seed: 30758052,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 468,
				type: 0,
				biome_type: 1,
				x: 59,
				y: 56,
				seed: 90917233,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 469,
				type: 21,
				biome_type: 44,
				x: 59,
				y: 59,
				seed: 5059854,
				flags: '09000',
				is_double: 1,
				master_room_x: 59,
				master_room_y: 59,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 470,
				type: 21,
				biome_type: 44,
				x: 59,
				y: 60,
				seed: 71154792,
				flags: '0B000',
				master_room_x: 59,
				master_room_y: 59,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 3,
				border_direction: 3
			},
			{
				room_id: 471,
				type: 0,
				biome_type: 3,
				x: 59,
				y: 61,
				seed: 81028808,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000'
				],
				difficulty: 0,
				border: 44,
				border_direction: -1
			},
			{
				room_id: 472,
				type: 0,
				biome_type: 3,
				x: 59,
				y: 62,
				seed: 36190880,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 473,
				type: 4,
				biome_type: 29,
				x: 60,
				y: 30,
				seed: 17155099,
				flags: '01000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF00000000B3'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 474,
				type: 0,
				biome_type: 29,
				x: 60,
				y: 32,
				seed: 53860543,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 475,
				type: 47,
				biome_type: 13,
				x: 60,
				y: 37,
				seed: 75754041,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 13,
				border_direction: -1
			},
			{
				room_id: 476,
				type: 0,
				biome_type: 13,
				x: 60,
				y: 38,
				seed: 80441670,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 477,
				type: 0,
				biome_type: 13,
				x: 60,
				y: 39,
				seed: 78773753,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 478,
				type: 0,
				biome_type: 13,
				x: 60,
				y: 45,
				seed: 63792097,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 479,
				type: 0,
				biome_type: 1,
				x: 60,
				y: 51,
				seed: 26184474,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 480,
				type: 0,
				biome_type: 1,
				x: 60,
				y: 52,
				seed: 54616123,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 481,
				type: 0,
				biome_type: 1,
				x: 60,
				y: 53,
				seed: 36240359,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 482,
				type: 0,
				biome_type: 1,
				x: 60,
				y: 54,
				seed: 7151466,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 483,
				type: 0,
				biome_type: 1,
				x: 60,
				y: 55,
				seed: 88493732,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 484,
				type: 0,
				biome_type: 1,
				x: 60,
				y: 56,
				seed: 9158522,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000', '00001FFFFFFFF00001'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 485,
				type: 0,
				biome_type: 1,
				x: 60,
				y: 57,
				seed: 68616371,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 486,
				type: 0,
				biome_type: 1,
				x: 60,
				y: 58,
				seed: 54001358,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000'],
				difficulty: 0,
				border: 44,
				border_direction: -1
			},
			{
				room_id: 487,
				type: 21,
				biome_type: 44,
				x: 60,
				y: 59,
				seed: 7875057,
				flags: '0E000',
				master_room_x: 59,
				master_room_y: 59,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 1,
				border_direction: 1
			},
			{
				room_id: 488,
				type: 21,
				biome_type: 44,
				x: 60,
				y: 60,
				seed: 30392134,
				flags: '06000',
				master_room_x: 59,
				master_room_y: 59,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 489,
				type: 0,
				biome_type: 29,
				x: 61,
				y: 30,
				seed: 12354372,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 490,
				type: 10,
				biome_type: 29,
				x: 61,
				y: 32,
				seed: 98239723,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 491,
				type: 27,
				biome_type: 29,
				x: 61,
				y: 33,
				seed: 44050150,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 492,
				type: 0,
				biome_type: 29,
				x: 61,
				y: 34,
				seed: 23022980,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 493,
				type: 0,
				biome_type: 13,
				x: 61,
				y: 38,
				seed: 57069505,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 494,
				type: 21,
				biome_type: 46,
				x: 61,
				y: 42,
				seed: 24460249,
				flags: '09000',
				is_double: 1,
				master_room_x: 61,
				master_room_y: 42,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 495,
				type: 21,
				biome_type: 46,
				x: 61,
				y: 43,
				seed: 66316435,
				flags: '0B000',
				master_room_x: 61,
				master_room_y: 42,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 13,
				border_direction: 3
			},
			{
				room_id: 496,
				type: 0,
				biome_type: 13,
				x: 61,
				y: 44,
				seed: 34360006,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 1,
				border: 46,
				border_direction: -1
			},
			{
				room_id: 497,
				type: 4,
				biome_type: 13,
				x: 61,
				y: 45,
				seed: 35119087,
				flags: '06000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000093'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 498,
				type: 4,
				biome_type: 1,
				x: 61,
				y: 55,
				seed: 8553585,
				flags: '04000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000067'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 499,
				type: 0,
				biome_type: 29,
				x: 62,
				y: 30,
				seed: 40692096,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 500,
				type: 0,
				biome_type: 29,
				x: 62,
				y: 31,
				seed: 71454051,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 501,
				type: 20,
				biome_type: 29,
				x: 62,
				y: 32,
				seed: 60435470,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 502,
				type: 0,
				biome_type: 29,
				x: 62,
				y: 34,
				seed: 5720073,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 503,
				type: 20,
				biome_type: 13,
				x: 62,
				y: 37,
				seed: 75670073,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 504,
				type: 10,
				biome_type: 13,
				x: 62,
				y: 38,
				seed: 57711044,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 505,
				type: 0,
				biome_type: 13,
				x: 62,
				y: 39,
				seed: 84586053,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 506,
				type: 4,
				biome_type: 13,
				x: 62,
				y: 40,
				seed: 33569818,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000097'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 507,
				type: 0,
				biome_type: 13,
				x: 62,
				y: 41,
				seed: 43079147,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 2,
				border: 46,
				border_direction: -1
			},
			{
				room_id: 508,
				type: 21,
				biome_type: 46,
				x: 62,
				y: 42,
				seed: 93460979,
				flags: '0E000',
				master_room_x: 61,
				master_room_y: 42,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 13,
				border_direction: 1
			},
			{
				room_id: 509,
				type: 21,
				biome_type: 46,
				x: 62,
				y: 43,
				seed: 52465914,
				flags: '06000',
				master_room_x: 61,
				master_room_y: 42,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 510,
				type: 0,
				biome_type: 13,
				x: 63,
				y: 39,
				seed: 36021679,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 511,
				type: 12,
				biome_type: 13,
				x: 64,
				y: 39,
				seed: 77606749,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			}
		]
	},
	{
		name: 'Worldgen 2',
		sprite: 's_mapgen_test_2',
		spawn_x: 33,
		spawn_y: 63,
		rooms: [
			{
				room_id: 0,
				type: 21,
				biome_type: 46,
				x: 30,
				y: 40,
				seed: 48405172,
				flags: '09000',
				is_double: 1,
				master_room_x: 30,
				master_room_y: 40,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 1,
				type: 21,
				biome_type: 46,
				x: 30,
				y: 41,
				seed: 43460188,
				flags: '0B000',
				master_room_x: 30,
				master_room_y: 40,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 13,
				border_direction: 3
			},
			{
				room_id: 2,
				type: 4,
				biome_type: 13,
				x: 30,
				y: 42,
				seed: 64299297,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000093'],
				difficulty: 2,
				border: 46,
				border_direction: -1
			},
			{
				room_id: 3,
				type: 0,
				biome_type: 13,
				x: 30,
				y: 43,
				seed: 81742722,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 4,
				type: 10,
				biome_type: 13,
				x: 30,
				y: 46,
				seed: 95942611,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 5,
				type: 0,
				biome_type: 13,
				x: 31,
				y: 38,
				seed: 69269096,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 6,
				type: 12,
				biome_type: 13,
				x: 31,
				y: 39,
				seed: 40407624,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 46,
				border_direction: -1
			},
			{
				room_id: 7,
				type: 21,
				biome_type: 46,
				x: 31,
				y: 40,
				seed: 68364121,
				flags: '0E000',
				master_room_x: 30,
				master_room_y: 40,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 13,
				border_direction: 1
			},
			{
				room_id: 8,
				type: 21,
				biome_type: 46,
				x: 31,
				y: 41,
				seed: 84881660,
				flags: '06000',
				master_room_x: 30,
				master_room_y: 40,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 9,
				type: 0,
				biome_type: 13,
				x: 31,
				y: 43,
				seed: 56790739,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 10,
				type: 0,
				biome_type: 13,
				x: 31,
				y: 44,
				seed: 2983319,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 11,
				type: 20,
				biome_type: 13,
				x: 31,
				y: 45,
				seed: 24410769,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 12,
				type: 0,
				biome_type: 13,
				x: 31,
				y: 46,
				seed: 77295141,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 13,
				type: 0,
				biome_type: 13,
				x: 32,
				y: 38,
				seed: 67454995,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 14,
				type: 26,
				biome_type: 13,
				x: 32,
				y: 44,
				seed: 63290004,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 15,
				type: 26,
				biome_type: 10,
				x: 32,
				y: 50,
				seed: 46502076,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 16,
				type: 0,
				biome_type: 10,
				x: 32,
				y: 51,
				seed: 17767909,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 17,
				type: 0,
				biome_type: 10,
				x: 32,
				y: 52,
				seed: 60058111,
				flags: '02000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 18,
				type: 47,
				biome_type: 13,
				x: 33,
				y: 37,
				seed: 26921823,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 13,
				border_direction: -1
			},
			{
				room_id: 19,
				type: 20,
				biome_type: 13,
				x: 33,
				y: 38,
				seed: 47017905,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 20,
				type: 0,
				biome_type: 13,
				x: 33,
				y: 44,
				seed: 80089,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 21,
				type: 0,
				biome_type: 10,
				x: 33,
				y: 51,
				seed: 62731174,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 22,
				type: 4,
				biome_type: 1,
				x: 33,
				y: 59,
				seed: 94595757,
				flags: '01000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000065'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 23,
				type: 69,
				biome_type: 1,
				x: 33,
				y: 63,
				seed: 64564708,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 4,
				border_direction: -1
			},
			{
				room_id: 24,
				type: 2,
				biome_type: 4,
				x: 33,
				y: 64,
				seed: 89846405,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 42,
				border_direction: -1
			},
			{
				room_id: 25,
				type: 0,
				biome_type: 3,
				x: 33,
				y: 65,
				seed: 19120455,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000'],
				difficulty: 0,
				border: 4,
				border_direction: -1
			},
			{
				room_id: 26,
				type: 0,
				biome_type: 13,
				x: 34,
				y: 38,
				seed: 86115089,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 27,
				type: 4,
				biome_type: 13,
				x: 34,
				y: 39,
				seed: 48402350,
				flags: '03000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000097'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 28,
				type: 0,
				biome_type: 13,
				x: 34,
				y: 42,
				seed: 33847644,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 29,
				type: 0,
				biome_type: 13,
				x: 34,
				y: 43,
				seed: 52928915,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 30,
				type: 10,
				biome_type: 13,
				x: 34,
				y: 44,
				seed: 47975447,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 31,
				type: 63,
				biome_type: 13,
				x: 34,
				y: 45,
				seed: 59970986,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 10,
				border_direction: 3
			},
			{
				room_id: 32,
				type: 65,
				biome_type: 10,
				x: 34,
				y: 46,
				seed: 77916084,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 13,
				border_direction: 1
			},
			{
				room_id: 33,
				type: 0,
				biome_type: 10,
				x: 34,
				y: 47,
				seed: 50035950,
				flags: '03000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 34,
				type: 1,
				biome_type: 10,
				x: 34,
				y: 51,
				seed: 4004538,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 35,
				type: 0,
				biome_type: 1,
				x: 34,
				y: 57,
				seed: 92310325,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 36,
				type: 0,
				biome_type: 1,
				x: 34,
				y: 58,
				seed: 34740014,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 37,
				type: 0,
				biome_type: 1,
				x: 34,
				y: 59,
				seed: 43503478,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 38,
				type: 0,
				biome_type: 3,
				x: 34,
				y: 65,
				seed: 35524747,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 39,
				type: 0,
				biome_type: 13,
				x: 35,
				y: 39,
				seed: 67382733,
				flags: '0C000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 40,
				type: 0,
				biome_type: 13,
				x: 35,
				y: 40,
				seed: 71168581,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 41,
				type: 0,
				biome_type: 13,
				x: 35,
				y: 41,
				seed: 86632753,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 42,
				type: 0,
				biome_type: 13,
				x: 35,
				y: 42,
				seed: 73919004,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 43,
				type: 0,
				biome_type: 10,
				x: 35,
				y: 47,
				seed: 34650425,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 44,
				type: 20,
				biome_type: 10,
				x: 35,
				y: 48,
				seed: 53559195,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 45,
				type: 0,
				biome_type: 10,
				x: 35,
				y: 49,
				seed: 39424274,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 46,
				type: 0,
				biome_type: 10,
				x: 35,
				y: 50,
				seed: 59802714,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0', '00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 47,
				type: 10,
				biome_type: 10,
				x: 35,
				y: 51,
				seed: 72363285,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 48,
				type: 20,
				biome_type: 10,
				x: 35,
				y: 52,
				seed: 21887020,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 49,
				type: 0,
				biome_type: 10,
				x: 35,
				y: 53,
				seed: 42115128,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 50,
				type: 0,
				biome_type: 10,
				x: 35,
				y: 54,
				seed: 83317036,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 51,
				type: 10,
				biome_type: 1,
				x: 35,
				y: 56,
				seed: 43099330,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 52,
				type: 0,
				biome_type: 1,
				x: 35,
				y: 57,
				seed: 35246479,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 53,
				type: 27,
				biome_type: 1,
				x: 35,
				y: 59,
				seed: 57461675,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 54,
				type: 21,
				biome_type: 44,
				x: 35,
				y: 62,
				seed: 42343976,
				flags: '09000',
				is_double: 1,
				master_room_x: 35,
				master_room_y: 62,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 55,
				type: 21,
				biome_type: 44,
				x: 35,
				y: 63,
				seed: 87522361,
				flags: '0B000',
				master_room_x: 35,
				master_room_y: 62,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 3,
				border_direction: 3
			},
			{
				room_id: 56,
				type: 0,
				biome_type: 3,
				x: 35,
				y: 64,
				seed: 89866483,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 0,
				border: 44,
				border_direction: -1
			},
			{
				room_id: 57,
				type: 0,
				biome_type: 3,
				x: 35,
				y: 65,
				seed: 8317766,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 58,
				type: 27,
				biome_type: 29,
				x: 36,
				y: 33,
				seed: 20026492,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 59,
				type: 0,
				biome_type: 29,
				x: 36,
				y: 35,
				seed: 28667764,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 60,
				type: 10,
				biome_type: 13,
				x: 36,
				y: 40,
				seed: 29109185,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 61,
				type: 0,
				biome_type: 10,
				x: 36,
				y: 48,
				seed: 40766263,
				flags: '05000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 62,
				type: 0,
				biome_type: 1,
				x: 36,
				y: 58,
				seed: 26984945,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 63,
				type: 0,
				biome_type: 1,
				x: 36,
				y: 59,
				seed: 7533959,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 64,
				type: 4,
				biome_type: 1,
				x: 36,
				y: 60,
				seed: 74742161,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000067'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 65,
				type: 0,
				biome_type: 1,
				x: 36,
				y: 61,
				seed: 20943899,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 0,
				border: 44,
				border_direction: -1
			},
			{
				room_id: 66,
				type: 21,
				biome_type: 44,
				x: 36,
				y: 62,
				seed: 11854546,
				flags: '0E000',
				master_room_x: 35,
				master_room_y: 62,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 1,
				border_direction: 1
			},
			{
				room_id: 67,
				type: 21,
				biome_type: 44,
				x: 36,
				y: 63,
				seed: 88356585,
				flags: '06000',
				master_room_x: 35,
				master_room_y: 62,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 68,
				type: 20,
				biome_type: 29,
				x: 37,
				y: 32,
				seed: 86677673,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 69,
				type: 10,
				biome_type: 29,
				x: 37,
				y: 33,
				seed: 39434987,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 70,
				type: 0,
				biome_type: 29,
				x: 37,
				y: 34,
				seed: 51718393,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 71,
				type: 0,
				biome_type: 29,
				x: 37,
				y: 35,
				seed: 76313624,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 72,
				type: 0,
				biome_type: 29,
				x: 37,
				y: 36,
				seed: 55019375,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 73,
				type: 4,
				biome_type: 13,
				x: 37,
				y: 40,
				seed: 96536703,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000095'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 74,
				type: 0,
				biome_type: 13,
				x: 37,
				y: 41,
				seed: 83768685,
				flags: '02000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 75,
				type: 4,
				biome_type: 10,
				x: 37,
				y: 48,
				seed: 46616559,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000008A'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 76,
				type: 0,
				biome_type: 23,
				x: 37,
				y: 51,
				seed: 18851797,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 77,
				type: 20,
				biome_type: 23,
				x: 37,
				y: 53,
				seed: 19092148,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 78,
				type: 0,
				biome_type: 1,
				x: 37,
				y: 58,
				seed: 99922776,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000', '00001FFFFFFFF00001'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 79,
				type: 70,
				biome_type: 29,
				x: 38,
				y: 32,
				seed: 90418863,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 80,
				type: 4,
				biome_type: 29,
				x: 38,
				y: 36,
				seed: 53970813,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00081FFFFFFFF00000000B2'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 81,
				type: 0,
				biome_type: 29,
				x: 38,
				y: 37,
				seed: 71645434,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 82,
				type: 10,
				biome_type: 29,
				x: 38,
				y: 38,
				seed: 46575341,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 83,
				type: 21,
				biome_type: 12,
				x: 38,
				y: 44,
				seed: 75578634,
				flags: '09000',
				is_double: 1,
				master_room_x: 38,
				master_room_y: 44,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 84,
				type: 21,
				biome_type: 12,
				x: 38,
				y: 45,
				seed: 80039609,
				flags: '0B000',
				master_room_x: 38,
				master_room_y: 44,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 10,
				border_direction: 3
			},
			{
				room_id: 85,
				type: 0,
				biome_type: 10,
				x: 38,
				y: 46,
				seed: 33732052,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 1,
				border: 12,
				border_direction: -1
			},
			{
				room_id: 86,
				type: 0,
				biome_type: 10,
				x: 38,
				y: 47,
				seed: 85549421,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00141FFFFFFFF0',
					'00141FFFFFFFF0',
					'00141FFFFFFFF0',
					'00141FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 87,
				type: 10,
				biome_type: 10,
				x: 38,
				y: 48,
				seed: 14114555,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 88,
				type: 0,
				biome_type: 23,
				x: 38,
				y: 50,
				seed: 22333242,
				flags: '08000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 89,
				type: 10,
				biome_type: 23,
				x: 38,
				y: 51,
				seed: 88140478,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 90,
				type: 0,
				biome_type: 23,
				x: 38,
				y: 52,
				seed: 7082231,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 91,
				type: 26,
				biome_type: 23,
				x: 38,
				y: 53,
				seed: 191745,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 92,
				type: 0,
				biome_type: 1,
				x: 38,
				y: 58,
				seed: 36952272,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 93,
				type: 0,
				biome_type: 1,
				x: 38,
				y: 60,
				seed: 63724757,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000', '00001FFFFFFFF00001'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 94,
				type: 0,
				biome_type: 29,
				x: 39,
				y: 32,
				seed: 50356037,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 95,
				type: 0,
				biome_type: 29,
				x: 39,
				y: 33,
				seed: 10836598,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 96,
				type: 0,
				biome_type: 29,
				x: 39,
				y: 36,
				seed: 11769161,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 97,
				type: 0,
				biome_type: 10,
				x: 39,
				y: 42,
				seed: 96395474,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 98,
				type: 0,
				biome_type: 10,
				x: 39,
				y: 43,
				seed: 11458818,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0'],
				difficulty: 2,
				border: 12,
				border_direction: -1
			},
			{
				room_id: 99,
				type: 21,
				biome_type: 12,
				x: 39,
				y: 44,
				seed: 50150941,
				flags: '0E000',
				master_room_x: 38,
				master_room_y: 44,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 10,
				border_direction: 1
			},
			{
				room_id: 100,
				type: 21,
				biome_type: 12,
				x: 39,
				y: 45,
				seed: 53227189,
				flags: '06000',
				master_room_x: 38,
				master_room_y: 44,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 101,
				type: 0,
				biome_type: 10,
				x: 39,
				y: 47,
				seed: 5971510,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0', '00141FFFFFFFF0'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 102,
				type: 0,
				biome_type: 23,
				x: 39,
				y: 53,
				seed: 44783728,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 103,
				type: 0,
				biome_type: 23,
				x: 39,
				y: 54,
				seed: 43219890,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 104,
				type: 0,
				biome_type: 23,
				x: 39,
				y: 55,
				seed: 82692196,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 105,
				type: 0,
				biome_type: 1,
				x: 39,
				y: 58,
				seed: 2433642,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 106,
				type: 0,
				biome_type: 1,
				x: 39,
				y: 59,
				seed: 33713734,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 107,
				type: 47,
				biome_type: 1,
				x: 39,
				y: 60,
				seed: 36920701,
				flags: '0F003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 108,
				type: 20,
				biome_type: 1,
				x: 39,
				y: 61,
				seed: 24763471,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 109,
				type: 0,
				biome_type: 1,
				x: 39,
				y: 62,
				seed: 53301765,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 110,
				type: 10,
				biome_type: 1,
				x: 39,
				y: 63,
				seed: 74109819,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 111,
				type: 0,
				biome_type: 1,
				x: 39,
				y: 64,
				seed: 34128649,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 112,
				type: 0,
				biome_type: 29,
				x: 40,
				y: 33,
				seed: 60666025,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 113,
				type: 0,
				biome_type: 29,
				x: 40,
				y: 34,
				seed: 89125949,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 114,
				type: 0,
				biome_type: 29,
				x: 40,
				y: 35,
				seed: 8126250,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 115,
				type: 0,
				biome_type: 29,
				x: 40,
				y: 36,
				seed: 17039097,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 116,
				type: 0,
				biome_type: 29,
				x: 40,
				y: 37,
				seed: 61100390,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 117,
				type: 0,
				biome_type: 29,
				x: 40,
				y: 38,
				seed: 24196708,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 118,
				type: 0,
				biome_type: 10,
				x: 40,
				y: 42,
				seed: 60606873,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 119,
				type: 12,
				biome_type: 10,
				x: 40,
				y: 47,
				seed: 73048542,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 120,
				type: 0,
				biome_type: 23,
				x: 40,
				y: 53,
				seed: 22190677,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 121,
				type: 10,
				biome_type: 23,
				x: 40,
				y: 55,
				seed: 28860925,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 122,
				type: 10,
				biome_type: 1,
				x: 40,
				y: 57,
				seed: 97729457,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 123,
				type: 4,
				biome_type: 1,
				x: 40,
				y: 58,
				seed: 87247085,
				flags: '06000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000068'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 124,
				type: 0,
				biome_type: 1,
				x: 40,
				y: 60,
				seed: 98094449,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000', '00001FFFFFFFF00000'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 125,
				type: 0,
				biome_type: 1,
				x: 40,
				y: 64,
				seed: 26215610,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 126,
				type: 0,
				biome_type: 29,
				x: 41,
				y: 33,
				seed: 72242859,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 127,
				type: 10,
				biome_type: 29,
				x: 41,
				y: 35,
				seed: 15800293,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 128,
				type: 0,
				biome_type: 29,
				x: 41,
				y: 38,
				seed: 59730570,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 129,
				type: 0,
				biome_type: 29,
				x: 41,
				y: 39,
				seed: 28671358,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 130,
				type: 63,
				biome_type: 29,
				x: 41,
				y: 40,
				seed: 68356892,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 10,
				border_direction: 3
			},
			{
				room_id: 131,
				type: 0,
				biome_type: 10,
				x: 41,
				y: 41,
				seed: 64370058,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0'],
				difficulty: 2,
				border: 29,
				border_direction: 1
			},
			{
				room_id: 132,
				type: 0,
				biome_type: 10,
				x: 41,
				y: 42,
				seed: 4342478,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 133,
				type: 0,
				biome_type: 10,
				x: 41,
				y: 47,
				seed: 91246494,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 134,
				type: 0,
				biome_type: 10,
				x: 41,
				y: 48,
				seed: 29559533,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 135,
				type: 10,
				biome_type: 10,
				x: 41,
				y: 49,
				seed: 60183832,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 23,
				border_direction: 3
			},
			{
				room_id: 136,
				type: 4,
				biome_type: 23,
				x: 41,
				y: 50,
				seed: 60605760,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000006E'],
				difficulty: 2,
				border: 10,
				border_direction: 1
			},
			{
				room_id: 137,
				type: 0,
				biome_type: 23,
				x: 41,
				y: 51,
				seed: 26705892,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 138,
				type: 1,
				biome_type: 23,
				x: 41,
				y: 52,
				seed: 33911745,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 23,
				border_direction: -1
			},
			{
				room_id: 139,
				type: 41,
				biome_type: 23,
				x: 41,
				y: 53,
				seed: 46994236,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 140,
				type: 41,
				biome_type: 23,
				x: 41,
				y: 55,
				seed: 7229171,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 141,
				type: 0,
				biome_type: 1,
				x: 41,
				y: 60,
				seed: 15045811,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 142,
				type: 22,
				biome_type: 45,
				x: 41,
				y: 62,
				seed: 69019003,
				flags: '09000',
				is_double: 1,
				master_room_x: 41,
				master_room_y: 62,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 143,
				type: 22,
				biome_type: 45,
				x: 41,
				y: 63,
				seed: 12958359,
				flags: '0B000',
				master_room_x: 41,
				master_room_y: 62,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 1,
				border_direction: 3
			},
			{
				room_id: 144,
				type: 4,
				biome_type: 1,
				x: 41,
				y: 64,
				seed: 68730831,
				flags: '06000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000190'],
				difficulty: 2,
				border: 45,
				border_direction: -1
			},
			{
				room_id: 145,
				type: 0,
				biome_type: 29,
				x: 42,
				y: 33,
				seed: 33672915,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 146,
				type: 26,
				biome_type: 29,
				x: 42,
				y: 34,
				seed: 58410460,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 147,
				type: 4,
				biome_type: 29,
				x: 42,
				y: 35,
				seed: 31661397,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00081FFFFFFFF00000000B3'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 148,
				type: 12,
				biome_type: 29,
				x: 42,
				y: 38,
				seed: 75567160,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 149,
				type: 0,
				biome_type: 10,
				x: 42,
				y: 44,
				seed: 62364952,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 150,
				type: 4,
				biome_type: 10,
				x: 42,
				y: 47,
				seed: 82373205,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000008D'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 151,
				type: 4,
				biome_type: 23,
				x: 42,
				y: 53,
				seed: 79981159,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000071'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 152,
				type: 0,
				biome_type: 23,
				x: 42,
				y: 55,
				seed: 61708542,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 153,
				type: 1,
				biome_type: 1,
				x: 42,
				y: 60,
				seed: 8064977,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 154,
				type: 26,
				biome_type: 1,
				x: 42,
				y: 61,
				seed: 82739212,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 45,
				border_direction: -1
			},
			{
				room_id: 155,
				type: 22,
				biome_type: 45,
				x: 42,
				y: 62,
				seed: 52005726,
				flags: '0E000',
				master_room_x: 41,
				master_room_y: 62,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 1,
				border_direction: 1
			},
			{
				room_id: 156,
				type: 22,
				biome_type: 45,
				x: 42,
				y: 63,
				seed: 93028054,
				flags: '06000',
				master_room_x: 41,
				master_room_y: 62,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 157,
				type: 0,
				biome_type: 29,
				x: 43,
				y: 33,
				seed: 62533482,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 158,
				type: 0,
				biome_type: 29,
				x: 43,
				y: 35,
				seed: 10470099,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 159,
				type: 0,
				biome_type: 29,
				x: 43,
				y: 36,
				seed: 18546549,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 160,
				type: 0,
				biome_type: 29,
				x: 43,
				y: 37,
				seed: 22992481,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 161,
				type: 0,
				biome_type: 29,
				x: 43,
				y: 38,
				seed: 88646822,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 162,
				type: 0,
				biome_type: 10,
				x: 43,
				y: 43,
				seed: 64197281,
				flags: '08000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 163,
				type: 4,
				biome_type: 10,
				x: 43,
				y: 44,
				seed: 71770602,
				flags: '0F000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000008E'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 164,
				type: 10,
				biome_type: 10,
				x: 43,
				y: 45,
				seed: 22961263,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 165,
				type: 0,
				biome_type: 10,
				x: 43,
				y: 46,
				seed: 97343377,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 166,
				type: 0,
				biome_type: 10,
				x: 43,
				y: 47,
				seed: 82131207,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 167,
				type: 0,
				biome_type: 23,
				x: 43,
				y: 53,
				seed: 64265412,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 168,
				type: 0,
				biome_type: 23,
				x: 43,
				y: 55,
				seed: 77071509,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 169,
				type: 0,
				biome_type: 23,
				x: 43,
				y: 56,
				seed: 44202159,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 170,
				type: 0,
				biome_type: 23,
				x: 43,
				y: 57,
				seed: 96040961,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 171,
				type: 10,
				biome_type: 23,
				x: 43,
				y: 58,
				seed: 43861067,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 1,
				border_direction: 3
			},
			{
				room_id: 172,
				type: 59,
				biome_type: 1,
				x: 43,
				y: 59,
				seed: 41051852,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 23,
				border_direction: 1
			},
			{
				room_id: 173,
				type: 0,
				biome_type: 1,
				x: 43,
				y: 60,
				seed: 44740506,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 174,
				type: 47,
				biome_type: 29,
				x: 44,
				y: 31,
				seed: 57135253,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 29,
				border_direction: -1
			},
			{
				room_id: 175,
				type: 20,
				biome_type: 29,
				x: 44,
				y: 32,
				seed: 53469024,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 176,
				type: 0,
				biome_type: 29,
				x: 44,
				y: 33,
				seed: 37571220,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 177,
				type: 0,
				biome_type: 29,
				x: 44,
				y: 36,
				seed: 56046884,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 178,
				type: 0,
				biome_type: 29,
				x: 44,
				y: 38,
				seed: 54365800,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 179,
				type: 0,
				biome_type: 29,
				x: 44,
				y: 39,
				seed: 16170857,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 180,
				type: 10,
				biome_type: 29,
				x: 44,
				y: 40,
				seed: 51545121,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 181,
				type: 0,
				biome_type: 10,
				x: 44,
				y: 44,
				seed: 79300322,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 182,
				type: 0,
				biome_type: 8,
				x: 44,
				y: 49,
				seed: 48088394,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00002'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 183,
				type: 10,
				biome_type: 23,
				x: 44,
				y: 53,
				seed: 74397369,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 184,
				type: 27,
				biome_type: 23,
				x: 44,
				y: 54,
				seed: 56287813,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 185,
				type: 0,
				biome_type: 23,
				x: 44,
				y: 55,
				seed: 79314544,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 186,
				type: 20,
				biome_type: 23,
				x: 44,
				y: 57,
				seed: 54537505,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 187,
				type: 0,
				biome_type: 29,
				x: 45,
				y: 33,
				seed: 33018553,
				flags: '0C000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 188,
				type: 0,
				biome_type: 29,
				x: 45,
				y: 34,
				seed: 58942658,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 189,
				type: 10,
				biome_type: 29,
				x: 45,
				y: 35,
				seed: 25966687,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 190,
				type: 0,
				biome_type: 29,
				x: 45,
				y: 36,
				seed: 84841992,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 191,
				type: 0,
				biome_type: 10,
				x: 45,
				y: 44,
				seed: 42890463,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 192,
				type: 10,
				biome_type: 8,
				x: 45,
				y: 49,
				seed: 70837236,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 193,
				type: 4,
				biome_type: 23,
				x: 45,
				y: 57,
				seed: 25057893,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000070'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 194,
				type: 0,
				biome_type: 5,
				x: 45,
				y: 62,
				seed: 29588098,
				flags: '01000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 195,
				type: 4,
				biome_type: 29,
				x: 46,
				y: 36,
				seed: 41121321,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00081FFFFFFFF00000000AC'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 196,
				type: 0,
				biome_type: 27,
				x: 46,
				y: 39,
				seed: 2674710,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 197,
				type: 0,
				biome_type: 10,
				x: 46,
				y: 42,
				seed: 73028573,
				flags: '08000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 198,
				type: 0,
				biome_type: 10,
				x: 46,
				y: 43,
				seed: 8465605,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 199,
				type: 0,
				biome_type: 10,
				x: 46,
				y: 44,
				seed: 50215498,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 200,
				type: 0,
				biome_type: 10,
				x: 46,
				y: 45,
				seed: 45190533,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 201,
				type: 10,
				biome_type: 10,
				x: 46,
				y: 46,
				seed: 64199365,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 202,
				type: 27,
				biome_type: 10,
				x: 46,
				y: 47,
				seed: 51699133,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 203,
				type: 0,
				biome_type: 8,
				x: 46,
				y: 49,
				seed: 13894226,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 204,
				type: 0,
				biome_type: 8,
				x: 46,
				y: 50,
				seed: 6218228,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 205,
				type: 0,
				biome_type: 8,
				x: 46,
				y: 51,
				seed: 91321798,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 206,
				type: 12,
				biome_type: 8,
				x: 46,
				y: 52,
				seed: 67191405,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 207,
				type: 0,
				biome_type: 8,
				x: 46,
				y: 53,
				seed: 21300566,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00002'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 208,
				type: 10,
				biome_type: 8,
				x: 46,
				y: 54,
				seed: 98775032,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 23,
				border_direction: 3
			},
			{
				room_id: 209,
				type: 41,
				biome_type: 23,
				x: 46,
				y: 55,
				seed: 56442223,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: 8,
				border_direction: 1
			},
			{
				room_id: 210,
				type: 0,
				biome_type: 23,
				x: 46,
				y: 56,
				seed: 75548229,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 211,
				type: 0,
				biome_type: 23,
				x: 46,
				y: 57,
				seed: 31739432,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 212,
				type: 10,
				biome_type: 5,
				x: 46,
				y: 61,
				seed: 6214526,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 213,
				type: 0,
				biome_type: 5,
				x: 46,
				y: 62,
				seed: 89081607,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 214,
				type: 0,
				biome_type: 29,
				x: 47,
				y: 36,
				seed: 31871678,
				flags: '04000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 215,
				type: 0,
				biome_type: 27,
				x: 47,
				y: 38,
				seed: 46411185,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 216,
				type: 10,
				biome_type: 27,
				x: 47,
				y: 39,
				seed: 91468409,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 217,
				type: 0,
				biome_type: 27,
				x: 47,
				y: 40,
				seed: 27789947,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 218,
				type: 0,
				biome_type: 8,
				x: 47,
				y: 49,
				seed: 12262762,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 219,
				type: 0,
				biome_type: 8,
				x: 47,
				y: 52,
				seed: 88927721,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 220,
				type: 0,
				biome_type: 5,
				x: 47,
				y: 61,
				seed: 34202522,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 221,
				type: 0,
				biome_type: 49,
				x: 48,
				y: 31,
				seed: 91006378,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 222,
				type: 0,
				biome_type: 49,
				x: 48,
				y: 32,
				seed: 12801209,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 223,
				type: 0,
				biome_type: 49,
				x: 48,
				y: 33,
				seed: 5333368,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 224,
				type: 0,
				biome_type: 49,
				x: 48,
				y: 34,
				seed: 80714074,
				flags: '02000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 225,
				type: 0,
				biome_type: 27,
				x: 48,
				y: 38,
				seed: 73566519,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 226,
				type: 0,
				biome_type: 27,
				x: 48,
				y: 40,
				seed: 46720666,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 227,
				type: 0,
				biome_type: 27,
				x: 48,
				y: 41,
				seed: 24177087,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 228,
				type: 0,
				biome_type: 27,
				x: 48,
				y: 42,
				seed: 32829364,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 229,
				type: 0,
				biome_type: 27,
				x: 48,
				y: 43,
				seed: 88018159,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 230,
				type: 10,
				biome_type: 27,
				x: 48,
				y: 44,
				seed: 13201629,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 231,
				type: 0,
				biome_type: 8,
				x: 48,
				y: 48,
				seed: 82986469,
				flags: '08000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 232,
				type: 0,
				biome_type: 8,
				x: 48,
				y: 49,
				seed: 12958599,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 233,
				type: 0,
				biome_type: 8,
				x: 48,
				y: 50,
				seed: 82587184,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 234,
				type: 4,
				biome_type: 8,
				x: 48,
				y: 52,
				seed: 77928400,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000078'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 235,
				type: 0,
				biome_type: 5,
				x: 48,
				y: 60,
				seed: 16545255,
				flags: '09000',
				objective_complete: 0,
				objects: ['00011FFFFFFFF0', '00011FFFFFFFF0', '00011FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 236,
				type: 4,
				biome_type: 5,
				x: 48,
				y: 61,
				seed: 44170148,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000007E'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 237,
				type: 0,
				biome_type: 5,
				x: 48,
				y: 62,
				seed: 58667423,
				flags: '02000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 238,
				type: 0,
				biome_type: 49,
				x: 49,
				y: 31,
				seed: 96955690,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 239,
				type: 10,
				biome_type: 49,
				x: 49,
				y: 33,
				seed: 31187715,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 240,
				type: 0,
				biome_type: 27,
				x: 49,
				y: 36,
				seed: 71443614,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 241,
				type: 0,
				biome_type: 27,
				x: 49,
				y: 37,
				seed: 21262664,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 242,
				type: 12,
				biome_type: 27,
				x: 49,
				y: 38,
				seed: 15032832,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 243,
				type: 0,
				biome_type: 27,
				x: 49,
				y: 41,
				seed: 6142648,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 244,
				type: 0,
				biome_type: 27,
				x: 49,
				y: 43,
				seed: 10505483,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 245,
				type: 0,
				biome_type: 8,
				x: 49,
				y: 50,
				seed: 37258109,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 246,
				type: 10,
				biome_type: 8,
				x: 49,
				y: 51,
				seed: 32200893,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 247,
				type: 0,
				biome_type: 8,
				x: 49,
				y: 52,
				seed: 31572939,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 248,
				type: 0,
				biome_type: 8,
				x: 49,
				y: 53,
				seed: 68665201,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00002'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 249,
				type: 0,
				biome_type: 5,
				x: 49,
				y: 60,
				seed: 17812780,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004', '00011FFFFFFFF0', '00011FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 250,
				type: 29,
				biome_type: 50,
				x: 50,
				y: 27,
				seed: 69153144,
				flags: '09000',
				is_double: 1,
				master_room_x: 50,
				master_room_y: 27,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 251,
				type: 29,
				biome_type: 50,
				x: 50,
				y: 28,
				seed: 52715816,
				flags: '0B000',
				master_room_x: 50,
				master_room_y: 27,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 49,
				border_direction: 3
			},
			{
				room_id: 252,
				type: 0,
				biome_type: 49,
				x: 50,
				y: 29,
				seed: 53274409,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: 50,
				border_direction: 1
			},
			{
				room_id: 253,
				type: 0,
				biome_type: 49,
				x: 50,
				y: 30,
				seed: 23075162,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 254,
				type: 0,
				biome_type: 49,
				x: 50,
				y: 31,
				seed: 53907281,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 255,
				type: 4,
				biome_type: 49,
				x: 50,
				y: 33,
				seed: 9909307,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00081FFFFFFFF00000000C0'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 256,
				type: 10,
				biome_type: 27,
				x: 50,
				y: 36,
				seed: 79591541,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 49,
				border_direction: 0
			},
			{
				room_id: 257,
				type: 0,
				biome_type: 27,
				x: 50,
				y: 38,
				seed: 16033479,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 258,
				type: 0,
				biome_type: 27,
				x: 50,
				y: 39,
				seed: 18585005,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 259,
				type: 10,
				biome_type: 27,
				x: 50,
				y: 40,
				seed: 74377823,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 260,
				type: 26,
				biome_type: 27,
				x: 50,
				y: 41,
				seed: 70471822,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 261,
				type: 0,
				biome_type: 27,
				x: 50,
				y: 43,
				seed: 17712095,
				flags: '04000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 262,
				type: 4,
				biome_type: 8,
				x: 50,
				y: 50,
				seed: 76887252,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000007A'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 263,
				type: 0,
				biome_type: 8,
				x: 50,
				y: 53,
				seed: 75552643,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 264,
				type: 0,
				biome_type: 5,
				x: 50,
				y: 58,
				seed: 41573649,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004', '00001FFFFFFFF00004'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 265,
				type: 0,
				biome_type: 5,
				x: 50,
				y: 59,
				seed: 6396511,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004', '00011FFFFFFFF0', '00011FFFFFFFF0'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 266,
				type: 0,
				biome_type: 5,
				x: 50,
				y: 60,
				seed: 80381168,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 267,
				type: 10,
				biome_type: 5,
				x: 50,
				y: 61,
				seed: 12820523,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 268,
				type: 29,
				biome_type: 50,
				x: 51,
				y: 27,
				seed: 62687772,
				flags: '0C000',
				master_room_x: 50,
				master_room_y: 27,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 269,
				type: 29,
				biome_type: 50,
				x: 51,
				y: 28,
				seed: 4591661,
				flags: '06000',
				master_room_x: 50,
				master_room_y: 27,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 270,
				type: 10,
				biome_type: 49,
				x: 51,
				y: 31,
				seed: 96706807,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 271,
				type: 0,
				biome_type: 49,
				x: 51,
				y: 33,
				seed: 85324019,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 272,
				type: 66,
				biome_type: 49,
				x: 51,
				y: 36,
				seed: 59637360,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 27,
				border_direction: 2
			},
			{
				room_id: 273,
				type: 0,
				biome_type: 27,
				x: 51,
				y: 40,
				seed: 2845441,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 274,
				type: 20,
				biome_type: 8,
				x: 51,
				y: 50,
				seed: 37499094,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 275,
				type: 0,
				biome_type: 8,
				x: 51,
				y: 51,
				seed: 48691970,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 276,
				type: 70,
				biome_type: 8,
				x: 51,
				y: 53,
				seed: 41091838,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: 5,
				border_direction: 3
			},
			{
				room_id: 277,
				type: 10,
				biome_type: 5,
				x: 51,
				y: 54,
				seed: 28027851,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 8,
				border_direction: 1
			},
			{
				room_id: 278,
				type: 0,
				biome_type: 5,
				x: 51,
				y: 55,
				seed: 73016348,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00004'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 279,
				type: 12,
				biome_type: 5,
				x: 51,
				y: 58,
				seed: 1357266,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 280,
				type: 0,
				biome_type: 5,
				x: 51,
				y: 61,
				seed: 3796361,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 281,
				type: 0,
				biome_type: 49,
				x: 52,
				y: 31,
				seed: 72898831,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 282,
				type: 0,
				biome_type: 49,
				x: 52,
				y: 32,
				seed: 90110139,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 283,
				type: 0,
				biome_type: 49,
				x: 52,
				y: 33,
				seed: 56169981,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 284,
				type: 0,
				biome_type: 49,
				x: 52,
				y: 34,
				seed: 66457242,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 285,
				type: 0,
				biome_type: 49,
				x: 52,
				y: 35,
				seed: 11264731,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 286,
				type: 10,
				biome_type: 49,
				x: 52,
				y: 36,
				seed: 40129787,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 287,
				type: 0,
				biome_type: 27,
				x: 52,
				y: 40,
				seed: 31804252,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 26,
				border_direction: -1
			},
			{
				room_id: 288,
				type: 0,
				biome_type: 25,
				x: 52,
				y: 44,
				seed: 17593388,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 289,
				type: 1,
				biome_type: 25,
				x: 52,
				y: 45,
				seed: 99824093,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 25,
				border_direction: -1
			},
			{
				room_id: 290,
				type: 10,
				biome_type: 25,
				x: 52,
				y: 46,
				seed: 15378576,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 8,
				border_direction: 3
			},
			{
				room_id: 291,
				type: 66,
				biome_type: 8,
				x: 52,
				y: 47,
				seed: 68453034,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 25,
				border_direction: 1
			},
			{
				room_id: 292,
				type: 0,
				biome_type: 8,
				x: 52,
				y: 48,
				seed: 92458013,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 293,
				type: 0,
				biome_type: 8,
				x: 52,
				y: 49,
				seed: 29610575,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 294,
				type: 4,
				biome_type: 8,
				x: 52,
				y: 50,
				seed: 33628581,
				flags: '07000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000079'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 295,
				type: 0,
				biome_type: 5,
				x: 52,
				y: 55,
				seed: 52747645,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 296,
				type: 0,
				biome_type: 5,
				x: 52,
				y: 56,
				seed: 543392,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00011FFFFFFFF0', '00011FFFFFFFF0'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 297,
				type: 0,
				biome_type: 5,
				x: 52,
				y: 57,
				seed: 93959653,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004', '00001FFFFFFFF00004'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 298,
				type: 4,
				biome_type: 5,
				x: 52,
				y: 58,
				seed: 68945043,
				flags: '0F000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000007C'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 299,
				type: 0,
				biome_type: 5,
				x: 52,
				y: 59,
				seed: 81024861,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 300,
				type: 0,
				biome_type: 5,
				x: 52,
				y: 60,
				seed: 78691178,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 301,
				type: 0,
				biome_type: 5,
				x: 52,
				y: 61,
				seed: 94693162,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 302,
				type: 10,
				biome_type: 5,
				x: 52,
				y: 63,
				seed: 71601081,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 303,
				type: 0,
				biome_type: 5,
				x: 52,
				y: 64,
				seed: 79569412,
				flags: '02000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 304,
				type: 0,
				biome_type: 49,
				x: 53,
				y: 32,
				seed: 43638281,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 305,
				type: 0,
				biome_type: 49,
				x: 53,
				y: 34,
				seed: 33164362,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 306,
				type: 0,
				biome_type: 49,
				x: 53,
				y: 36,
				seed: 1252378,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 307,
				type: 21,
				biome_type: 26,
				x: 53,
				y: 40,
				seed: 28141964,
				flags: '0D000',
				is_double: 1,
				master_room_x: 53,
				master_room_y: 40,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 27,
				border_direction: 2
			},
			{
				room_id: 308,
				type: 21,
				biome_type: 26,
				x: 53,
				y: 41,
				seed: 44615442,
				flags: '03000',
				master_room_x: 53,
				master_room_y: 40,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 309,
				type: 0,
				biome_type: 25,
				x: 53,
				y: 44,
				seed: 57298845,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 310,
				type: 0,
				biome_type: 8,
				x: 53,
				y: 48,
				seed: 4753556,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 311,
				type: 0,
				biome_type: 8,
				x: 53,
				y: 50,
				seed: 96808532,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 312,
				type: 0,
				biome_type: 5,
				x: 53,
				y: 55,
				seed: 59214354,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 313,
				type: 0,
				biome_type: 5,
				x: 53,
				y: 58,
				seed: 50973950,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 314,
				type: 26,
				biome_type: 5,
				x: 53,
				y: 61,
				seed: 29967922,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 315,
				type: 0,
				biome_type: 5,
				x: 53,
				y: 62,
				seed: 95345701,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004', '00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 316,
				type: 0,
				biome_type: 5,
				x: 53,
				y: 63,
				seed: 61048946,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 317,
				type: 0,
				biome_type: 49,
				x: 54,
				y: 30,
				seed: 3287729,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 318,
				type: 0,
				biome_type: 49,
				x: 54,
				y: 32,
				seed: 8722093,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 319,
				type: 0,
				biome_type: 49,
				x: 54,
				y: 34,
				seed: 33949098,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 320,
				type: 0,
				biome_type: 49,
				x: 54,
				y: 36,
				seed: 6647483,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 321,
				type: 21,
				biome_type: 26,
				x: 54,
				y: 40,
				seed: 25876356,
				flags: '0D000',
				master_room_x: 53,
				master_room_y: 40,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 25,
				border_direction: 0
			},
			{
				room_id: 322,
				type: 21,
				biome_type: 26,
				x: 54,
				y: 41,
				seed: 59534049,
				flags: '0E000',
				master_room_x: 53,
				master_room_y: 40,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 25,
				border_direction: 3
			},
			{
				room_id: 323,
				type: 0,
				biome_type: 25,
				x: 54,
				y: 42,
				seed: 61960990,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 1,
				border: 26,
				border_direction: -1
			},
			{
				room_id: 324,
				type: 0,
				biome_type: 25,
				x: 54,
				y: 43,
				seed: 54311671,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 325,
				type: 0,
				biome_type: 25,
				x: 54,
				y: 44,
				seed: 98729278,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 326,
				type: 4,
				biome_type: 8,
				x: 54,
				y: 48,
				seed: 1636192,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000077'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 327,
				type: 1,
				biome_type: 8,
				x: 54,
				y: 49,
				seed: 59991419,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 328,
				type: 10,
				biome_type: 8,
				x: 54,
				y: 50,
				seed: 71699626,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 329,
				type: 0,
				biome_type: 5,
				x: 54,
				y: 55,
				seed: 82120839,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 330,
				type: 0,
				biome_type: 5,
				x: 54,
				y: 58,
				seed: 42079755,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 331,
				type: 10,
				biome_type: 5,
				x: 54,
				y: 59,
				seed: 39598119,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 332,
				type: 0,
				biome_type: 5,
				x: 54,
				y: 60,
				seed: 54116780,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 333,
				type: 0,
				biome_type: 5,
				x: 54,
				y: 61,
				seed: 3740253,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 334,
				type: 0,
				biome_type: 49,
				x: 55,
				y: 30,
				seed: 75466576,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 335,
				type: 0,
				biome_type: 49,
				x: 55,
				y: 31,
				seed: 50166683,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 336,
				type: 0,
				biome_type: 49,
				x: 55,
				y: 32,
				seed: 11471482,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 337,
				type: 10,
				biome_type: 49,
				x: 55,
				y: 33,
				seed: 11062494,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 338,
				type: 4,
				biome_type: 49,
				x: 55,
				y: 34,
				seed: 10555622,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF00000000C3'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 339,
				type: 0,
				biome_type: 49,
				x: 55,
				y: 35,
				seed: 205464,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 340,
				type: 0,
				biome_type: 49,
				x: 55,
				y: 36,
				seed: 56530802,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 341,
				type: 0,
				biome_type: 49,
				x: 55,
				y: 37,
				seed: 2900179,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 342,
				type: 0,
				biome_type: 25,
				x: 55,
				y: 40,
				seed: 42000191,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 1,
				border: 26,
				border_direction: -1
			},
			{
				room_id: 343,
				type: 0,
				biome_type: 25,
				x: 55,
				y: 44,
				seed: 39858868,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 344,
				type: 20,
				biome_type: 8,
				x: 55,
				y: 46,
				seed: 2392803,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 345,
				type: 0,
				biome_type: 8,
				x: 55,
				y: 47,
				seed: 15246597,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 346,
				type: 0,
				biome_type: 8,
				x: 55,
				y: 48,
				seed: 24531225,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 347,
				type: 0,
				biome_type: 5,
				x: 55,
				y: 55,
				seed: 11512700,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 348,
				type: 0,
				biome_type: 5,
				x: 55,
				y: 58,
				seed: 40396594,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 349,
				type: 0,
				biome_type: 5,
				x: 55,
				y: 61,
				seed: 52358554,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 350,
				type: 47,
				biome_type: 49,
				x: 56,
				y: 28,
				seed: 62249453,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 49,
				border_direction: -1
			},
			{
				room_id: 351,
				type: 0,
				biome_type: 49,
				x: 56,
				y: 29,
				seed: 84027801,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 352,
				type: 20,
				biome_type: 49,
				x: 56,
				y: 31,
				seed: 13329205,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 353,
				type: 0,
				biome_type: 49,
				x: 56,
				y: 35,
				seed: 72387983,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 354,
				type: 0,
				biome_type: 49,
				x: 56,
				y: 37,
				seed: 50556698,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 355,
				type: 0,
				biome_type: 25,
				x: 56,
				y: 40,
				seed: 36425648,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 356,
				type: 10,
				biome_type: 25,
				x: 56,
				y: 44,
				seed: 83701200,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 357,
				type: 0,
				biome_type: 6,
				x: 56,
				y: 52,
				seed: 29167534,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00011FFFFFFFF0', '00011FFFFFFFF0'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 358,
				type: 0,
				biome_type: 6,
				x: 56,
				y: 53,
				seed: 3783740,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 0,
				border: 5,
				border_direction: -1
			},
			{
				room_id: 359,
				type: 0,
				biome_type: 5,
				x: 56,
				y: 54,
				seed: 19196948,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00004'],
				difficulty: 1,
				border: 6,
				border_direction: -1
			},
			{
				room_id: 360,
				type: 10,
				biome_type: 5,
				x: 56,
				y: 55,
				seed: 53835880,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 361,
				type: 20,
				biome_type: 5,
				x: 56,
				y: 56,
				seed: 27791326,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 362,
				type: 4,
				biome_type: 5,
				x: 56,
				y: 57,
				seed: 54514116,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000080'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 363,
				type: 0,
				biome_type: 5,
				x: 56,
				y: 58,
				seed: 22369807,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 364,
				type: 0,
				biome_type: 5,
				x: 56,
				y: 59,
				seed: 59252711,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 365,
				type: 1,
				biome_type: 5,
				x: 56,
				y: 61,
				seed: 46446074,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 366,
				type: 0,
				biome_type: 5,
				x: 56,
				y: 62,
				seed: 56942109,
				flags: '03000',
				objective_complete: 0,
				objects: ['00011FFFFFFFF0', '00011FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 367,
				type: 0,
				biome_type: 49,
				x: 57,
				y: 29,
				seed: 4082243,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 368,
				type: 0,
				biome_type: 49,
				x: 57,
				y: 30,
				seed: 17033189,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 369,
				type: 0,
				biome_type: 49,
				x: 57,
				y: 31,
				seed: 85870185,
				flags: '0F000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 370,
				type: 0,
				biome_type: 49,
				x: 57,
				y: 32,
				seed: 18843708,
				flags: '02000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 371,
				type: 0,
				biome_type: 49,
				x: 57,
				y: 35,
				seed: 76114836,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 372,
				type: 0,
				biome_type: 49,
				x: 57,
				y: 36,
				seed: 55383830,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 373,
				type: 10,
				biome_type: 49,
				x: 57,
				y: 37,
				seed: 9887757,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 374,
				type: 10,
				biome_type: 25,
				x: 57,
				y: 39,
				seed: 1304276,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 375,
				type: 0,
				biome_type: 25,
				x: 57,
				y: 40,
				seed: 28192890,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 376,
				type: 0,
				biome_type: 25,
				x: 57,
				y: 41,
				seed: 46387052,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 377,
				type: 0,
				biome_type: 25,
				x: 57,
				y: 42,
				seed: 18954640,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 378,
				type: 20,
				biome_type: 25,
				x: 57,
				y: 43,
				seed: 3567659,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 379,
				type: 0,
				biome_type: 25,
				x: 57,
				y: 44,
				seed: 17349858,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 380,
				type: 4,
				biome_type: 25,
				x: 57,
				y: 45,
				seed: 81401134,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF00000000A0'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 381,
				type: 0,
				biome_type: 25,
				x: 57,
				y: 46,
				seed: 13606329,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 382,
				type: 0,
				biome_type: 25,
				x: 57,
				y: 47,
				seed: 76965234,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 383,
				type: 0,
				biome_type: 6,
				x: 57,
				y: 50,
				seed: 31158144,
				flags: '08000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 384,
				type: 0,
				biome_type: 6,
				x: 57,
				y: 51,
				seed: 53267948,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 385,
				type: 0,
				biome_type: 6,
				x: 57,
				y: 52,
				seed: 11036250,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 386,
				type: 0,
				biome_type: 5,
				x: 57,
				y: 59,
				seed: 99310248,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 387,
				type: 10,
				biome_type: 5,
				x: 57,
				y: 62,
				seed: 32920653,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 388,
				type: 0,
				biome_type: 49,
				x: 58,
				y: 31,
				seed: 92746334,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 389,
				type: 20,
				biome_type: 49,
				x: 58,
				y: 35,
				seed: 12233804,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 390,
				type: 0,
				biome_type: 25,
				x: 58,
				y: 39,
				seed: 60203253,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 391,
				type: 0,
				biome_type: 25,
				x: 58,
				y: 42,
				seed: 27052675,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 392,
				type: 0,
				biome_type: 25,
				x: 58,
				y: 45,
				seed: 65186781,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 393,
				type: 0,
				biome_type: 25,
				x: 58,
				y: 47,
				seed: 14109311,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 394,
				type: 0,
				biome_type: 6,
				x: 58,
				y: 52,
				seed: 68766234,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 395,
				type: 10,
				biome_type: 6,
				x: 58,
				y: 53,
				seed: 6005628,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 396,
				type: 21,
				biome_type: 7,
				x: 58,
				y: 57,
				seed: 54205244,
				flags: '09000',
				is_double: 1,
				master_room_x: 58,
				master_room_y: 57,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 397,
				type: 21,
				biome_type: 7,
				x: 58,
				y: 58,
				seed: 41754443,
				flags: '0B000',
				master_room_x: 58,
				master_room_y: 57,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 5,
				border_direction: 3
			},
			{
				room_id: 398,
				type: 0,
				biome_type: 5,
				x: 58,
				y: 59,
				seed: 95338838,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 2,
				border: 7,
				border_direction: -1
			},
			{
				room_id: 399,
				type: 0,
				biome_type: 5,
				x: 58,
				y: 62,
				seed: 52142766,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 400,
				type: 10,
				biome_type: 49,
				x: 59,
				y: 31,
				seed: 38508389,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 401,
				type: 4,
				biome_type: 49,
				x: 59,
				y: 32,
				seed: 5897235,
				flags: '02000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00081FFFFFFFF00000000C2'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 402,
				type: 0,
				biome_type: 49,
				x: 59,
				y: 34,
				seed: 71234057,
				flags: '08000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 403,
				type: 0,
				biome_type: 49,
				x: 59,
				y: 35,
				seed: 55120832,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 404,
				type: 0,
				biome_type: 25,
				x: 59,
				y: 39,
				seed: 47071081,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 405,
				type: 0,
				biome_type: 25,
				x: 59,
				y: 40,
				seed: 4034190,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 406,
				type: 0,
				biome_type: 25,
				x: 59,
				y: 41,
				seed: 53464062,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 407,
				type: 0,
				biome_type: 25,
				x: 59,
				y: 42,
				seed: 83495213,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 408,
				type: 10,
				biome_type: 25,
				x: 59,
				y: 43,
				seed: 82148443,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 409,
				type: 27,
				biome_type: 25,
				x: 59,
				y: 44,
				seed: 18130312,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 410,
				type: 0,
				biome_type: 25,
				x: 59,
				y: 45,
				seed: 59290393,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 411,
				type: 0,
				biome_type: 25,
				x: 59,
				y: 47,
				seed: 50700962,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 412,
				type: 0,
				biome_type: 6,
				x: 59,
				y: 53,
				seed: 3017102,
				flags: '05000',
				objective_complete: 0,
				objects: ['00011FFFFFFFF0', '00011FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 413,
				type: 21,
				biome_type: 7,
				x: 59,
				y: 57,
				seed: 6482358,
				flags: '0C000',
				master_room_x: 58,
				master_room_y: 57,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 414,
				type: 21,
				biome_type: 7,
				x: 59,
				y: 58,
				seed: 13745135,
				flags: '07000',
				master_room_x: 58,
				master_room_y: 57,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 47,
				border_direction: 0
			},
			{
				room_id: 415,
				type: 0,
				biome_type: 5,
				x: 59,
				y: 62,
				seed: 52991750,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 416,
				type: 0,
				biome_type: 49,
				x: 60,
				y: 31,
				seed: 93958247,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 417,
				type: 26,
				biome_type: 49,
				x: 60,
				y: 35,
				seed: 30857428,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 418,
				type: 20,
				biome_type: 25,
				x: 60,
				y: 39,
				seed: 40880907,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 419,
				type: 0,
				biome_type: 25,
				x: 60,
				y: 45,
				seed: 74416556,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 420,
				type: 0,
				biome_type: 25,
				x: 60,
				y: 46,
				seed: 4320704,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 421,
				type: 0,
				biome_type: 25,
				x: 60,
				y: 47,
				seed: 57911857,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 422,
				type: 47,
				biome_type: 6,
				x: 60,
				y: 50,
				seed: 68001495,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 6,
				border_direction: -1
			},
			{
				room_id: 423,
				type: 0,
				biome_type: 6,
				x: 60,
				y: 51,
				seed: 70571586,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00004'],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 424,
				type: 0,
				biome_type: 6,
				x: 60,
				y: 52,
				seed: 74530507,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00011FFFFFFFF0', '00011FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 425,
				type: 0,
				biome_type: 6,
				x: 60,
				y: 53,
				seed: 78244593,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 426,
				type: 62,
				biome_type: 47,
				x: 60,
				y: 58,
				seed: 29591255,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 7,
				border_direction: -1
			},
			{
				room_id: 427,
				type: 0,
				biome_type: 5,
				x: 60,
				y: 62,
				seed: 20288059,
				flags: '04000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 428,
				type: 0,
				biome_type: 49,
				x: 61,
				y: 30,
				seed: 18157839,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 429,
				type: 0,
				biome_type: 49,
				x: 61,
				y: 31,
				seed: 91238863,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 430,
				type: 10,
				biome_type: 25,
				x: 61,
				y: 37,
				seed: 43967800,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 431,
				type: 0,
				biome_type: 25,
				x: 61,
				y: 38,
				seed: 89394217,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 432,
				type: 0,
				biome_type: 25,
				x: 61,
				y: 39,
				seed: 29366995,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 433,
				type: 0,
				biome_type: 25,
				x: 61,
				y: 46,
				seed: 42149234,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 434,
				type: 0,
				biome_type: 6,
				x: 61,
				y: 53,
				seed: 34888815,
				flags: '04000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 435,
				type: 10,
				biome_type: 47,
				x: 61,
				y: 58,
				seed: 92489510,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 436,
				type: 0,
				biome_type: 49,
				x: 62,
				y: 29,
				seed: 20942367,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 437,
				type: 0,
				biome_type: 49,
				x: 62,
				y: 30,
				seed: 41806767,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 438,
				type: 0,
				biome_type: 25,
				x: 62,
				y: 37,
				seed: 67825426,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 439,
				type: 0,
				biome_type: 25,
				x: 62,
				y: 39,
				seed: 8949973,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 440,
				type: 21,
				biome_type: 16,
				x: 62,
				y: 43,
				seed: 58500268,
				flags: '09000',
				is_double: 1,
				master_room_x: 62,
				master_room_y: 43,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 441,
				type: 21,
				biome_type: 16,
				x: 62,
				y: 44,
				seed: 35059858,
				flags: '03000',
				master_room_x: 62,
				master_room_y: 43,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 442,
				type: 10,
				biome_type: 25,
				x: 62,
				y: 46,
				seed: 84203082,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 443,
				type: 0,
				biome_type: 47,
				x: 62,
				y: 58,
				seed: 25622877,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 444,
				type: 0,
				biome_type: 47,
				x: 62,
				y: 59,
				seed: 95291197,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 445,
				type: 0,
				biome_type: 47,
				x: 62,
				y: 60,
				seed: 71783561,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 446,
				type: 0,
				biome_type: 47,
				x: 62,
				y: 61,
				seed: 20069893,
				flags: '02000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 447,
				type: 10,
				biome_type: 47,
				x: 62,
				y: 63,
				seed: 92404139,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 448,
				type: 0,
				biome_type: 47,
				x: 62,
				y: 64,
				seed: 61950769,
				flags: '02000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 449,
				type: 10,
				biome_type: 49,
				x: 63,
				y: 29,
				seed: 99126139,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 450,
				type: 47,
				biome_type: 25,
				x: 63,
				y: 35,
				seed: 62357949,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 25,
				border_direction: -1
			},
			{
				room_id: 451,
				type: 0,
				biome_type: 25,
				x: 63,
				y: 36,
				seed: 81058265,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 452,
				type: 0,
				biome_type: 25,
				x: 63,
				y: 37,
				seed: 63721392,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 453,
				type: 0,
				biome_type: 25,
				x: 63,
				y: 39,
				seed: 53811306,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 454,
				type: 21,
				biome_type: 16,
				x: 63,
				y: 43,
				seed: 72764950,
				flags: '0C000',
				master_room_x: 62,
				master_room_y: 43,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 455,
				type: 21,
				biome_type: 16,
				x: 63,
				y: 44,
				seed: 26072386,
				flags: '0E000',
				master_room_x: 62,
				master_room_y: 43,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 25,
				border_direction: 3
			},
			{
				room_id: 456,
				type: 0,
				biome_type: 25,
				x: 63,
				y: 45,
				seed: 22634447,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: 16,
				border_direction: 1
			},
			{
				room_id: 457,
				type: 0,
				biome_type: 25,
				x: 63,
				y: 46,
				seed: 60334930,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 458,
				type: 0,
				biome_type: 25,
				x: 63,
				y: 47,
				seed: 56139667,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 459,
				type: 0,
				biome_type: 25,
				x: 63,
				y: 48,
				seed: 37015877,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 460,
				type: 4,
				biome_type: 25,
				x: 63,
				y: 49,
				seed: 38937963,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF00000000A1'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 461,
				type: 0,
				biome_type: 25,
				x: 63,
				y: 50,
				seed: 93490313,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 462,
				type: 10,
				biome_type: 25,
				x: 63,
				y: 51,
				seed: 67049775,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 463,
				type: 0,
				biome_type: 47,
				x: 63,
				y: 57,
				seed: 13797497,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 464,
				type: 0,
				biome_type: 47,
				x: 63,
				y: 58,
				seed: 66217169,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 465,
				type: 0,
				biome_type: 47,
				x: 63,
				y: 60,
				seed: 55357368,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 466,
				type: 0,
				biome_type: 47,
				x: 63,
				y: 63,
				seed: 85073397,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 467,
				type: 0,
				biome_type: 25,
				x: 64,
				y: 39,
				seed: 2003439,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 468,
				type: 0,
				biome_type: 25,
				x: 64,
				y: 48,
				seed: 47429989,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 469,
				type: 10,
				biome_type: 47,
				x: 64,
				y: 55,
				seed: 47739067,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 470,
				type: 0,
				biome_type: 47,
				x: 64,
				y: 56,
				seed: 4519544,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 471,
				type: 20,
				biome_type: 47,
				x: 64,
				y: 57,
				seed: 11211845,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 472,
				type: 4,
				biome_type: 47,
				x: 64,
				y: 60,
				seed: 71598810,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00081FFFFFFFF00000000B6'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 473,
				type: 0,
				biome_type: 47,
				x: 64,
				y: 63,
				seed: 7436160,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 474,
				type: 0,
				biome_type: 25,
				x: 65,
				y: 38,
				seed: 38845614,
				flags: '08000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 475,
				type: 10,
				biome_type: 25,
				x: 65,
				y: 39,
				seed: 84026896,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 476,
				type: 0,
				biome_type: 25,
				x: 65,
				y: 40,
				seed: 85605731,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 477,
				type: 20,
				biome_type: 25,
				x: 65,
				y: 48,
				seed: 34080182,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 478,
				type: 0,
				biome_type: 47,
				x: 65,
				y: 51,
				seed: 96688962,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 479,
				type: 0,
				biome_type: 47,
				x: 65,
				y: 55,
				seed: 14613389,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 480,
				type: 10,
				biome_type: 47,
				x: 65,
				y: 60,
				seed: 56055064,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 481,
				type: 0,
				biome_type: 47,
				x: 65,
				y: 61,
				seed: 50190752,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 482,
				type: 0,
				biome_type: 47,
				x: 65,
				y: 62,
				seed: 35961735,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 483,
				type: 0,
				biome_type: 47,
				x: 65,
				y: 63,
				seed: 62824048,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 484,
				type: 0,
				biome_type: 47,
				x: 65,
				y: 64,
				seed: 62933745,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 485,
				type: 0,
				biome_type: 25,
				x: 66,
				y: 40,
				seed: 70279764,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 486,
				type: 10,
				biome_type: 47,
				x: 66,
				y: 51,
				seed: 91415032,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 487,
				type: 0,
				biome_type: 47,
				x: 66,
				y: 52,
				seed: 19599419,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 488,
				type: 0,
				biome_type: 47,
				x: 66,
				y: 53,
				seed: 75424015,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 489,
				type: 0,
				biome_type: 47,
				x: 66,
				y: 54,
				seed: 27799132,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 490,
				type: 0,
				biome_type: 47,
				x: 66,
				y: 55,
				seed: 41415276,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 491,
				type: 21,
				biome_type: 48,
				x: 66,
				y: 57,
				seed: 28037323,
				flags: '09000',
				is_double: 1,
				master_room_x: 66,
				master_room_y: 57,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 492,
				type: 21,
				biome_type: 48,
				x: 66,
				y: 58,
				seed: 65794869,
				flags: '0B000',
				master_room_x: 66,
				master_room_y: 57,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 47,
				border_direction: 3
			},
			{
				room_id: 493,
				type: 0,
				biome_type: 47,
				x: 66,
				y: 59,
				seed: 56436384,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 1,
				border: 48,
				border_direction: -1
			},
			{
				room_id: 494,
				type: 0,
				biome_type: 47,
				x: 66,
				y: 60,
				seed: 13777400,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 495,
				type: 12,
				biome_type: 47,
				x: 66,
				y: 64,
				seed: 26316055,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 496,
				type: 0,
				biome_type: 47,
				x: 67,
				y: 52,
				seed: 67872476,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 497,
				type: 0,
				biome_type: 47,
				x: 67,
				y: 54,
				seed: 91432407,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 498,
				type: 21,
				biome_type: 48,
				x: 67,
				y: 57,
				seed: 56086422,
				flags: '0D000',
				master_room_x: 66,
				master_room_y: 57,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 47,
				border_direction: 0
			},
			{
				room_id: 499,
				type: 21,
				biome_type: 48,
				x: 67,
				y: 58,
				seed: 992243,
				flags: '06000',
				master_room_x: 66,
				master_room_y: 57,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 500,
				type: 0,
				biome_type: 47,
				x: 68,
				y: 54,
				seed: 65796254,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 501,
				type: 0,
				biome_type: 47,
				x: 68,
				y: 57,
				seed: 55848423,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: 48,
				border_direction: -1
			},
			{
				room_id: 502,
				type: 0,
				biome_type: 47,
				x: 69,
				y: 52,
				seed: 39686912,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 503,
				type: 0,
				biome_type: 47,
				x: 69,
				y: 53,
				seed: 51796338,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 504,
				type: 10,
				biome_type: 47,
				x: 69,
				y: 54,
				seed: 90793934,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 505,
				type: 20,
				biome_type: 47,
				x: 69,
				y: 57,
				seed: 96006369,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 506,
				type: 47,
				biome_type: 47,
				x: 70,
				y: 51,
				seed: 3465234,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 47,
				border_direction: -1
			},
			{
				room_id: 507,
				type: 4,
				biome_type: 47,
				x: 70,
				y: 52,
				seed: 10690016,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00081FFFFFFFF00000000B7'],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 508,
				type: 0,
				biome_type: 47,
				x: 70,
				y: 54,
				seed: 1698614,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 509,
				type: 26,
				biome_type: 47,
				x: 70,
				y: 55,
				seed: 77041820,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 510,
				type: 0,
				biome_type: 47,
				x: 70,
				y: 56,
				seed: 2407552,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 511,
				type: 0,
				biome_type: 47,
				x: 70,
				y: 57,
				seed: 16686332,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 512,
				type: 4,
				biome_type: 47,
				x: 70,
				y: 58,
				seed: 27822277,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00081FFFFFFFF00000000B9'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 513,
				type: 12,
				biome_type: 47,
				x: 71,
				y: 53,
				seed: 96250258,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 514,
				type: 0,
				biome_type: 47,
				x: 71,
				y: 54,
				seed: 58712843,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 515,
				type: 0,
				biome_type: 47,
				x: 71,
				y: 56,
				seed: 19576193,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 516,
				type: 10,
				biome_type: 47,
				x: 71,
				y: 58,
				seed: 15771029,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 517,
				type: 0,
				biome_type: 47,
				x: 71,
				y: 59,
				seed: 3412183,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 518,
				type: 0,
				biome_type: 47,
				x: 71,
				y: 60,
				seed: 24370348,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 519,
				type: 0,
				biome_type: 47,
				x: 72,
				y: 54,
				seed: 90605613,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 520,
				type: 0,
				biome_type: 47,
				x: 72,
				y: 56,
				seed: 83436359,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 521,
				type: 0,
				biome_type: 47,
				x: 72,
				y: 60,
				seed: 53253482,
				flags: '04000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 522,
				type: 0,
				biome_type: 47,
				x: 73,
				y: 51,
				seed: 6440664,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 523,
				type: 0,
				biome_type: 47,
				x: 73,
				y: 52,
				seed: 38638516,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 524,
				type: 0,
				biome_type: 47,
				x: 73,
				y: 53,
				seed: 80027658,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 525,
				type: 0,
				biome_type: 47,
				x: 73,
				y: 54,
				seed: 10791631,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 526,
				type: 0,
				biome_type: 47,
				x: 73,
				y: 55,
				seed: 67513005,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 527,
				type: 10,
				biome_type: 47,
				x: 73,
				y: 56,
				seed: 31380809,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			}
		]
	},
	{
		name: 'Worldgen 3',
		sprite: 's_mapgen_test_3',
		spawn_x: 40,
		spawn_y: 66,
		rooms: [
			{
				room_id: 0,
				type: 0,
				biome_type: 29,
				x: 23,
				y: 51,
				seed: 61980612,
				flags: '01000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 1,
				type: 0,
				biome_type: 29,
				x: 23,
				y: 53,
				seed: 18843790,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 2,
				type: 4,
				biome_type: 29,
				x: 23,
				y: 54,
				seed: 49821861,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF00000000AC'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 3,
				type: 0,
				biome_type: 29,
				x: 23,
				y: 55,
				seed: 79604756,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 4,
				type: 10,
				biome_type: 29,
				x: 23,
				y: 56,
				seed: 52019417,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 5,
				type: 0,
				biome_type: 29,
				x: 23,
				y: 57,
				seed: 3380467,
				flags: '02000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 6,
				type: 0,
				biome_type: 29,
				x: 24,
				y: 50,
				seed: 4395442,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 7,
				type: 10,
				biome_type: 29,
				x: 24,
				y: 51,
				seed: 18222393,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 8,
				type: 0,
				biome_type: 29,
				x: 24,
				y: 52,
				seed: 49397173,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 9,
				type: 0,
				biome_type: 29,
				x: 24,
				y: 53,
				seed: 83537322,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 10,
				type: 0,
				biome_type: 29,
				x: 24,
				y: 55,
				seed: 81458959,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 11,
				type: 0,
				biome_type: 29,
				x: 25,
				y: 50,
				seed: 62223500,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 12,
				type: 0,
				biome_type: 29,
				x: 25,
				y: 53,
				seed: 55709856,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 13,
				type: 20,
				biome_type: 29,
				x: 25,
				y: 54,
				seed: 82347232,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 14,
				type: 0,
				biome_type: 29,
				x: 25,
				y: 55,
				seed: 72640990,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 15,
				type: 0,
				biome_type: 29,
				x: 26,
				y: 50,
				seed: 2426804,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 16,
				type: 0,
				biome_type: 29,
				x: 26,
				y: 55,
				seed: 76896448,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 17,
				type: 0,
				biome_type: 29,
				x: 27,
				y: 48,
				seed: 95605740,
				flags: '01000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 18,
				type: 12,
				biome_type: 29,
				x: 27,
				y: 50,
				seed: 87646130,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 19,
				type: 47,
				biome_type: 29,
				x: 27,
				y: 53,
				seed: 26408461,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 29,
				border_direction: -1
			},
			{
				room_id: 20,
				type: 0,
				biome_type: 29,
				x: 27,
				y: 54,
				seed: 87223724,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 21,
				type: 4,
				biome_type: 29,
				x: 27,
				y: 55,
				seed: 88981172,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00081FFFFFFFF00000000B3'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 22,
				type: 0,
				biome_type: 29,
				x: 27,
				y: 56,
				seed: 72901322,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 23,
				type: 0,
				biome_type: 29,
				x: 27,
				y: 58,
				seed: 60300226,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 24,
				type: 21,
				biome_type: 46,
				x: 28,
				y: 35,
				seed: 50081497,
				flags: '09000',
				is_double: 1,
				master_room_x: 28,
				master_room_y: 35,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 25,
				type: 21,
				biome_type: 46,
				x: 28,
				y: 36,
				seed: 6279689,
				flags: '0B000',
				master_room_x: 28,
				master_room_y: 35,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 13,
				border_direction: 3
			},
			{
				room_id: 26,
				type: 0,
				biome_type: 13,
				x: 28,
				y: 37,
				seed: 44443832,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 1,
				border: 46,
				border_direction: -1
			},
			{
				room_id: 27,
				type: 4,
				biome_type: 13,
				x: 28,
				y: 38,
				seed: 89949001,
				flags: '03000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000095'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 28,
				type: 0,
				biome_type: 29,
				x: 28,
				y: 43,
				seed: 76828262,
				flags: '01000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 29,
				type: 70,
				biome_type: 29,
				x: 28,
				y: 45,
				seed: 76215002,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 30,
				type: 0,
				biome_type: 29,
				x: 28,
				y: 46,
				seed: 61671345,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 31,
				type: 0,
				biome_type: 29,
				x: 28,
				y: 47,
				seed: 10980957,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 32,
				type: 0,
				biome_type: 29,
				x: 28,
				y: 48,
				seed: 62803523,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 33,
				type: 0,
				biome_type: 29,
				x: 28,
				y: 49,
				seed: 80848187,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 34,
				type: 0,
				biome_type: 29,
				x: 28,
				y: 50,
				seed: 62678810,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 35,
				type: 10,
				biome_type: 29,
				x: 28,
				y: 51,
				seed: 69788695,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 36,
				type: 0,
				biome_type: 29,
				x: 28,
				y: 56,
				seed: 73031882,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 37,
				type: 0,
				biome_type: 29,
				x: 28,
				y: 57,
				seed: 32134819,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 38,
				type: 0,
				biome_type: 29,
				x: 28,
				y: 58,
				seed: 91788648,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 39,
				type: 4,
				biome_type: 13,
				x: 29,
				y: 33,
				seed: 80029226,
				flags: '09000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000097'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 40,
				type: 20,
				biome_type: 13,
				x: 29,
				y: 34,
				seed: 83318023,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 46,
				border_direction: -1
			},
			{
				room_id: 41,
				type: 21,
				biome_type: 46,
				x: 29,
				y: 35,
				seed: 85083390,
				flags: '0E000',
				master_room_x: 28,
				master_room_y: 35,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 13,
				border_direction: 1
			},
			{
				room_id: 42,
				type: 21,
				biome_type: 46,
				x: 29,
				y: 36,
				seed: 50835869,
				flags: '06000',
				master_room_x: 28,
				master_room_y: 35,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 43,
				type: 0,
				biome_type: 13,
				x: 29,
				y: 38,
				seed: 89325003,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 44,
				type: 0,
				biome_type: 13,
				x: 29,
				y: 39,
				seed: 61377583,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 45,
				type: 0,
				biome_type: 29,
				x: 29,
				y: 43,
				seed: 85244114,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 46,
				type: 10,
				biome_type: 29,
				x: 29,
				y: 44,
				seed: 93795266,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 47,
				type: 0,
				biome_type: 29,
				x: 29,
				y: 45,
				seed: 3686250,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 48,
				type: 26,
				biome_type: 29,
				x: 29,
				y: 47,
				seed: 90813994,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 49,
				type: 0,
				biome_type: 29,
				x: 29,
				y: 51,
				seed: 90233484,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 50,
				type: 0,
				biome_type: 29,
				x: 29,
				y: 56,
				seed: 20111850,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 51,
				type: 10,
				biome_type: 29,
				x: 29,
				y: 58,
				seed: 80462171,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 52,
				type: 47,
				biome_type: 13,
				x: 30,
				y: 32,
				seed: 42303283,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 13,
				border_direction: -1
			},
			{
				room_id: 53,
				type: 0,
				biome_type: 13,
				x: 30,
				y: 33,
				seed: 86933116,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 54,
				type: 0,
				biome_type: 13,
				x: 30,
				y: 39,
				seed: 54186870,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 55,
				type: 0,
				biome_type: 29,
				x: 30,
				y: 47,
				seed: 44685456,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 56,
				type: 63,
				biome_type: 29,
				x: 30,
				y: 48,
				seed: 70967190,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 10,
				border_direction: 3
			},
			{
				room_id: 57,
				type: 0,
				biome_type: 10,
				x: 30,
				y: 49,
				seed: 98279582,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 2,
				border: 29,
				border_direction: 1
			},
			{
				room_id: 58,
				type: 0,
				biome_type: 29,
				x: 30,
				y: 51,
				seed: 73623321,
				flags: '0C000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 59,
				type: 0,
				biome_type: 29,
				x: 30,
				y: 52,
				seed: 24316919,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 60,
				type: 0,
				biome_type: 29,
				x: 30,
				y: 53,
				seed: 65156209,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 61,
				type: 4,
				biome_type: 29,
				x: 30,
				y: 54,
				seed: 52531178,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00081FFFFFFFF00000000B2'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 62,
				type: 10,
				biome_type: 29,
				x: 30,
				y: 55,
				seed: 5344774,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 63,
				type: 0,
				biome_type: 29,
				x: 30,
				y: 56,
				seed: 44351894,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 64,
				type: 20,
				biome_type: 29,
				x: 30,
				y: 58,
				seed: 35380119,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 65,
				type: 0,
				biome_type: 13,
				x: 31,
				y: 33,
				seed: 18040830,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 66,
				type: 0,
				biome_type: 13,
				x: 31,
				y: 34,
				seed: 93995847,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 67,
				type: 10,
				biome_type: 13,
				x: 31,
				y: 39,
				seed: 33048261,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 68,
				type: 63,
				biome_type: 13,
				x: 31,
				y: 40,
				seed: 66924498,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 10,
				border_direction: 3
			},
			{
				room_id: 69,
				type: 65,
				biome_type: 10,
				x: 31,
				y: 41,
				seed: 67899306,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 13,
				border_direction: 1
			},
			{
				room_id: 70,
				type: 0,
				biome_type: 10,
				x: 31,
				y: 42,
				seed: 79694879,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00141FFFFFFFF0',
					'00141FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 71,
				type: 0,
				biome_type: 10,
				x: 31,
				y: 49,
				seed: 22241524,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 72,
				type: 27,
				biome_type: 29,
				x: 31,
				y: 54,
				seed: 13119182,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 73,
				type: 0,
				biome_type: 13,
				x: 32,
				y: 34,
				seed: 71432613,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 74,
				type: 0,
				biome_type: 13,
				x: 32,
				y: 35,
				seed: 17965349,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 75,
				type: 0,
				biome_type: 13,
				x: 32,
				y: 39,
				seed: 73502663,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 76,
				type: 20,
				biome_type: 10,
				x: 32,
				y: 42,
				seed: 71039420,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 77,
				type: 21,
				biome_type: 12,
				x: 32,
				y: 44,
				seed: 20131293,
				flags: '09000',
				is_double: 1,
				master_room_x: 32,
				master_room_y: 44,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 78,
				type: 21,
				biome_type: 12,
				x: 32,
				y: 45,
				seed: 49362027,
				flags: '0B000',
				master_room_x: 32,
				master_room_y: 44,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 10,
				border_direction: 3
			},
			{
				room_id: 79,
				type: 0,
				biome_type: 10,
				x: 32,
				y: 46,
				seed: 71707879,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 12,
				border_direction: -1
			},
			{
				room_id: 80,
				type: 20,
				biome_type: 10,
				x: 32,
				y: 47,
				seed: 5462117,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 81,
				type: 0,
				biome_type: 10,
				x: 32,
				y: 48,
				seed: 43118419,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00141FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 82,
				type: 4,
				biome_type: 10,
				x: 32,
				y: 49,
				seed: 69787717,
				flags: '06000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000008E'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 83,
				type: 10,
				biome_type: 13,
				x: 33,
				y: 35,
				seed: 45516202,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 84,
				type: 0,
				biome_type: 13,
				x: 33,
				y: 36,
				seed: 92573233,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 85,
				type: 26,
				biome_type: 13,
				x: 33,
				y: 37,
				seed: 9456783,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 86,
				type: 0,
				biome_type: 13,
				x: 33,
				y: 38,
				seed: 23853156,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 87,
				type: 0,
				biome_type: 13,
				x: 33,
				y: 39,
				seed: 50502148,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 88,
				type: 1,
				biome_type: 10,
				x: 33,
				y: 42,
				seed: 69154365,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 89,
				type: 4,
				biome_type: 10,
				x: 33,
				y: 43,
				seed: 24198232,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000008A'],
				difficulty: 2,
				border: 12,
				border_direction: -1
			},
			{
				room_id: 90,
				type: 21,
				biome_type: 12,
				x: 33,
				y: 44,
				seed: 23832477,
				flags: '0E000',
				master_room_x: 32,
				master_room_y: 44,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 10,
				border_direction: 1
			},
			{
				room_id: 91,
				type: 21,
				biome_type: 12,
				x: 33,
				y: 45,
				seed: 89232261,
				flags: '06000',
				master_room_x: 32,
				master_room_y: 44,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 92,
				type: 0,
				biome_type: 10,
				x: 33,
				y: 48,
				seed: 88357962,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 93,
				type: 0,
				biome_type: 13,
				x: 34,
				y: 36,
				seed: 29045622,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 94,
				type: 0,
				biome_type: 13,
				x: 34,
				y: 39,
				seed: 82386817,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 95,
				type: 0,
				biome_type: 10,
				x: 34,
				y: 48,
				seed: 52171807,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00141FFFFFFFF0',
					'00141FFFFFFFF0',
					'00141FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 96,
				type: 10,
				biome_type: 13,
				x: 35,
				y: 33,
				seed: 69989648,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 97,
				type: 0,
				biome_type: 13,
				x: 35,
				y: 34,
				seed: 20464188,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 98,
				type: 0,
				biome_type: 13,
				x: 35,
				y: 35,
				seed: 46865277,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 99,
				type: 12,
				biome_type: 13,
				x: 35,
				y: 36,
				seed: 94783350,
				flags: '0F000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 100,
				type: 4,
				biome_type: 13,
				x: 35,
				y: 37,
				seed: 73845024,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000093'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 101,
				type: 0,
				biome_type: 13,
				x: 35,
				y: 38,
				seed: 36247149,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 102,
				type: 0,
				biome_type: 13,
				x: 35,
				y: 39,
				seed: 77777916,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 103,
				type: 10,
				biome_type: 10,
				x: 35,
				y: 46,
				seed: 37532410,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 104,
				type: 0,
				biome_type: 10,
				x: 35,
				y: 47,
				seed: 12663635,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 105,
				type: 0,
				biome_type: 10,
				x: 35,
				y: 48,
				seed: 58816913,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 106,
				type: 12,
				biome_type: 10,
				x: 35,
				y: 49,
				seed: 3069816,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 107,
				type: 0,
				biome_type: 13,
				x: 36,
				y: 33,
				seed: 61062386,
				flags: '04000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 108,
				type: 0,
				biome_type: 13,
				x: 36,
				y: 36,
				seed: 49992896,
				flags: '04000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 109,
				type: 10,
				biome_type: 13,
				x: 36,
				y: 38,
				seed: 22682160,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 110,
				type: 0,
				biome_type: 10,
				x: 36,
				y: 46,
				seed: 55051538,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0', '00141FFFFFFFF0'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 111,
				type: 0,
				biome_type: 10,
				x: 36,
				y: 49,
				seed: 30328028,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 112,
				type: 0,
				biome_type: 13,
				x: 37,
				y: 37,
				seed: 81666671,
				flags: '08000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 113,
				type: 20,
				biome_type: 13,
				x: 37,
				y: 38,
				seed: 55700282,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 114,
				type: 27,
				biome_type: 10,
				x: 37,
				y: 44,
				seed: 77694800,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 115,
				type: 0,
				biome_type: 10,
				x: 37,
				y: 45,
				seed: 10506640,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0', '00141FFFFFFFF0'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 116,
				type: 0,
				biome_type: 10,
				x: 37,
				y: 46,
				seed: 44608095,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00141FFFFFFFF0',
					'00141FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 117,
				type: 0,
				biome_type: 10,
				x: 37,
				y: 47,
				seed: 33521684,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 118,
				type: 26,
				biome_type: 10,
				x: 37,
				y: 49,
				seed: 96484563,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 119,
				type: 10,
				biome_type: 10,
				x: 37,
				y: 50,
				seed: 1525790,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 23,
				border_direction: 3
			},
			{
				room_id: 120,
				type: 4,
				biome_type: 23,
				x: 37,
				y: 51,
				seed: 80292041,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000070'],
				difficulty: 2,
				border: 10,
				border_direction: 1
			},
			{
				room_id: 121,
				type: 0,
				biome_type: 23,
				x: 37,
				y: 52,
				seed: 17467377,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 122,
				type: 1,
				biome_type: 23,
				x: 37,
				y: 53,
				seed: 88285515,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 23,
				border_direction: -1
			},
			{
				room_id: 123,
				type: 20,
				biome_type: 23,
				x: 37,
				y: 54,
				seed: 72273685,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 124,
				type: 0,
				biome_type: 10,
				x: 38,
				y: 47,
				seed: 93898946,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 125,
				type: 4,
				biome_type: 10,
				x: 38,
				y: 48,
				seed: 18710415,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000008D'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 126,
				type: 0,
				biome_type: 10,
				x: 38,
				y: 49,
				seed: 51998705,
				flags: '06000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 127,
				type: 41,
				biome_type: 23,
				x: 38,
				y: 54,
				seed: 84230357,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 128,
				type: 0,
				biome_type: 10,
				x: 39,
				y: 47,
				seed: 36993610,
				flags: '05000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0', '00141FFFFFFFF0'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 129,
				type: 4,
				biome_type: 23,
				x: 39,
				y: 54,
				seed: 71547478,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000006E'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 130,
				type: 10,
				biome_type: 25,
				x: 40,
				y: 38,
				seed: 35640297,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 131,
				type: 0,
				biome_type: 25,
				x: 40,
				y: 40,
				seed: 77454003,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 132,
				type: 10,
				biome_type: 10,
				x: 40,
				y: 47,
				seed: 80373481,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 133,
				type: 0,
				biome_type: 10,
				x: 40,
				y: 48,
				seed: 97906611,
				flags: '03000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 134,
				type: 41,
				biome_type: 23,
				x: 40,
				y: 51,
				seed: 77566933,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 135,
				type: 10,
				biome_type: 23,
				x: 40,
				y: 52,
				seed: 1578289,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 136,
				type: 27,
				biome_type: 23,
				x: 40,
				y: 53,
				seed: 90420519,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 137,
				type: 0,
				biome_type: 23,
				x: 40,
				y: 54,
				seed: 92462820,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 138,
				type: 69,
				biome_type: 1,
				x: 40,
				y: 66,
				seed: 371907,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 4,
				border_direction: -1
			},
			{
				room_id: 139,
				type: 2,
				biome_type: 4,
				x: 40,
				y: 67,
				seed: 22412979,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 42,
				border_direction: -1
			},
			{
				room_id: 140,
				type: 0,
				biome_type: 3,
				x: 40,
				y: 68,
				seed: 7671775,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 0,
				border: 4,
				border_direction: -1
			},
			{
				room_id: 141,
				type: 21,
				biome_type: 16,
				x: 41,
				y: 34,
				seed: 8845756,
				flags: '09000',
				is_double: 1,
				master_room_x: 41,
				master_room_y: 34,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 142,
				type: 21,
				biome_type: 16,
				x: 41,
				y: 35,
				seed: 65008531,
				flags: '03000',
				master_room_x: 41,
				master_room_y: 34,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 143,
				type: 0,
				biome_type: 25,
				x: 41,
				y: 38,
				seed: 10057592,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 144,
				type: 0,
				biome_type: 25,
				x: 41,
				y: 40,
				seed: 37765315,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 145,
				type: 12,
				biome_type: 25,
				x: 41,
				y: 42,
				seed: 77015011,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 146,
				type: 0,
				biome_type: 25,
				x: 41,
				y: 43,
				seed: 39121030,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 147,
				type: 0,
				biome_type: 25,
				x: 41,
				y: 44,
				seed: 67003808,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 148,
				type: 0,
				biome_type: 10,
				x: 41,
				y: 48,
				seed: 24082283,
				flags: '04000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 149,
				type: 0,
				biome_type: 23,
				x: 41,
				y: 50,
				seed: 68033388,
				flags: '08000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 150,
				type: 0,
				biome_type: 23,
				x: 41,
				y: 51,
				seed: 86911387,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 151,
				type: 0,
				biome_type: 3,
				x: 41,
				y: 68,
				seed: 99424329,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 152,
				type: 21,
				biome_type: 16,
				x: 42,
				y: 34,
				seed: 55735640,
				flags: '0C000',
				master_room_x: 41,
				master_room_y: 34,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 153,
				type: 21,
				biome_type: 16,
				x: 42,
				y: 35,
				seed: 22766144,
				flags: '0E000',
				master_room_x: 41,
				master_room_y: 34,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 25,
				border_direction: 3
			},
			{
				room_id: 154,
				type: 0,
				biome_type: 25,
				x: 42,
				y: 36,
				seed: 64168316,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: 16,
				border_direction: 1
			},
			{
				room_id: 155,
				type: 0,
				biome_type: 25,
				x: 42,
				y: 38,
				seed: 14780485,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 156,
				type: 0,
				biome_type: 25,
				x: 42,
				y: 39,
				seed: 32728998,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 157,
				type: 26,
				biome_type: 25,
				x: 42,
				y: 40,
				seed: 70215825,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 158,
				type: 20,
				biome_type: 25,
				x: 42,
				y: 41,
				seed: 95123205,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 159,
				type: 10,
				biome_type: 25,
				x: 42,
				y: 42,
				seed: 12087113,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 160,
				type: 0,
				biome_type: 25,
				x: 42,
				y: 44,
				seed: 50554254,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 161,
				type: 41,
				biome_type: 23,
				x: 42,
				y: 51,
				seed: 17973697,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 162,
				type: 10,
				biome_type: 1,
				x: 42,
				y: 56,
				seed: 95600995,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 163,
				type: 22,
				biome_type: 45,
				x: 42,
				y: 58,
				seed: 9353527,
				flags: '09000',
				is_double: 1,
				master_room_x: 42,
				master_room_y: 58,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 164,
				type: 22,
				biome_type: 45,
				x: 42,
				y: 59,
				seed: 3310845,
				flags: '0B000',
				master_room_x: 42,
				master_room_y: 58,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 1,
				border_direction: 3
			},
			{
				room_id: 165,
				type: 0,
				biome_type: 1,
				x: 42,
				y: 60,
				seed: 42019329,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000'],
				difficulty: 2,
				border: 45,
				border_direction: -1
			},
			{
				room_id: 166,
				type: 26,
				biome_type: 1,
				x: 42,
				y: 61,
				seed: 64719361,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 167,
				type: 21,
				biome_type: 44,
				x: 42,
				y: 65,
				seed: 65082002,
				flags: '09000',
				is_double: 1,
				master_room_x: 42,
				master_room_y: 65,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 168,
				type: 21,
				biome_type: 44,
				x: 42,
				y: 66,
				seed: 21239610,
				flags: '0B000',
				master_room_x: 42,
				master_room_y: 65,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 3,
				border_direction: 3
			},
			{
				room_id: 169,
				type: 0,
				biome_type: 3,
				x: 42,
				y: 67,
				seed: 63405594,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001'
				],
				difficulty: 0,
				border: 44,
				border_direction: -1
			},
			{
				room_id: 170,
				type: 0,
				biome_type: 3,
				x: 42,
				y: 68,
				seed: 42831223,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 171,
				type: 0,
				biome_type: 25,
				x: 43,
				y: 36,
				seed: 69305157,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 172,
				type: 0,
				biome_type: 25,
				x: 43,
				y: 39,
				seed: 80972705,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 173,
				type: 0,
				biome_type: 25,
				x: 43,
				y: 42,
				seed: 66646683,
				flags: '04000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 174,
				type: 0,
				biome_type: 25,
				x: 43,
				y: 44,
				seed: 1344845,
				flags: '04000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 175,
				type: 10,
				biome_type: 23,
				x: 43,
				y: 49,
				seed: 42847644,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 176,
				type: 26,
				biome_type: 23,
				x: 43,
				y: 50,
				seed: 79357735,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 177,
				type: 0,
				biome_type: 23,
				x: 43,
				y: 51,
				seed: 34351251,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 178,
				type: 0,
				biome_type: 23,
				x: 43,
				y: 52,
				seed: 40341632,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 179,
				type: 4,
				biome_type: 1,
				x: 43,
				y: 56,
				seed: 31798861,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000067'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 180,
				type: 0,
				biome_type: 1,
				x: 43,
				y: 57,
				seed: 31907119,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 2,
				border: 45,
				border_direction: -1
			},
			{
				room_id: 181,
				type: 22,
				biome_type: 45,
				x: 43,
				y: 58,
				seed: 87000463,
				flags: '0E000',
				master_room_x: 42,
				master_room_y: 58,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 1,
				border_direction: 1
			},
			{
				room_id: 182,
				type: 22,
				biome_type: 45,
				x: 43,
				y: 59,
				seed: 19853322,
				flags: '06000',
				master_room_x: 42,
				master_room_y: 58,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 183,
				type: 4,
				biome_type: 1,
				x: 43,
				y: 61,
				seed: 93895970,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000068'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 184,
				type: 0,
				biome_type: 1,
				x: 43,
				y: 63,
				seed: 19496293,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 185,
				type: 0,
				biome_type: 1,
				x: 43,
				y: 64,
				seed: 95024195,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001'
				],
				difficulty: 0,
				border: 44,
				border_direction: -1
			},
			{
				room_id: 186,
				type: 21,
				biome_type: 44,
				x: 43,
				y: 65,
				seed: 76962239,
				flags: '0E000',
				master_room_x: 42,
				master_room_y: 65,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 1,
				border_direction: 1
			},
			{
				room_id: 187,
				type: 21,
				biome_type: 44,
				x: 43,
				y: 66,
				seed: 60239146,
				flags: '06000',
				master_room_x: 42,
				master_room_y: 65,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 188,
				type: 47,
				biome_type: 25,
				x: 44,
				y: 29,
				seed: 63441970,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 25,
				border_direction: -1
			},
			{
				room_id: 189,
				type: 0,
				biome_type: 25,
				x: 44,
				y: 30,
				seed: 38169235,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 190,
				type: 20,
				biome_type: 25,
				x: 44,
				y: 31,
				seed: 2924522,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 191,
				type: 27,
				biome_type: 25,
				x: 44,
				y: 34,
				seed: 68144991,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 192,
				type: 0,
				biome_type: 25,
				x: 44,
				y: 35,
				seed: 35688217,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 193,
				type: 4,
				biome_type: 25,
				x: 44,
				y: 36,
				seed: 3795463,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF00000000A0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 194,
				type: 20,
				biome_type: 25,
				x: 44,
				y: 37,
				seed: 314275,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 195,
				type: 10,
				biome_type: 25,
				x: 44,
				y: 38,
				seed: 58428552,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 196,
				type: 0,
				biome_type: 25,
				x: 44,
				y: 39,
				seed: 92022696,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 197,
				type: 4,
				biome_type: 23,
				x: 44,
				y: 52,
				seed: 52041221,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000071'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 198,
				type: 0,
				biome_type: 23,
				x: 44,
				y: 53,
				seed: 87994216,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 199,
				type: 10,
				biome_type: 23,
				x: 44,
				y: 54,
				seed: 24028837,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 1,
				border_direction: 3
			},
			{
				room_id: 200,
				type: 59,
				biome_type: 1,
				x: 44,
				y: 55,
				seed: 50036438,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 23,
				border_direction: 1
			},
			{
				room_id: 201,
				type: 0,
				biome_type: 1,
				x: 44,
				y: 56,
				seed: 46395231,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 202,
				type: 0,
				biome_type: 1,
				x: 44,
				y: 61,
				seed: 67379630,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 203,
				type: 0,
				biome_type: 1,
				x: 44,
				y: 62,
				seed: 74406911,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 204,
				type: 20,
				biome_type: 1,
				x: 44,
				y: 63,
				seed: 9388694,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 205,
				type: 0,
				biome_type: 25,
				x: 45,
				y: 31,
				seed: 98164929,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 206,
				type: 0,
				biome_type: 25,
				x: 45,
				y: 32,
				seed: 82156208,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 207,
				type: 4,
				biome_type: 25,
				x: 45,
				y: 33,
				seed: 10485360,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF00000000A1'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 208,
				type: 0,
				biome_type: 25,
				x: 45,
				y: 34,
				seed: 60284517,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 209,
				type: 0,
				biome_type: 25,
				x: 45,
				y: 37,
				seed: 12561606,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: 26,
				border_direction: -1
			},
			{
				room_id: 210,
				type: 0,
				biome_type: 23,
				x: 45,
				y: 53,
				seed: 74919060,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 211,
				type: 0,
				biome_type: 1,
				x: 45,
				y: 56,
				seed: 38966889,
				flags: '0C000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 212,
				type: 47,
				biome_type: 1,
				x: 45,
				y: 57,
				seed: 34398215,
				flags: '03003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 213,
				type: 0,
				biome_type: 1,
				x: 45,
				y: 61,
				seed: 70258645,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 214,
				type: 10,
				biome_type: 25,
				x: 46,
				y: 34,
				seed: 54048085,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 215,
				type: 21,
				biome_type: 26,
				x: 46,
				y: 37,
				seed: 94907942,
				flags: '0D000',
				is_double: 1,
				master_room_x: 46,
				master_room_y: 37,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 25,
				border_direction: 2
			},
			{
				room_id: 216,
				type: 21,
				biome_type: 26,
				x: 46,
				y: 38,
				seed: 51343930,
				flags: '03000',
				master_room_x: 46,
				master_room_y: 37,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 217,
				type: 10,
				biome_type: 8,
				x: 46,
				y: 46,
				seed: 62935067,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 218,
				type: 4,
				biome_type: 8,
				x: 46,
				y: 49,
				seed: 60080868,
				flags: '09000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000007A'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 219,
				type: 10,
				biome_type: 8,
				x: 46,
				y: 50,
				seed: 10788733,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 23,
				border_direction: 3
			},
			{
				room_id: 220,
				type: 0,
				biome_type: 23,
				x: 46,
				y: 51,
				seed: 73641644,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: 8,
				border_direction: 1
			},
			{
				room_id: 221,
				type: 0,
				biome_type: 23,
				x: 46,
				y: 52,
				seed: 77856346,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 222,
				type: 20,
				biome_type: 23,
				x: 46,
				y: 53,
				seed: 83015239,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 223,
				type: 4,
				biome_type: 1,
				x: 46,
				y: 57,
				seed: 89649010,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000190'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 224,
				type: 0,
				biome_type: 1,
				x: 46,
				y: 58,
				seed: 38371844,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 225,
				type: 10,
				biome_type: 1,
				x: 46,
				y: 59,
				seed: 35261175,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 226,
				type: 4,
				biome_type: 1,
				x: 46,
				y: 60,
				seed: 99962540,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000065'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 227,
				type: 27,
				biome_type: 1,
				x: 46,
				y: 61,
				seed: 30384240,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 228,
				type: 0,
				biome_type: 25,
				x: 47,
				y: 34,
				seed: 21511436,
				flags: '04000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 229,
				type: 21,
				biome_type: 26,
				x: 47,
				y: 37,
				seed: 22980653,
				flags: '0D000',
				master_room_x: 46,
				master_room_y: 37,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 27,
				border_direction: 0
			},
			{
				room_id: 230,
				type: 21,
				biome_type: 26,
				x: 47,
				y: 38,
				seed: 14510560,
				flags: '0E000',
				master_room_x: 46,
				master_room_y: 37,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 25,
				border_direction: 3
			},
			{
				room_id: 231,
				type: 0,
				biome_type: 25,
				x: 47,
				y: 39,
				seed: 5292520,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 1,
				border: 26,
				border_direction: -1
			},
			{
				room_id: 232,
				type: 0,
				biome_type: 25,
				x: 47,
				y: 40,
				seed: 3427368,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 233,
				type: 0,
				biome_type: 25,
				x: 47,
				y: 41,
				seed: 33585028,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 234,
				type: 0,
				biome_type: 8,
				x: 47,
				y: 46,
				seed: 93691556,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 235,
				type: 20,
				biome_type: 8,
				x: 47,
				y: 49,
				seed: 21150877,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 236,
				type: 1,
				biome_type: 1,
				x: 47,
				y: 60,
				seed: 17269687,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 237,
				type: 0,
				biome_type: 27,
				x: 48,
				y: 37,
				seed: 16261057,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 0,
				border: 26,
				border_direction: -1
			},
			{
				room_id: 238,
				type: 0,
				biome_type: 25,
				x: 48,
				y: 41,
				seed: 21503343,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 239,
				type: 1,
				biome_type: 25,
				x: 48,
				y: 42,
				seed: 70099889,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 25,
				border_direction: -1
			},
			{
				room_id: 240,
				type: 10,
				biome_type: 25,
				x: 48,
				y: 43,
				seed: 31598436,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 8,
				border_direction: 3
			},
			{
				room_id: 241,
				type: 66,
				biome_type: 8,
				x: 48,
				y: 44,
				seed: 72590406,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 25,
				border_direction: 1
			},
			{
				room_id: 242,
				type: 70,
				biome_type: 8,
				x: 48,
				y: 45,
				seed: 78549582,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 243,
				type: 0,
				biome_type: 8,
				x: 48,
				y: 46,
				seed: 58785504,
				flags: '0F000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 244,
				type: 4,
				biome_type: 8,
				x: 48,
				y: 47,
				seed: 24502072,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000079'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 245,
				type: 0,
				biome_type: 8,
				x: 48,
				y: 48,
				seed: 57172167,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 246,
				type: 0,
				biome_type: 8,
				x: 48,
				y: 49,
				seed: 58463361,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00002'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 247,
				type: 12,
				biome_type: 8,
				x: 48,
				y: 50,
				seed: 667532,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 248,
				type: 4,
				biome_type: 8,
				x: 48,
				y: 51,
				seed: 25249427,
				flags: '02000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000078'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 249,
				type: 20,
				biome_type: 5,
				x: 48,
				y: 56,
				seed: 59118523,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 250,
				type: 0,
				biome_type: 1,
				x: 48,
				y: 60,
				seed: 49845562,
				flags: '04000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 251,
				type: 0,
				biome_type: 27,
				x: 49,
				y: 34,
				seed: 7893989,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 252,
				type: 0,
				biome_type: 27,
				x: 49,
				y: 35,
				seed: 75952531,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 253,
				type: 10,
				biome_type: 27,
				x: 49,
				y: 36,
				seed: 57830978,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 254,
				type: 0,
				biome_type: 27,
				x: 49,
				y: 37,
				seed: 47172159,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 255,
				type: 0,
				biome_type: 8,
				x: 49,
				y: 46,
				seed: 21074940,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 256,
				type: 0,
				biome_type: 8,
				x: 49,
				y: 50,
				seed: 4998494,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 257,
				type: 0,
				biome_type: 5,
				x: 49,
				y: 56,
				seed: 95013435,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 258,
				type: 0,
				biome_type: 27,
				x: 50,
				y: 32,
				seed: 84558606,
				flags: '08000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 259,
				type: 0,
				biome_type: 27,
				x: 50,
				y: 33,
				seed: 30991729,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 260,
				type: 0,
				biome_type: 27,
				x: 50,
				y: 34,
				seed: 19086393,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 261,
				type: 0,
				biome_type: 27,
				x: 50,
				y: 36,
				seed: 31621768,
				flags: '04000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 262,
				type: 20,
				biome_type: 8,
				x: 50,
				y: 46,
				seed: 8381208,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 263,
				type: 0,
				biome_type: 8,
				x: 50,
				y: 47,
				seed: 83823048,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 264,
				type: 0,
				biome_type: 8,
				x: 50,
				y: 48,
				seed: 77727063,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 265,
				type: 0,
				biome_type: 8,
				x: 50,
				y: 49,
				seed: 27318304,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 266,
				type: 4,
				biome_type: 8,
				x: 50,
				y: 50,
				seed: 44828585,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000077'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 267,
				type: 0,
				biome_type: 8,
				x: 50,
				y: 51,
				seed: 39999480,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 268,
				type: 0,
				biome_type: 5,
				x: 50,
				y: 56,
				seed: 3698720,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 269,
				type: 0,
				biome_type: 27,
				x: 51,
				y: 34,
				seed: 5521324,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 270,
				type: 0,
				biome_type: 27,
				x: 51,
				y: 35,
				seed: 48124178,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 271,
				type: 10,
				biome_type: 8,
				x: 51,
				y: 47,
				seed: 81943372,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 272,
				type: 1,
				biome_type: 8,
				x: 51,
				y: 51,
				seed: 13484466,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 5,
				border_direction: 3
			},
			{
				room_id: 273,
				type: 10,
				biome_type: 5,
				x: 51,
				y: 52,
				seed: 21140915,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 8,
				border_direction: 1
			},
			{
				room_id: 274,
				type: 0,
				biome_type: 5,
				x: 51,
				y: 53,
				seed: 93885423,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 275,
				type: 10,
				biome_type: 5,
				x: 51,
				y: 56,
				seed: 101708,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 276,
				type: 10,
				biome_type: 27,
				x: 52,
				y: 35,
				seed: 28272201,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 277,
				type: 0,
				biome_type: 8,
				x: 52,
				y: 47,
				seed: 90528006,
				flags: '04000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 278,
				type: 0,
				biome_type: 5,
				x: 52,
				y: 53,
				seed: 43695655,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 279,
				type: 0,
				biome_type: 5,
				x: 52,
				y: 54,
				seed: 1143171,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 280,
				type: 12,
				biome_type: 5,
				x: 52,
				y: 55,
				seed: 66467114,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 281,
				type: 0,
				biome_type: 5,
				x: 52,
				y: 56,
				seed: 9621860,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 282,
				type: 0,
				biome_type: 5,
				x: 52,
				y: 57,
				seed: 56283073,
				flags: '02000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00004'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 283,
				type: 0,
				biome_type: 27,
				x: 53,
				y: 35,
				seed: 9682839,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 284,
				type: 0,
				biome_type: 5,
				x: 53,
				y: 53,
				seed: 44056153,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 285,
				type: 0,
				biome_type: 27,
				x: 54,
				y: 35,
				seed: 82063143,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 49,
				border_direction: 0
			},
			{
				room_id: 286,
				type: 0,
				biome_type: 5,
				x: 54,
				y: 52,
				seed: 93731235,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 287,
				type: 0,
				biome_type: 5,
				x: 54,
				y: 53,
				seed: 89658322,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 288,
				type: 0,
				biome_type: 5,
				x: 54,
				y: 54,
				seed: 30071262,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 289,
				type: 0,
				biome_type: 5,
				x: 54,
				y: 55,
				seed: 20517694,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 290,
				type: 0,
				biome_type: 5,
				x: 54,
				y: 56,
				seed: 84687150,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 291,
				type: 10,
				biome_type: 5,
				x: 54,
				y: 57,
				seed: 37192351,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 292,
				type: 66,
				biome_type: 49,
				x: 55,
				y: 35,
				seed: 33563827,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 27,
				border_direction: 2
			},
			{
				room_id: 293,
				type: 0,
				biome_type: 6,
				x: 55,
				y: 47,
				seed: 76427715,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 294,
				type: 10,
				biome_type: 5,
				x: 55,
				y: 52,
				seed: 3847424,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 295,
				type: 0,
				biome_type: 5,
				x: 55,
				y: 55,
				seed: 9637126,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 296,
				type: 26,
				biome_type: 5,
				x: 55,
				y: 57,
				seed: 21123472,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 297,
				type: 4,
				biome_type: 5,
				x: 55,
				y: 58,
				seed: 3205429,
				flags: '03000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000080'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 298,
				type: 10,
				biome_type: 49,
				x: 56,
				y: 35,
				seed: 5973511,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 299,
				type: 0,
				biome_type: 49,
				x: 56,
				y: 36,
				seed: 78718772,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 300,
				type: 0,
				biome_type: 6,
				x: 56,
				y: 46,
				seed: 30934463,
				flags: '08000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 301,
				type: 10,
				biome_type: 6,
				x: 56,
				y: 47,
				seed: 44322098,
				flags: '0F000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 302,
				type: 0,
				biome_type: 6,
				x: 56,
				y: 48,
				seed: 45333108,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 303,
				type: 0,
				biome_type: 6,
				x: 56,
				y: 49,
				seed: 73291516,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00004'],
				difficulty: 0,
				border: 5,
				border_direction: -1
			},
			{
				room_id: 304,
				type: 4,
				biome_type: 5,
				x: 56,
				y: 50,
				seed: 22836015,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000007E'],
				difficulty: 2,
				border: 6,
				border_direction: -1
			},
			{
				room_id: 305,
				type: 0,
				biome_type: 5,
				x: 56,
				y: 51,
				seed: 58592084,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 306,
				type: 0,
				biome_type: 5,
				x: 56,
				y: 52,
				seed: 82527159,
				flags: '0F000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 307,
				type: 0,
				biome_type: 5,
				x: 56,
				y: 53,
				seed: 51195119,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004', '00011FFFFFFFF0', '00011FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 308,
				type: 4,
				biome_type: 5,
				x: 56,
				y: 54,
				seed: 86117029,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000007C'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 309,
				type: 0,
				biome_type: 5,
				x: 56,
				y: 55,
				seed: 75931447,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 310,
				type: 0,
				biome_type: 5,
				x: 56,
				y: 58,
				seed: 60535120,
				flags: '04000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 311,
				type: 47,
				biome_type: 49,
				x: 57,
				y: 31,
				seed: 62712897,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 49,
				border_direction: -1
			},
			{
				room_id: 312,
				type: 0,
				biome_type: 49,
				x: 57,
				y: 32,
				seed: 51585964,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 313,
				type: 0,
				biome_type: 49,
				x: 57,
				y: 36,
				seed: 83233620,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 314,
				type: 4,
				biome_type: 49,
				x: 57,
				y: 41,
				seed: 23178530,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00081FFFFFFFF00000000C3'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 315,
				type: 0,
				biome_type: 6,
				x: 57,
				y: 47,
				seed: 56234630,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 316,
				type: 0,
				biome_type: 5,
				x: 57,
				y: 52,
				seed: 68718498,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 317,
				type: 0,
				biome_type: 5,
				x: 57,
				y: 55,
				seed: 94698017,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 318,
				type: 0,
				biome_type: 49,
				x: 58,
				y: 32,
				seed: 42135705,
				flags: '0C000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 319,
				type: 10,
				biome_type: 49,
				x: 58,
				y: 33,
				seed: 61351636,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 320,
				type: 0,
				biome_type: 49,
				x: 58,
				y: 34,
				seed: 71806657,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 321,
				type: 0,
				biome_type: 49,
				x: 58,
				y: 36,
				seed: 12763320,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 322,
				type: 0,
				biome_type: 49,
				x: 58,
				y: 37,
				seed: 40665445,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 323,
				type: 0,
				biome_type: 49,
				x: 58,
				y: 38,
				seed: 44310523,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 324,
				type: 20,
				biome_type: 49,
				x: 58,
				y: 39,
				seed: 95108516,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 325,
				type: 0,
				biome_type: 49,
				x: 58,
				y: 40,
				seed: 65210656,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 326,
				type: 0,
				biome_type: 49,
				x: 58,
				y: 41,
				seed: 27528886,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 327,
				type: 0,
				biome_type: 6,
				x: 58,
				y: 47,
				seed: 74298484,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 328,
				type: 0,
				biome_type: 5,
				x: 58,
				y: 52,
				seed: 66898346,
				flags: '0C000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 329,
				type: 1,
				biome_type: 5,
				x: 58,
				y: 53,
				seed: 31465472,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 330,
				type: 0,
				biome_type: 5,
				x: 58,
				y: 54,
				seed: 87710522,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 331,
				type: 10,
				biome_type: 5,
				x: 58,
				y: 55,
				seed: 46427427,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 332,
				type: 0,
				biome_type: 49,
				x: 59,
				y: 34,
				seed: 99504968,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 333,
				type: 26,
				biome_type: 49,
				x: 59,
				y: 35,
				seed: 54587092,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 334,
				type: 0,
				biome_type: 49,
				x: 59,
				y: 36,
				seed: 29089790,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 335,
				type: 10,
				biome_type: 49,
				x: 59,
				y: 38,
				seed: 34812497,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 336,
				type: 0,
				biome_type: 49,
				x: 59,
				y: 40,
				seed: 66817651,
				flags: '04000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 337,
				type: 47,
				biome_type: 6,
				x: 59,
				y: 45,
				seed: 97685157,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 6,
				border_direction: -1
			},
			{
				room_id: 338,
				type: 0,
				biome_type: 6,
				x: 59,
				y: 46,
				seed: 63776852,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 339,
				type: 0,
				biome_type: 6,
				x: 59,
				y: 47,
				seed: 19533710,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 340,
				type: 0,
				biome_type: 5,
				x: 59,
				y: 54,
				seed: 58878677,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 341,
				type: 20,
				biome_type: 49,
				x: 60,
				y: 35,
				seed: 82148932,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 342,
				type: 0,
				biome_type: 49,
				x: 60,
				y: 36,
				seed: 25797362,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 343,
				type: 0,
				biome_type: 49,
				x: 60,
				y: 37,
				seed: 90087637,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 344,
				type: 4,
				biome_type: 49,
				x: 60,
				y: 38,
				seed: 64390400,
				flags: '07000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF00000000C0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 345,
				type: 21,
				biome_type: 7,
				x: 60,
				y: 52,
				seed: 66122685,
				flags: '09000',
				is_double: 1,
				master_room_x: 60,
				master_room_y: 52,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 346,
				type: 21,
				biome_type: 7,
				x: 60,
				y: 53,
				seed: 48123361,
				flags: '0B000',
				master_room_x: 60,
				master_room_y: 52,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 5,
				border_direction: 3
			},
			{
				room_id: 347,
				type: 0,
				biome_type: 5,
				x: 60,
				y: 54,
				seed: 75762592,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00004'],
				difficulty: 2,
				border: 7,
				border_direction: -1
			},
			{
				room_id: 348,
				type: 0,
				biome_type: 47,
				x: 60,
				y: 58,
				seed: 22731055,
				flags: '01000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 349,
				type: 0,
				biome_type: 49,
				x: 61,
				y: 35,
				seed: 53365157,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 350,
				type: 0,
				biome_type: 49,
				x: 61,
				y: 38,
				seed: 24244976,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 351,
				type: 21,
				biome_type: 7,
				x: 61,
				y: 52,
				seed: 96715888,
				flags: '0C000',
				master_room_x: 60,
				master_room_y: 52,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 352,
				type: 21,
				biome_type: 7,
				x: 61,
				y: 53,
				seed: 85959756,
				flags: '07000',
				master_room_x: 60,
				master_room_y: 52,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 47,
				border_direction: 0
			},
			{
				room_id: 353,
				type: 0,
				biome_type: 47,
				x: 61,
				y: 58,
				seed: 6055605,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 354,
				type: 10,
				biome_type: 49,
				x: 62,
				y: 35,
				seed: 44098242,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 355,
				type: 0,
				biome_type: 49,
				x: 62,
				y: 38,
				seed: 55112013,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 356,
				type: 62,
				biome_type: 47,
				x: 62,
				y: 53,
				seed: 71577931,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 7,
				border_direction: -1
			},
			{
				room_id: 357,
				type: 0,
				biome_type: 47,
				x: 62,
				y: 57,
				seed: 50885792,
				flags: '08000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 358,
				type: 10,
				biome_type: 47,
				x: 62,
				y: 58,
				seed: 21035622,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 359,
				type: 29,
				biome_type: 50,
				x: 63,
				y: 31,
				seed: 50475928,
				flags: '09000',
				is_double: 1,
				master_room_x: 63,
				master_room_y: 31,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 360,
				type: 29,
				biome_type: 50,
				x: 63,
				y: 32,
				seed: 66397960,
				flags: '0B000',
				master_room_x: 63,
				master_room_y: 31,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 49,
				border_direction: 3
			},
			{
				room_id: 361,
				type: 0,
				biome_type: 49,
				x: 63,
				y: 33,
				seed: 24708596,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: 50,
				border_direction: 1
			},
			{
				room_id: 362,
				type: 0,
				biome_type: 49,
				x: 63,
				y: 34,
				seed: 8403824,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 363,
				type: 0,
				biome_type: 49,
				x: 63,
				y: 35,
				seed: 61074458,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 364,
				type: 0,
				biome_type: 49,
				x: 63,
				y: 36,
				seed: 20752195,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 365,
				type: 4,
				biome_type: 49,
				x: 63,
				y: 37,
				seed: 53518087,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00081FFFFFFFF00000000C2'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 366,
				type: 0,
				biome_type: 49,
				x: 63,
				y: 38,
				seed: 52067424,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 367,
				type: 10,
				biome_type: 47,
				x: 63,
				y: 53,
				seed: 43634361,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 368,
				type: 0,
				biome_type: 47,
				x: 63,
				y: 54,
				seed: 14257669,
				flags: '02000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 369,
				type: 12,
				biome_type: 47,
				x: 63,
				y: 58,
				seed: 81594982,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 370,
				type: 29,
				biome_type: 50,
				x: 64,
				y: 31,
				seed: 31886442,
				flags: '0C000',
				master_room_x: 63,
				master_room_y: 31,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 371,
				type: 29,
				biome_type: 50,
				x: 64,
				y: 32,
				seed: 13614613,
				flags: '06000',
				master_room_x: 63,
				master_room_y: 31,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 372,
				type: 0,
				biome_type: 49,
				x: 64,
				y: 36,
				seed: 67750437,
				flags: '04000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 373,
				type: 0,
				biome_type: 47,
				x: 64,
				y: 51,
				seed: 25430363,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 374,
				type: 0,
				biome_type: 47,
				x: 64,
				y: 53,
				seed: 59683388,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 375,
				type: 0,
				biome_type: 47,
				x: 64,
				y: 58,
				seed: 53325511,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 376,
				type: 0,
				biome_type: 47,
				x: 64,
				y: 60,
				seed: 47612913,
				flags: '01000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 377,
				type: 21,
				biome_type: 48,
				x: 65,
				y: 48,
				seed: 53324989,
				flags: '09000',
				is_double: 1,
				master_room_x: 65,
				master_room_y: 48,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 378,
				type: 21,
				biome_type: 48,
				x: 65,
				y: 49,
				seed: 24357706,
				flags: '0B000',
				master_room_x: 65,
				master_room_y: 48,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 47,
				border_direction: 3
			},
			{
				room_id: 379,
				type: 0,
				biome_type: 47,
				x: 65,
				y: 50,
				seed: 32197677,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 1,
				border: 48,
				border_direction: -1
			},
			{
				room_id: 380,
				type: 20,
				biome_type: 47,
				x: 65,
				y: 51,
				seed: 7284198,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 381,
				type: 0,
				biome_type: 47,
				x: 65,
				y: 52,
				seed: 22668704,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 382,
				type: 0,
				biome_type: 47,
				x: 65,
				y: 53,
				seed: 12522936,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 383,
				type: 12,
				biome_type: 47,
				x: 65,
				y: 58,
				seed: 94368949,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 384,
				type: 0,
				biome_type: 47,
				x: 65,
				y: 59,
				seed: 88676789,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 385,
				type: 0,
				biome_type: 47,
				x: 65,
				y: 60,
				seed: 17780876,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 386,
				type: 21,
				biome_type: 48,
				x: 66,
				y: 48,
				seed: 10865957,
				flags: '0D000',
				master_room_x: 65,
				master_room_y: 48,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 47,
				border_direction: 0
			},
			{
				room_id: 387,
				type: 21,
				biome_type: 48,
				x: 66,
				y: 49,
				seed: 48261892,
				flags: '06000',
				master_room_x: 65,
				master_room_y: 48,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 388,
				type: 0,
				biome_type: 47,
				x: 66,
				y: 53,
				seed: 70124791,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 389,
				type: 47,
				biome_type: 47,
				x: 66,
				y: 56,
				seed: 9126507,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 47,
				border_direction: -1
			},
			{
				room_id: 390,
				type: 0,
				biome_type: 47,
				x: 66,
				y: 57,
				seed: 26194170,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 391,
				type: 0,
				biome_type: 47,
				x: 66,
				y: 58,
				seed: 67432577,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 392,
				type: 10,
				biome_type: 47,
				x: 66,
				y: 60,
				seed: 82245007,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 393,
				type: 0,
				biome_type: 47,
				x: 67,
				y: 48,
				seed: 70169944,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: 48,
				border_direction: -1
			},
			{
				room_id: 394,
				type: 0,
				biome_type: 47,
				x: 67,
				y: 53,
				seed: 2340999,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 395,
				type: 20,
				biome_type: 47,
				x: 67,
				y: 58,
				seed: 62996092,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 396,
				type: 0,
				biome_type: 47,
				x: 67,
				y: 59,
				seed: 66938214,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 397,
				type: 26,
				biome_type: 47,
				x: 67,
				y: 60,
				seed: 69486261,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 398,
				type: 0,
				biome_type: 47,
				x: 68,
				y: 48,
				seed: 16517314,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 399,
				type: 0,
				biome_type: 47,
				x: 68,
				y: 49,
				seed: 59027404,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 400,
				type: 0,
				biome_type: 47,
				x: 68,
				y: 50,
				seed: 981922,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 401,
				type: 4,
				biome_type: 47,
				x: 68,
				y: 51,
				seed: 2995983,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF00000000B7'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 402,
				type: 10,
				biome_type: 47,
				x: 68,
				y: 52,
				seed: 88323183,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 403,
				type: 0,
				biome_type: 47,
				x: 68,
				y: 53,
				seed: 46006844,
				flags: '0F000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 404,
				type: 0,
				biome_type: 47,
				x: 68,
				y: 54,
				seed: 23618020,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 405,
				type: 0,
				biome_type: 47,
				x: 68,
				y: 55,
				seed: 7089639,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 406,
				type: 0,
				biome_type: 47,
				x: 68,
				y: 57,
				seed: 52065813,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 407,
				type: 4,
				biome_type: 47,
				x: 68,
				y: 58,
				seed: 79347123,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00081FFFFFFFF00000000B6'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 408,
				type: 4,
				biome_type: 47,
				x: 69,
				y: 48,
				seed: 21455036,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00081FFFFFFFF00000000B9'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 409,
				type: 0,
				biome_type: 47,
				x: 69,
				y: 53,
				seed: 31684062,
				flags: '04000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 410,
				type: 0,
				biome_type: 47,
				x: 69,
				y: 55,
				seed: 84305869,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 411,
				type: 0,
				biome_type: 47,
				x: 69,
				y: 56,
				seed: 56972173,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 412,
				type: 10,
				biome_type: 47,
				x: 69,
				y: 57,
				seed: 81297680,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 413,
				type: 10,
				biome_type: 47,
				x: 70,
				y: 48,
				seed: 69025894,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			}
		]
	},
	{
		name: 'Worldgen 4',
		sprite: 's_mapgen_test_4',
		spawn_x: 44,
		spawn_y: 67,
		rooms: [
			{
				room_id: 0,
				type: 0,
				biome_type: 25,
				x: 24,
				y: 41,
				seed: 50463484,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 1,
				type: 0,
				biome_type: 25,
				x: 24,
				y: 43,
				seed: 70228362,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 2,
				type: 20,
				biome_type: 25,
				x: 25,
				y: 41,
				seed: 23502473,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 3,
				type: 0,
				biome_type: 25,
				x: 25,
				y: 43,
				seed: 71254199,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 4,
				type: 21,
				biome_type: 16,
				x: 25,
				y: 47,
				seed: 10885472,
				flags: '09000',
				is_double: 1,
				master_room_x: 25,
				master_room_y: 47,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 5,
				type: 21,
				biome_type: 16,
				x: 25,
				y: 48,
				seed: 7030209,
				flags: '03000',
				master_room_x: 25,
				master_room_y: 47,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 6,
				type: 10,
				biome_type: 25,
				x: 26,
				y: 40,
				seed: 38608198,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 7,
				type: 0,
				biome_type: 25,
				x: 26,
				y: 41,
				seed: 11554653,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 8,
				type: 0,
				biome_type: 25,
				x: 26,
				y: 42,
				seed: 61532023,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 9,
				type: 0,
				biome_type: 25,
				x: 26,
				y: 43,
				seed: 38563921,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 10,
				type: 21,
				biome_type: 16,
				x: 26,
				y: 47,
				seed: 77332660,
				flags: '0C000',
				master_room_x: 25,
				master_room_y: 47,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 11,
				type: 21,
				biome_type: 16,
				x: 26,
				y: 48,
				seed: 38478407,
				flags: '0E000',
				master_room_x: 25,
				master_room_y: 47,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 25,
				border_direction: 3
			},
			{
				room_id: 12,
				type: 0,
				biome_type: 25,
				x: 26,
				y: 49,
				seed: 11137522,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 16,
				border_direction: 1
			},
			{
				room_id: 13,
				type: 27,
				biome_type: 25,
				x: 26,
				y: 50,
				seed: 94890858,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 14,
				type: 10,
				biome_type: 25,
				x: 26,
				y: 51,
				seed: 91765865,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 15,
				type: 0,
				biome_type: 25,
				x: 27,
				y: 40,
				seed: 74354237,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 16,
				type: 0,
				biome_type: 25,
				x: 27,
				y: 43,
				seed: 92824251,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 17,
				type: 0,
				biome_type: 25,
				x: 27,
				y: 50,
				seed: 36584750,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 18,
				type: 47,
				biome_type: 25,
				x: 28,
				y: 38,
				seed: 97329055,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 25,
				border_direction: -1
			},
			{
				room_id: 19,
				type: 4,
				biome_type: 25,
				x: 28,
				y: 39,
				seed: 94284296,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF00000000A0'],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 20,
				type: 0,
				biome_type: 25,
				x: 28,
				y: 40,
				seed: 78801503,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 21,
				type: 0,
				biome_type: 25,
				x: 28,
				y: 42,
				seed: 7004953,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 22,
				type: 0,
				biome_type: 25,
				x: 28,
				y: 43,
				seed: 5695671,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 23,
				type: 10,
				biome_type: 25,
				x: 28,
				y: 44,
				seed: 57317994,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 24,
				type: 0,
				biome_type: 25,
				x: 28,
				y: 45,
				seed: 34143932,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 25,
				type: 0,
				biome_type: 25,
				x: 28,
				y: 50,
				seed: 51145627,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 26,
				type: 0,
				biome_type: 25,
				x: 28,
				y: 55,
				seed: 5607175,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 27,
				type: 0,
				biome_type: 25,
				x: 29,
				y: 42,
				seed: 47299174,
				flags: '04000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 28,
				type: 0,
				biome_type: 25,
				x: 29,
				y: 45,
				seed: 81611867,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: 26,
				border_direction: -1
			},
			{
				room_id: 29,
				type: 0,
				biome_type: 25,
				x: 29,
				y: 49,
				seed: 55707294,
				flags: '08000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 30,
				type: 0,
				biome_type: 25,
				x: 29,
				y: 50,
				seed: 34088808,
				flags: '0F000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 31,
				type: 0,
				biome_type: 25,
				x: 29,
				y: 51,
				seed: 22447814,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 32,
				type: 0,
				biome_type: 25,
				x: 29,
				y: 52,
				seed: 25473948,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 33,
				type: 10,
				biome_type: 25,
				x: 29,
				y: 53,
				seed: 51241889,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 34,
				type: 12,
				biome_type: 25,
				x: 29,
				y: 54,
				seed: 40665968,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 35,
				type: 0,
				biome_type: 25,
				x: 29,
				y: 55,
				seed: 79897984,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 36,
				type: 21,
				biome_type: 26,
				x: 30,
				y: 45,
				seed: 9748333,
				flags: '0D000',
				is_double: 1,
				master_room_x: 30,
				master_room_y: 45,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 25,
				border_direction: 2
			},
			{
				room_id: 37,
				type: 21,
				biome_type: 26,
				x: 30,
				y: 46,
				seed: 32560658,
				flags: '03000',
				master_room_x: 30,
				master_room_y: 45,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 38,
				type: 4,
				biome_type: 25,
				x: 30,
				y: 50,
				seed: 81402733,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF00000000A1'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 39,
				type: 0,
				biome_type: 25,
				x: 30,
				y: 55,
				seed: 44355919,
				flags: '04000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 40,
				type: 21,
				biome_type: 26,
				x: 31,
				y: 45,
				seed: 32465555,
				flags: '0D000',
				master_room_x: 30,
				master_room_y: 45,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 27,
				border_direction: 0
			},
			{
				room_id: 41,
				type: 21,
				biome_type: 26,
				x: 31,
				y: 46,
				seed: 18320696,
				flags: '0E000',
				master_room_x: 30,
				master_room_y: 45,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 25,
				border_direction: 3
			},
			{
				room_id: 42,
				type: 0,
				biome_type: 25,
				x: 31,
				y: 47,
				seed: 97902754,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 1,
				border: 26,
				border_direction: -1
			},
			{
				room_id: 43,
				type: 20,
				biome_type: 25,
				x: 31,
				y: 48,
				seed: 44205333,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 44,
				type: 10,
				biome_type: 25,
				x: 31,
				y: 49,
				seed: 65507709,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 45,
				type: 0,
				biome_type: 25,
				x: 31,
				y: 50,
				seed: 30385296,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 46,
				type: 0,
				biome_type: 25,
				x: 31,
				y: 51,
				seed: 24157541,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 47,
				type: 0,
				biome_type: 25,
				x: 31,
				y: 52,
				seed: 76476168,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 48,
				type: 20,
				biome_type: 25,
				x: 31,
				y: 53,
				seed: 28933007,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 49,
				type: 0,
				biome_type: 27,
				x: 32,
				y: 45,
				seed: 76475252,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006', '00001FFFFFFFF00006'],
				difficulty: 0,
				border: 26,
				border_direction: -1
			},
			{
				room_id: 50,
				type: 0,
				biome_type: 25,
				x: 32,
				y: 48,
				seed: 33367286,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 51,
				type: 47,
				biome_type: 49,
				x: 33,
				y: 34,
				seed: 31495947,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 49,
				border_direction: -1
			},
			{
				room_id: 52,
				type: 4,
				biome_type: 49,
				x: 33,
				y: 35,
				seed: 95628440,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00081FFFFFFFF00000000C2'],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 53,
				type: 10,
				biome_type: 49,
				x: 33,
				y: 37,
				seed: 78266750,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 54,
				type: 10,
				biome_type: 27,
				x: 33,
				y: 40,
				seed: 9152468,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 55,
				type: 0,
				biome_type: 27,
				x: 33,
				y: 41,
				seed: 74564371,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 56,
				type: 0,
				biome_type: 27,
				x: 33,
				y: 42,
				seed: 82453591,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 57,
				type: 0,
				biome_type: 27,
				x: 33,
				y: 45,
				seed: 64452977,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 58,
				type: 0,
				biome_type: 25,
				x: 33,
				y: 48,
				seed: 22094313,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 59,
				type: 0,
				biome_type: 49,
				x: 34,
				y: 35,
				seed: 98753741,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 60,
				type: 0,
				biome_type: 49,
				x: 34,
				y: 37,
				seed: 39262981,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 61,
				type: 0,
				biome_type: 27,
				x: 34,
				y: 40,
				seed: 82152023,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: 49,
				border_direction: 0
			},
			{
				room_id: 62,
				type: 0,
				biome_type: 27,
				x: 34,
				y: 42,
				seed: 82952380,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 63,
				type: 0,
				biome_type: 27,
				x: 34,
				y: 43,
				seed: 11526841,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 64,
				type: 0,
				biome_type: 27,
				x: 34,
				y: 44,
				seed: 9748394,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 65,
				type: 10,
				biome_type: 27,
				x: 34,
				y: 45,
				seed: 75031110,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 66,
				type: 0,
				biome_type: 25,
				x: 34,
				y: 48,
				seed: 28768434,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 67,
				type: 26,
				biome_type: 25,
				x: 34,
				y: 49,
				seed: 47367652,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 68,
				type: 0,
				biome_type: 25,
				x: 34,
				y: 50,
				seed: 49669812,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 69,
				type: 1,
				biome_type: 25,
				x: 34,
				y: 51,
				seed: 55922932,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 25,
				border_direction: -1
			},
			{
				room_id: 70,
				type: 10,
				biome_type: 25,
				x: 34,
				y: 52,
				seed: 20092068,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 8,
				border_direction: 3
			},
			{
				room_id: 71,
				type: 66,
				biome_type: 8,
				x: 34,
				y: 53,
				seed: 73922507,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 25,
				border_direction: 1
			},
			{
				room_id: 72,
				type: 0,
				biome_type: 8,
				x: 34,
				y: 54,
				seed: 92055538,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 73,
				type: 20,
				biome_type: 49,
				x: 35,
				y: 34,
				seed: 57249986,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 74,
				type: 0,
				biome_type: 49,
				x: 35,
				y: 35,
				seed: 95741030,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 75,
				type: 0,
				biome_type: 49,
				x: 35,
				y: 36,
				seed: 32485752,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 76,
				type: 0,
				biome_type: 49,
				x: 35,
				y: 37,
				seed: 83364853,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 77,
				type: 66,
				biome_type: 49,
				x: 35,
				y: 40,
				seed: 9268353,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 27,
				border_direction: 2
			},
			{
				room_id: 78,
				type: 0,
				biome_type: 27,
				x: 35,
				y: 44,
				seed: 54336333,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 79,
				type: 0,
				biome_type: 25,
				x: 35,
				y: 48,
				seed: 53473003,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 80,
				type: 0,
				biome_type: 8,
				x: 35,
				y: 54,
				seed: 37638603,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00001'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 81,
				type: 0,
				biome_type: 49,
				x: 36,
				y: 32,
				seed: 26435702,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 82,
				type: 0,
				biome_type: 49,
				x: 36,
				y: 34,
				seed: 60746237,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 83,
				type: 0,
				biome_type: 49,
				x: 36,
				y: 37,
				seed: 37813606,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 84,
				type: 0,
				biome_type: 49,
				x: 36,
				y: 38,
				seed: 85741578,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 85,
				type: 4,
				biome_type: 49,
				x: 36,
				y: 39,
				seed: 97285523,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00081FFFFFFFF00000000C3'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 86,
				type: 10,
				biome_type: 49,
				x: 36,
				y: 40,
				seed: 76389754,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 87,
				type: 0,
				biome_type: 27,
				x: 36,
				y: 44,
				seed: 98612729,
				flags: '04000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006',
					'00001FFFFFFFF00006'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 88,
				type: 10,
				biome_type: 25,
				x: 36,
				y: 48,
				seed: 52096557,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 89,
				type: 0,
				biome_type: 25,
				x: 36,
				y: 49,
				seed: 51829506,
				flags: '02000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00006'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 90,
				type: 0,
				biome_type: 8,
				x: 36,
				y: 54,
				seed: 48453939,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 91,
				type: 10,
				biome_type: 49,
				x: 37,
				y: 31,
				seed: 21107149,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 92,
				type: 0,
				biome_type: 49,
				x: 37,
				y: 32,
				seed: 26196230,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 93,
				type: 4,
				biome_type: 49,
				x: 37,
				y: 33,
				seed: 75596678,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00081FFFFFFFF00000000C0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 94,
				type: 0,
				biome_type: 49,
				x: 37,
				y: 34,
				seed: 20190564,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 95,
				type: 0,
				biome_type: 49,
				x: 37,
				y: 37,
				seed: 80245547,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 96,
				type: 4,
				biome_type: 8,
				x: 37,
				y: 52,
				seed: 9889015,
				flags: '01000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000007A'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 97,
				type: 0,
				biome_type: 8,
				x: 37,
				y: 54,
				seed: 73728179,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 98,
				type: 0,
				biome_type: 8,
				x: 37,
				y: 55,
				seed: 13247701,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 99,
				type: 0,
				biome_type: 8,
				x: 37,
				y: 56,
				seed: 52812210,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 100,
				type: 10,
				biome_type: 8,
				x: 37,
				y: 57,
				seed: 11673409,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 101,
				type: 0,
				biome_type: 8,
				x: 37,
				y: 58,
				seed: 37084258,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 102,
				type: 0,
				biome_type: 49,
				x: 38,
				y: 34,
				seed: 77909017,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 103,
				type: 0,
				biome_type: 49,
				x: 38,
				y: 35,
				seed: 6131548,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 104,
				type: 10,
				biome_type: 49,
				x: 38,
				y: 36,
				seed: 57546892,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 105,
				type: 26,
				biome_type: 49,
				x: 38,
				y: 37,
				seed: 1348108,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 106,
				type: 70,
				biome_type: 8,
				x: 38,
				y: 48,
				seed: 44176332,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 107,
				type: 0,
				biome_type: 8,
				x: 38,
				y: 49,
				seed: 21795331,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 108,
				type: 0,
				biome_type: 8,
				x: 38,
				y: 50,
				seed: 84308566,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 109,
				type: 0,
				biome_type: 8,
				x: 38,
				y: 51,
				seed: 5225785,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 110,
				type: 10,
				biome_type: 8,
				x: 38,
				y: 52,
				seed: 77530194,
				flags: '0F000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 111,
				type: 0,
				biome_type: 8,
				x: 38,
				y: 53,
				seed: 51297693,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 112,
				type: 4,
				biome_type: 8,
				x: 38,
				y: 54,
				seed: 21383968,
				flags: '07000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000079'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 113,
				type: 0,
				biome_type: 8,
				x: 38,
				y: 58,
				seed: 75163150,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 114,
				type: 0,
				biome_type: 49,
				x: 39,
				y: 37,
				seed: 62304770,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 115,
				type: 0,
				biome_type: 49,
				x: 39,
				y: 38,
				seed: 5806266,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 116,
				type: 0,
				biome_type: 49,
				x: 39,
				y: 39,
				seed: 84449359,
				flags: '02000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 117,
				type: 0,
				biome_type: 8,
				x: 39,
				y: 48,
				seed: 81736643,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 118,
				type: 0,
				biome_type: 8,
				x: 39,
				y: 52,
				seed: 94128063,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 119,
				type: 0,
				biome_type: 8,
				x: 39,
				y: 54,
				seed: 64804468,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 120,
				type: 0,
				biome_type: 8,
				x: 39,
				y: 55,
				seed: 73803663,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00001'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 121,
				type: 1,
				biome_type: 8,
				x: 39,
				y: 56,
				seed: 15672562,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 122,
				type: 0,
				biome_type: 8,
				x: 39,
				y: 57,
				seed: 7715570,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 123,
				type: 20,
				biome_type: 8,
				x: 39,
				y: 58,
				seed: 16165113,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 124,
				type: 20,
				biome_type: 49,
				x: 40,
				y: 36,
				seed: 8173298,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 125,
				type: 0,
				biome_type: 49,
				x: 40,
				y: 37,
				seed: 74609357,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 126,
				type: 0,
				biome_type: 6,
				x: 40,
				y: 42,
				seed: 35368052,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 127,
				type: 0,
				biome_type: 6,
				x: 40,
				y: 43,
				seed: 29484917,
				flags: '02000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 128,
				type: 0,
				biome_type: 8,
				x: 40,
				y: 48,
				seed: 45186739,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 129,
				type: 0,
				biome_type: 8,
				x: 40,
				y: 49,
				seed: 13888510,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 130,
				type: 12,
				biome_type: 8,
				x: 40,
				y: 50,
				seed: 91946450,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 131,
				type: 20,
				biome_type: 8,
				x: 40,
				y: 51,
				seed: 22490364,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 132,
				type: 4,
				biome_type: 8,
				x: 40,
				y: 52,
				seed: 96651351,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000078'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 133,
				type: 0,
				biome_type: 8,
				x: 40,
				y: 53,
				seed: 24078477,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 134,
				type: 0,
				biome_type: 8,
				x: 40,
				y: 58,
				seed: 59523977,
				flags: '04000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 135,
				type: 29,
				biome_type: 50,
				x: 41,
				y: 33,
				seed: 93697538,
				flags: '09000',
				is_double: 1,
				master_room_x: 41,
				master_room_y: 33,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 136,
				type: 29,
				biome_type: 50,
				x: 41,
				y: 34,
				seed: 79357514,
				flags: '0B000',
				master_room_x: 41,
				master_room_y: 33,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 49,
				border_direction: 3
			},
			{
				room_id: 137,
				type: 0,
				biome_type: 49,
				x: 41,
				y: 35,
				seed: 682157,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00007', '00001FFFFFFFF00007'],
				difficulty: 2,
				border: 50,
				border_direction: 1
			},
			{
				room_id: 138,
				type: 0,
				biome_type: 49,
				x: 41,
				y: 36,
				seed: 73670002,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007',
					'00001FFFFFFFF00007'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 139,
				type: 0,
				biome_type: 6,
				x: 41,
				y: 42,
				seed: 70052877,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 140,
				type: 4,
				biome_type: 8,
				x: 41,
				y: 49,
				seed: 66974904,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000077'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 141,
				type: 0,
				biome_type: 8,
				x: 41,
				y: 53,
				seed: 64940221,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 142,
				type: 29,
				biome_type: 50,
				x: 42,
				y: 33,
				seed: 57754928,
				flags: '0C000',
				master_room_x: 41,
				master_room_y: 33,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 143,
				type: 29,
				biome_type: 50,
				x: 42,
				y: 34,
				seed: 10474707,
				flags: '06000',
				master_room_x: 41,
				master_room_y: 33,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 144,
				type: 47,
				biome_type: 6,
				x: 42,
				y: 39,
				seed: 25358230,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 6,
				border_direction: -1
			},
			{
				room_id: 145,
				type: 0,
				biome_type: 6,
				x: 42,
				y: 40,
				seed: 36061180,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 146,
				type: 0,
				biome_type: 6,
				x: 42,
				y: 41,
				seed: 10885099,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 147,
				type: 0,
				biome_type: 6,
				x: 42,
				y: 42,
				seed: 18456294,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 148,
				type: 0,
				biome_type: 8,
				x: 42,
				y: 49,
				seed: 84746315,
				flags: '0C000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 2,
				border: 5,
				border_direction: 3
			},
			{
				room_id: 149,
				type: 10,
				biome_type: 5,
				x: 42,
				y: 50,
				seed: 41576437,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 8,
				border_direction: 1
			},
			{
				room_id: 150,
				type: 12,
				biome_type: 5,
				x: 42,
				y: 51,
				seed: 35988142,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 151,
				type: 0,
				biome_type: 8,
				x: 42,
				y: 53,
				seed: 73237001,
				flags: '0C000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 152,
				type: 10,
				biome_type: 8,
				x: 42,
				y: 54,
				seed: 41879534,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 23,
				border_direction: 3
			},
			{
				room_id: 153,
				type: 0,
				biome_type: 23,
				x: 42,
				y: 55,
				seed: 46682818,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00001'],
				difficulty: 2,
				border: 8,
				border_direction: 1
			},
			{
				room_id: 154,
				type: 0,
				biome_type: 23,
				x: 42,
				y: 56,
				seed: 94102962,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 155,
				type: 0,
				biome_type: 23,
				x: 42,
				y: 57,
				seed: 6618394,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 156,
				type: 20,
				biome_type: 23,
				x: 42,
				y: 58,
				seed: 62647606,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 157,
				type: 10,
				biome_type: 23,
				x: 42,
				y: 59,
				seed: 98454474,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 158,
				type: 41,
				biome_type: 23,
				x: 42,
				y: 60,
				seed: 16932485,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 159,
				type: 0,
				biome_type: 23,
				x: 42,
				y: 61,
				seed: 73135439,
				flags: '02000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 160,
				type: 0,
				biome_type: 6,
				x: 43,
				y: 42,
				seed: 76802849,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 161,
				type: 10,
				biome_type: 6,
				x: 43,
				y: 43,
				seed: 14639595,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 162,
				type: 0,
				biome_type: 6,
				x: 43,
				y: 44,
				seed: 4411399,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004'],
				difficulty: 0,
				border: 5,
				border_direction: -1
			},
			{
				room_id: 163,
				type: 0,
				biome_type: 5,
				x: 43,
				y: 45,
				seed: 48813567,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: 6,
				border_direction: -1
			},
			{
				room_id: 164,
				type: 0,
				biome_type: 5,
				x: 43,
				y: 46,
				seed: 70742679,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 165,
				type: 4,
				biome_type: 5,
				x: 43,
				y: 51,
				seed: 16710208,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000007E'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 166,
				type: 41,
				biome_type: 23,
				x: 43,
				y: 57,
				seed: 13850861,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 167,
				type: 0,
				biome_type: 6,
				x: 44,
				y: 42,
				seed: 31786069,
				flags: '04000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 168,
				type: 0,
				biome_type: 5,
				x: 44,
				y: 46,
				seed: 70084478,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 169,
				type: 0,
				biome_type: 5,
				x: 44,
				y: 51,
				seed: 83848772,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 170,
				type: 26,
				biome_type: 23,
				x: 44,
				y: 56,
				seed: 49752828,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 171,
				type: 0,
				biome_type: 23,
				x: 44,
				y: 57,
				seed: 74361579,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 172,
				type: 69,
				biome_type: 1,
				x: 44,
				y: 67,
				seed: 77616970,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 4,
				border_direction: -1
			},
			{
				room_id: 173,
				type: 2,
				biome_type: 4,
				x: 44,
				y: 68,
				seed: 68294588,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 42,
				border_direction: -1
			},
			{
				room_id: 174,
				type: 0,
				biome_type: 3,
				x: 44,
				y: 69,
				seed: 57398164,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000'],
				difficulty: 0,
				border: 4,
				border_direction: -1
			},
			{
				room_id: 175,
				type: 0,
				biome_type: 5,
				x: 45,
				y: 46,
				seed: 14304890,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 176,
				type: 0,
				biome_type: 5,
				x: 45,
				y: 47,
				seed: 47658573,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 177,
				type: 0,
				biome_type: 5,
				x: 45,
				y: 48,
				seed: 31266594,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 178,
				type: 0,
				biome_type: 5,
				x: 45,
				y: 49,
				seed: 86128317,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 179,
				type: 20,
				biome_type: 5,
				x: 45,
				y: 50,
				seed: 11546427,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 180,
				type: 0,
				biome_type: 5,
				x: 45,
				y: 51,
				seed: 83877603,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 181,
				type: 0,
				biome_type: 23,
				x: 45,
				y: 54,
				seed: 31220607,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 182,
				type: 4,
				biome_type: 23,
				x: 45,
				y: 55,
				seed: 2729695,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000071'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 183,
				type: 0,
				biome_type: 23,
				x: 45,
				y: 56,
				seed: 9171226,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 184,
				type: 22,
				biome_type: 45,
				x: 45,
				y: 61,
				seed: 33568137,
				flags: '09000',
				is_double: 1,
				master_room_x: 45,
				master_room_y: 61,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 185,
				type: 22,
				biome_type: 45,
				x: 45,
				y: 62,
				seed: 49041728,
				flags: '0B000',
				master_room_x: 45,
				master_room_y: 61,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 1,
				border_direction: 3
			},
			{
				room_id: 186,
				type: 4,
				biome_type: 1,
				x: 45,
				y: 63,
				seed: 12991080,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000065'],
				difficulty: 1,
				border: 45,
				border_direction: -1
			},
			{
				room_id: 187,
				type: 0,
				biome_type: 1,
				x: 45,
				y: 64,
				seed: 6212136,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 188,
				type: 0,
				biome_type: 3,
				x: 45,
				y: 69,
				seed: 59248942,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 189,
				type: 0,
				biome_type: 5,
				x: 46,
				y: 42,
				seed: 62781258,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004', '00011FFFFFFFF0', '00011FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 190,
				type: 0,
				biome_type: 5,
				x: 46,
				y: 43,
				seed: 47505140,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004', '00011FFFFFFFF0', '00011FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 191,
				type: 0,
				biome_type: 5,
				x: 46,
				y: 44,
				seed: 83086859,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 192,
				type: 4,
				biome_type: 5,
				x: 46,
				y: 45,
				seed: 66641994,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000007C'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 193,
				type: 10,
				biome_type: 5,
				x: 46,
				y: 46,
				seed: 81051399,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 194,
				type: 4,
				biome_type: 5,
				x: 46,
				y: 48,
				seed: 83652479,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000080'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 195,
				type: 10,
				biome_type: 5,
				x: 46,
				y: 50,
				seed: 52330515,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 196,
				type: 0,
				biome_type: 23,
				x: 46,
				y: 54,
				seed: 87683493,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 197,
				type: 0,
				biome_type: 23,
				x: 46,
				y: 56,
				seed: 47990146,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 198,
				type: 0,
				biome_type: 1,
				x: 46,
				y: 59,
				seed: 86161092,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 199,
				type: 0,
				biome_type: 1,
				x: 46,
				y: 60,
				seed: 55333589,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000', '00001FFFFFFFF00000'],
				difficulty: 2,
				border: 45,
				border_direction: -1
			},
			{
				room_id: 200,
				type: 22,
				biome_type: 45,
				x: 46,
				y: 61,
				seed: 2181432,
				flags: '0E000',
				master_room_x: 45,
				master_room_y: 61,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 1,
				border_direction: 1
			},
			{
				room_id: 201,
				type: 22,
				biome_type: 45,
				x: 46,
				y: 62,
				seed: 76451584,
				flags: '06000',
				master_room_x: 45,
				master_room_y: 61,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 202,
				type: 0,
				biome_type: 1,
				x: 46,
				y: 64,
				seed: 48693228,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 203,
				type: 21,
				biome_type: 44,
				x: 46,
				y: 66,
				seed: 32066348,
				flags: '09000',
				is_double: 1,
				master_room_x: 46,
				master_room_y: 66,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 204,
				type: 21,
				biome_type: 44,
				x: 46,
				y: 67,
				seed: 90383471,
				flags: '0B000',
				master_room_x: 46,
				master_room_y: 66,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 3,
				border_direction: 3
			},
			{
				room_id: 205,
				type: 0,
				biome_type: 3,
				x: 46,
				y: 68,
				seed: 20691361,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 0,
				border: 44,
				border_direction: -1
			},
			{
				room_id: 206,
				type: 0,
				biome_type: 3,
				x: 46,
				y: 69,
				seed: 4766680,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 207,
				type: 10,
				biome_type: 5,
				x: 47,
				y: 41,
				seed: 23296929,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 208,
				type: 0,
				biome_type: 5,
				x: 47,
				y: 42,
				seed: 45417479,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 209,
				type: 0,
				biome_type: 5,
				x: 47,
				y: 45,
				seed: 66148557,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004', '00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 210,
				type: 0,
				biome_type: 5,
				x: 47,
				y: 48,
				seed: 66184328,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 211,
				type: 0,
				biome_type: 5,
				x: 47,
				y: 49,
				seed: 36870204,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 212,
				type: 0,
				biome_type: 5,
				x: 47,
				y: 50,
				seed: 73748329,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 213,
				type: 0,
				biome_type: 23,
				x: 47,
				y: 54,
				seed: 35012812,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 214,
				type: 0,
				biome_type: 23,
				x: 47,
				y: 55,
				seed: 5660689,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 215,
				type: 0,
				biome_type: 23,
				x: 47,
				y: 56,
				seed: 61902301,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 216,
				type: 10,
				biome_type: 23,
				x: 47,
				y: 57,
				seed: 11659072,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 1,
				border_direction: 3
			},
			{
				room_id: 217,
				type: 59,
				biome_type: 1,
				x: 47,
				y: 58,
				seed: 11217834,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 23,
				border_direction: 1
			},
			{
				room_id: 218,
				type: 0,
				biome_type: 1,
				x: 47,
				y: 59,
				seed: 29122341,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 219,
				type: 0,
				biome_type: 1,
				x: 47,
				y: 64,
				seed: 70061329,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000', '00001FFFFFFFF00001'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 220,
				type: 0,
				biome_type: 1,
				x: 47,
				y: 65,
				seed: 63847673,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 0,
				border: 44,
				border_direction: -1
			},
			{
				room_id: 221,
				type: 21,
				biome_type: 44,
				x: 47,
				y: 66,
				seed: 30038399,
				flags: '0E000',
				master_room_x: 46,
				master_room_y: 66,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 1,
				border_direction: 1
			},
			{
				room_id: 222,
				type: 21,
				biome_type: 44,
				x: 47,
				y: 67,
				seed: 97932630,
				flags: '06000',
				master_room_x: 46,
				master_room_y: 66,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 223,
				type: 0,
				biome_type: 5,
				x: 48,
				y: 43,
				seed: 76727505,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 224,
				type: 0,
				biome_type: 5,
				x: 48,
				y: 44,
				seed: 3024719,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 225,
				type: 0,
				biome_type: 5,
				x: 48,
				y: 45,
				seed: 94685337,
				flags: '0F000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 226,
				type: 0,
				biome_type: 5,
				x: 48,
				y: 46,
				seed: 24617628,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004', '00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 227,
				type: 0,
				biome_type: 5,
				x: 48,
				y: 47,
				seed: 4265444,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 228,
				type: 26,
				biome_type: 5,
				x: 48,
				y: 48,
				seed: 56068237,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 229,
				type: 0,
				biome_type: 23,
				x: 48,
				y: 53,
				seed: 62899423,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00011FFFFFFFF0', '00011FFFFFFFF0'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 230,
				type: 0,
				biome_type: 23,
				x: 48,
				y: 54,
				seed: 43262910,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 231,
				type: 0,
				biome_type: 23,
				x: 48,
				y: 55,
				seed: 47212022,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 232,
				type: 0,
				biome_type: 1,
				x: 48,
				y: 59,
				seed: 27293239,
				flags: '0C000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 233,
				type: 0,
				biome_type: 1,
				x: 48,
				y: 60,
				seed: 85424770,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 234,
				type: 0,
				biome_type: 1,
				x: 48,
				y: 64,
				seed: 14578464,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 235,
				type: 0,
				biome_type: 5,
				x: 49,
				y: 43,
				seed: 64403786,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 236,
				type: 0,
				biome_type: 5,
				x: 49,
				y: 45,
				seed: 9328847,
				flags: '04000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00004', '00001FFFFFFFF00004'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 237,
				type: 10,
				biome_type: 5,
				x: 49,
				y: 47,
				seed: 43863309,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 238,
				type: 0,
				biome_type: 23,
				x: 49,
				y: 52,
				seed: 70941710,
				flags: '08000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 239,
				type: 10,
				biome_type: 23,
				x: 49,
				y: 53,
				seed: 96914615,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 240,
				type: 4,
				biome_type: 23,
				x: 49,
				y: 55,
				seed: 51725703,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000070'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 241,
				type: 20,
				biome_type: 23,
				x: 49,
				y: 56,
				seed: 55169645,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 242,
				type: 0,
				biome_type: 1,
				x: 49,
				y: 60,
				seed: 36326021,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 243,
				type: 0,
				biome_type: 1,
				x: 49,
				y: 63,
				seed: 42837535,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 244,
				type: 4,
				biome_type: 1,
				x: 49,
				y: 64,
				seed: 37028074,
				flags: '06000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000067'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 245,
				type: 21,
				biome_type: 7,
				x: 50,
				y: 41,
				seed: 45648922,
				flags: '09000',
				is_double: 1,
				master_room_x: 50,
				master_room_y: 41,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 246,
				type: 21,
				biome_type: 7,
				x: 50,
				y: 42,
				seed: 48451026,
				flags: '0B000',
				master_room_x: 50,
				master_room_y: 41,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 5,
				border_direction: 3
			},
			{
				room_id: 247,
				type: 0,
				biome_type: 5,
				x: 50,
				y: 43,
				seed: 6216977,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00002',
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: 7,
				border_direction: -1
			},
			{
				room_id: 248,
				type: 0,
				biome_type: 5,
				x: 50,
				y: 46,
				seed: 65548031,
				flags: '08000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00004',
					'00001FFFFFFFF00004',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 249,
				type: 1,
				biome_type: 5,
				x: 50,
				y: 47,
				seed: 78093365,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 250,
				type: 10,
				biome_type: 23,
				x: 50,
				y: 56,
				seed: 11909102,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 251,
				type: 0,
				biome_type: 1,
				x: 50,
				y: 60,
				seed: 34482705,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 252,
				type: 4,
				biome_type: 1,
				x: 50,
				y: 61,
				seed: 50186335,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000190'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 253,
				type: 10,
				biome_type: 1,
				x: 50,
				y: 62,
				seed: 20254278,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 254,
				type: 0,
				biome_type: 1,
				x: 50,
				y: 63,
				seed: 41663742,
				flags: '07000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 255,
				type: 21,
				biome_type: 7,
				x: 51,
				y: 41,
				seed: 38579270,
				flags: '0C000',
				master_room_x: 50,
				master_room_y: 41,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 256,
				type: 21,
				biome_type: 7,
				x: 51,
				y: 42,
				seed: 94779501,
				flags: '07000',
				master_room_x: 50,
				master_room_y: 41,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 47,
				border_direction: 0
			},
			{
				room_id: 257,
				type: 41,
				biome_type: 23,
				x: 51,
				y: 56,
				seed: 63563059,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 258,
				type: 0,
				biome_type: 1,
				x: 51,
				y: 60,
				seed: 16690393,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 259,
				type: 20,
				biome_type: 1,
				x: 51,
				y: 63,
				seed: 27137909,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 260,
				type: 0,
				biome_type: 1,
				x: 51,
				y: 64,
				seed: 1403901,
				flags: '02000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 261,
				type: 0,
				biome_type: 47,
				x: 52,
				y: 33,
				seed: 83219314,
				flags: '01000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 262,
				type: 0,
				biome_type: 47,
				x: 52,
				y: 38,
				seed: 57055660,
				flags: '01000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 263,
				type: 62,
				biome_type: 47,
				x: 52,
				y: 42,
				seed: 39135567,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 7,
				border_direction: -1
			},
			{
				room_id: 264,
				type: 0,
				biome_type: 10,
				x: 52,
				y: 47,
				seed: 89333351,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 265,
				type: 0,
				biome_type: 10,
				x: 52,
				y: 48,
				seed: 94223282,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 266,
				type: 20,
				biome_type: 10,
				x: 52,
				y: 49,
				seed: 31896321,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 267,
				type: 0,
				biome_type: 10,
				x: 52,
				y: 50,
				seed: 84865229,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 268,
				type: 10,
				biome_type: 10,
				x: 52,
				y: 51,
				seed: 50577884,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 23,
				border_direction: 3
			},
			{
				room_id: 269,
				type: 0,
				biome_type: 23,
				x: 52,
				y: 52,
				seed: 83066225,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: 10,
				border_direction: 1
			},
			{
				room_id: 270,
				type: 0,
				biome_type: 23,
				x: 52,
				y: 53,
				seed: 31493798,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 271,
				type: 1,
				biome_type: 23,
				x: 52,
				y: 54,
				seed: 26225505,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 23,
				border_direction: -1
			},
			{
				room_id: 272,
				type: 0,
				biome_type: 23,
				x: 52,
				y: 55,
				seed: 19091099,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00001',
					'00001FFFFFFFF00002',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 273,
				type: 27,
				biome_type: 23,
				x: 52,
				y: 56,
				seed: 10869737,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 274,
				type: 0,
				biome_type: 23,
				x: 52,
				y: 57,
				seed: 96673736,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 275,
				type: 4,
				biome_type: 23,
				x: 52,
				y: 58,
				seed: 18766743,
				flags: '03000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000006E'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 276,
				type: 47,
				biome_type: 1,
				x: 52,
				y: 60,
				seed: 66318783,
				flags: '05003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 277,
				type: 27,
				biome_type: 1,
				x: 52,
				y: 63,
				seed: 88880883,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 278,
				type: 0,
				biome_type: 47,
				x: 53,
				y: 33,
				seed: 27800915,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 279,
				type: 20,
				biome_type: 47,
				x: 53,
				y: 34,
				seed: 33189529,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 280,
				type: 26,
				biome_type: 47,
				x: 53,
				y: 35,
				seed: 26405203,
				flags: '03000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 281,
				type: 0,
				biome_type: 47,
				x: 53,
				y: 37,
				seed: 2211803,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 282,
				type: 20,
				biome_type: 47,
				x: 53,
				y: 38,
				seed: 29671161,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 283,
				type: 10,
				biome_type: 47,
				x: 53,
				y: 42,
				seed: 74471955,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 284,
				type: 0,
				biome_type: 10,
				x: 53,
				y: 49,
				seed: 87324697,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00141FFFFFFFF0',
					'00141FFFFFFFF0'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 285,
				type: 0,
				biome_type: 23,
				x: 53,
				y: 58,
				seed: 98911156,
				flags: '04000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00001', '00001FFFFFFFF00002'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 286,
				type: 0,
				biome_type: 1,
				x: 53,
				y: 60,
				seed: 28950315,
				flags: '0C000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 287,
				type: 0,
				biome_type: 1,
				x: 53,
				y: 61,
				seed: 75001103,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 288,
				type: 4,
				biome_type: 1,
				x: 53,
				y: 62,
				seed: 68554229,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000068'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 289,
				type: 0,
				biome_type: 1,
				x: 53,
				y: 63,
				seed: 47819794,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00000', '00001FFFFFFFF00000'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 290,
				type: 0,
				biome_type: 47,
				x: 54,
				y: 33,
				seed: 3978859,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 291,
				type: 0,
				biome_type: 47,
				x: 54,
				y: 35,
				seed: 35340011,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 292,
				type: 0,
				biome_type: 47,
				x: 54,
				y: 36,
				seed: 9242756,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 293,
				type: 0,
				biome_type: 47,
				x: 54,
				y: 37,
				seed: 78551228,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 294,
				type: 0,
				biome_type: 47,
				x: 54,
				y: 40,
				seed: 4289885,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 295,
				type: 0,
				biome_type: 47,
				x: 54,
				y: 41,
				seed: 78315623,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 296,
				type: 0,
				biome_type: 47,
				x: 54,
				y: 42,
				seed: 59527957,
				flags: '0F000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 297,
				type: 0,
				biome_type: 47,
				x: 54,
				y: 43,
				seed: 47009608,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 298,
				type: 0,
				biome_type: 10,
				x: 54,
				y: 48,
				seed: 27750601,
				flags: '08000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 299,
				type: 0,
				biome_type: 10,
				x: 54,
				y: 49,
				seed: 14732943,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0', '00141FFFFFFFF0'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 300,
				type: 0,
				biome_type: 10,
				x: 54,
				y: 50,
				seed: 37811088,
				flags: '03000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 301,
				type: 10,
				biome_type: 1,
				x: 54,
				y: 61,
				seed: 79098944,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 302,
				type: 1,
				biome_type: 1,
				x: 54,
				y: 63,
				seed: 79846432,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 303,
				type: 0,
				biome_type: 47,
				x: 55,
				y: 33,
				seed: 52343736,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 304,
				type: 10,
				biome_type: 47,
				x: 55,
				y: 34,
				seed: 21691346,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 305,
				type: 0,
				biome_type: 47,
				x: 55,
				y: 35,
				seed: 44288268,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 306,
				type: 0,
				biome_type: 47,
				x: 55,
				y: 37,
				seed: 89610680,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 307,
				type: 10,
				biome_type: 47,
				x: 55,
				y: 38,
				seed: 30919540,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 308,
				type: 0,
				biome_type: 47,
				x: 55,
				y: 39,
				seed: 79703186,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 309,
				type: 0,
				biome_type: 47,
				x: 55,
				y: 40,
				seed: 59417648,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 310,
				type: 0,
				biome_type: 47,
				x: 55,
				y: 42,
				seed: 57154476,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 311,
				type: 10,
				biome_type: 10,
				x: 55,
				y: 50,
				seed: 44132553,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 312,
				type: 0,
				biome_type: 10,
				x: 55,
				y: 51,
				seed: 63620326,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 313,
				type: 0,
				biome_type: 10,
				x: 55,
				y: 52,
				seed: 14443001,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 314,
				type: 20,
				biome_type: 10,
				x: 55,
				y: 53,
				seed: 93854007,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 315,
				type: 0,
				biome_type: 10,
				x: 55,
				y: 54,
				seed: 72948727,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 316,
				type: 0,
				biome_type: 1,
				x: 55,
				y: 61,
				seed: 5768434,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00011FFFFFFFF0',
					'00011FFFFFFFF0',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00000',
					'00001FFFFFFFF00001'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 317,
				type: 0,
				biome_type: 47,
				x: 56,
				y: 33,
				seed: 89407114,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 318,
				type: 0,
				biome_type: 47,
				x: 56,
				y: 39,
				seed: 62762005,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 319,
				type: 0,
				biome_type: 47,
				x: 56,
				y: 42,
				seed: 98051262,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 320,
				type: 4,
				biome_type: 47,
				x: 56,
				y: 43,
				seed: 15158766,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00081FFFFFFFF00000000B7'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 321,
				type: 0,
				biome_type: 47,
				x: 56,
				y: 44,
				seed: 59731663,
				flags: '02000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 322,
				type: 0,
				biome_type: 10,
				x: 56,
				y: 51,
				seed: 68161956,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 323,
				type: 0,
				biome_type: 10,
				x: 56,
				y: 54,
				seed: 34932977,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 324,
				type: 26,
				biome_type: 1,
				x: 56,
				y: 61,
				seed: 62384420,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 325,
				type: 4,
				biome_type: 47,
				x: 57,
				y: 33,
				seed: 80572707,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00081FFFFFFFF00000000B6'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 326,
				type: 21,
				biome_type: 48,
				x: 57,
				y: 36,
				seed: 5949738,
				flags: '09000',
				is_double: 1,
				master_room_x: 57,
				master_room_y: 36,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 327,
				type: 21,
				biome_type: 48,
				x: 57,
				y: 37,
				seed: 88289076,
				flags: '0B000',
				master_room_x: 57,
				master_room_y: 36,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 47,
				border_direction: 3
			},
			{
				room_id: 328,
				type: 12,
				biome_type: 47,
				x: 57,
				y: 38,
				seed: 62902939,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: 48,
				border_direction: -1
			},
			{
				room_id: 329,
				type: 0,
				biome_type: 47,
				x: 57,
				y: 39,
				seed: 15165305,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 330,
				type: 0,
				biome_type: 47,
				x: 57,
				y: 40,
				seed: 4831595,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 331,
				type: 0,
				biome_type: 10,
				x: 57,
				y: 47,
				seed: 1577802,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 332,
				type: 0,
				biome_type: 10,
				x: 57,
				y: 51,
				seed: 82288721,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00141FFFFFFFF0', '00141FFFFFFFF0'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 333,
				type: 4,
				biome_type: 10,
				x: 57,
				y: 52,
				seed: 42432691,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000008A'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 334,
				type: 0,
				biome_type: 10,
				x: 57,
				y: 53,
				seed: 21443137,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 335,
				type: 10,
				biome_type: 10,
				x: 57,
				y: 54,
				seed: 19807662,
				flags: '0E000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 336,
				type: 0,
				biome_type: 10,
				x: 57,
				y: 55,
				seed: 43607688,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 337,
				type: 0,
				biome_type: 10,
				x: 57,
				y: 57,
				seed: 33355367,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 338,
				type: 4,
				biome_type: 10,
				x: 57,
				y: 58,
				seed: 40451589,
				flags: '02000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000008E'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 339,
				type: 47,
				biome_type: 47,
				x: 58,
				y: 32,
				seed: 27803245,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 47,
				border_direction: -1
			},
			{
				room_id: 340,
				type: 0,
				biome_type: 47,
				x: 58,
				y: 33,
				seed: 19758120,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 341,
				type: 21,
				biome_type: 48,
				x: 58,
				y: 36,
				seed: 51692198,
				flags: '0D000',
				master_room_x: 57,
				master_room_y: 36,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 47,
				border_direction: 0
			},
			{
				room_id: 342,
				type: 21,
				biome_type: 48,
				x: 58,
				y: 37,
				seed: 30054765,
				flags: '06000',
				master_room_x: 57,
				master_room_y: 36,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 343,
				type: 0,
				biome_type: 10,
				x: 58,
				y: 47,
				seed: 38240115,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 344,
				type: 21,
				biome_type: 12,
				x: 58,
				y: 49,
				seed: 62981690,
				flags: '09000',
				is_double: 1,
				master_room_x: 58,
				master_room_y: 49,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 345,
				type: 21,
				biome_type: 12,
				x: 58,
				y: 50,
				seed: 92864455,
				flags: '0B000',
				master_room_x: 58,
				master_room_y: 49,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 10,
				border_direction: 3
			},
			{
				room_id: 346,
				type: 0,
				biome_type: 10,
				x: 58,
				y: 51,
				seed: 81659153,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003', '00141FFFFFFFF0'],
				difficulty: 1,
				border: 12,
				border_direction: -1
			},
			{
				room_id: 347,
				type: 0,
				biome_type: 10,
				x: 58,
				y: 55,
				seed: 38786849,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 348,
				type: 0,
				biome_type: 10,
				x: 58,
				y: 56,
				seed: 80598357,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00141FFFFFFFF0', '00141FFFFFFFF0'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 349,
				type: 0,
				biome_type: 10,
				x: 58,
				y: 57,
				seed: 13408038,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 350,
				type: 0,
				biome_type: 47,
				x: 59,
				y: 33,
				seed: 97534185,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 351,
				type: 0,
				biome_type: 47,
				x: 59,
				y: 36,
				seed: 94429192,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 48,
				border_direction: -1
			},
			{
				room_id: 352,
				type: 4,
				biome_type: 10,
				x: 59,
				y: 47,
				seed: 51151012,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF000000008D'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 353,
				type: 0,
				biome_type: 10,
				x: 59,
				y: 48,
				seed: 82816858,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003'],
				difficulty: 2,
				border: 12,
				border_direction: -1
			},
			{
				room_id: 354,
				type: 21,
				biome_type: 12,
				x: 59,
				y: 49,
				seed: 28827833,
				flags: '0E000',
				master_room_x: 58,
				master_room_y: 49,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 10,
				border_direction: 1
			},
			{
				room_id: 355,
				type: 21,
				biome_type: 12,
				x: 59,
				y: 50,
				seed: 88012847,
				flags: '06000',
				master_room_x: 58,
				master_room_y: 49,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 356,
				type: 0,
				biome_type: 10,
				x: 59,
				y: 55,
				seed: 62987626,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00141FFFFFFFF0',
					'00141FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 357,
				type: 1,
				biome_type: 10,
				x: 59,
				y: 57,
				seed: 17595024,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 358,
				type: 10,
				biome_type: 47,
				x: 60,
				y: 33,
				seed: 1638899,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 359,
				type: 0,
				biome_type: 47,
				x: 60,
				y: 34,
				seed: 7347083,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 360,
				type: 4,
				biome_type: 47,
				x: 60,
				y: 35,
				seed: 50909711,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00081FFFFFFFF00000000B9'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 361,
				type: 0,
				biome_type: 47,
				x: 60,
				y: 36,
				seed: 54767070,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009', '00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 362,
				type: 0,
				biome_type: 29,
				x: 60,
				y: 44,
				seed: 45915220,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 363,
				type: 63,
				biome_type: 29,
				x: 60,
				y: 45,
				seed: 40654801,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 10,
				border_direction: 3
			},
			{
				room_id: 364,
				type: 26,
				biome_type: 10,
				x: 60,
				y: 46,
				seed: 71020719,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 29,
				border_direction: 1
			},
			{
				room_id: 365,
				type: 0,
				biome_type: 10,
				x: 60,
				y: 47,
				seed: 88151302,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 366,
				type: 10,
				biome_type: 13,
				x: 60,
				y: 52,
				seed: 36734912,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 367,
				type: 63,
				biome_type: 13,
				x: 60,
				y: 53,
				seed: 86611682,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 10,
				border_direction: 3
			},
			{
				room_id: 368,
				type: 65,
				biome_type: 10,
				x: 60,
				y: 54,
				seed: 91014226,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 13,
				border_direction: 1
			},
			{
				room_id: 369,
				type: 0,
				biome_type: 10,
				x: 60,
				y: 55,
				seed: 24103447,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00003', '00001FFFFFFFF00003'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 370,
				type: 27,
				biome_type: 10,
				x: 60,
				y: 56,
				seed: 26469815,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 371,
				type: 0,
				biome_type: 10,
				x: 60,
				y: 57,
				seed: 81174019,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00141FFFFFFFF0',
					'00141FFFFFFFF0',
					'00141FFFFFFFF0',
					'00141FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 372,
				type: 10,
				biome_type: 10,
				x: 60,
				y: 58,
				seed: 32570419,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 373,
				type: 12,
				biome_type: 10,
				x: 60,
				y: 59,
				seed: 49102989,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 374,
				type: 0,
				biome_type: 10,
				x: 60,
				y: 60,
				seed: 97932390,
				flags: '02000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00003',
					'00001FFFFFFFF00003',
					'00141FFFFFFFF0',
					'00141FFFFFFFF0'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 375,
				type: 0,
				biome_type: 47,
				x: 61,
				y: 33,
				seed: 53570563,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 376,
				type: 0,
				biome_type: 47,
				x: 61,
				y: 36,
				seed: 34270636,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 377,
				type: 0,
				biome_type: 29,
				x: 61,
				y: 44,
				seed: 27122218,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 378,
				type: 0,
				biome_type: 13,
				x: 61,
				y: 52,
				seed: 77167023,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 379,
				type: 0,
				biome_type: 47,
				x: 62,
				y: 33,
				seed: 61601097,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 380,
				type: 12,
				biome_type: 47,
				x: 62,
				y: 34,
				seed: 45012983,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 381,
				type: 0,
				biome_type: 47,
				x: 62,
				y: 35,
				seed: 1807167,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009',
					'00001FFFFFFFF00009'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 382,
				type: 0,
				biome_type: 47,
				x: 62,
				y: 36,
				seed: 20507725,
				flags: '0F000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 383,
				type: 0,
				biome_type: 47,
				x: 62,
				y: 37,
				seed: 9784279,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 384,
				type: 0,
				biome_type: 47,
				x: 62,
				y: 38,
				seed: 78239662,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00009'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 385,
				type: 0,
				biome_type: 29,
				x: 62,
				y: 41,
				seed: 30793634,
				flags: '09000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 386,
				type: 4,
				biome_type: 29,
				x: 62,
				y: 42,
				seed: 49140788,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00081FFFFFFFF00000000AC'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 387,
				type: 0,
				biome_type: 29,
				x: 62,
				y: 43,
				seed: 53315373,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 388,
				type: 0,
				biome_type: 29,
				x: 62,
				y: 44,
				seed: 58294552,
				flags: '0E000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 389,
				type: 4,
				biome_type: 29,
				x: 62,
				y: 45,
				seed: 93309444,
				flags: '03000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00081FFFFFFFF00000000B2'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 390,
				type: 0,
				biome_type: 13,
				x: 62,
				y: 52,
				seed: 39998133,
				flags: '0C000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 391,
				type: 0,
				biome_type: 13,
				x: 62,
				y: 53,
				seed: 94701959,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 392,
				type: 10,
				biome_type: 13,
				x: 62,
				y: 60,
				seed: 99679298,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 393,
				type: 10,
				biome_type: 47,
				x: 63,
				y: 36,
				seed: 14082703,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 394,
				type: 0,
				biome_type: 47,
				x: 63,
				y: 38,
				seed: 89896434,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 395,
				type: 10,
				biome_type: 29,
				x: 63,
				y: 41,
				seed: 75317940,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 396,
				type: 0,
				biome_type: 29,
				x: 63,
				y: 43,
				seed: 78273263,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 397,
				type: 0,
				biome_type: 29,
				x: 63,
				y: 45,
				seed: 60677991,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 398,
				type: 12,
				biome_type: 13,
				x: 63,
				y: 53,
				seed: 80081543,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 399,
				type: 21,
				biome_type: 46,
				x: 63,
				y: 55,
				seed: 28127525,
				flags: '09000',
				is_double: 1,
				master_room_x: 63,
				master_room_y: 55,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 400,
				type: 21,
				biome_type: 46,
				x: 63,
				y: 56,
				seed: 57483695,
				flags: '0B000',
				master_room_x: 63,
				master_room_y: 55,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 13,
				border_direction: 3
			},
			{
				room_id: 401,
				type: 4,
				biome_type: 13,
				x: 63,
				y: 57,
				seed: 39605414,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000093'],
				difficulty: 2,
				border: 46,
				border_direction: -1
			},
			{
				room_id: 402,
				type: 0,
				biome_type: 13,
				x: 63,
				y: 58,
				seed: 29592044,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 403,
				type: 0,
				biome_type: 13,
				x: 63,
				y: 59,
				seed: 38199773,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 404,
				type: 0,
				biome_type: 13,
				x: 63,
				y: 60,
				seed: 63528659,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 405,
				type: 0,
				biome_type: 13,
				x: 63,
				y: 61,
				seed: 18606027,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 406,
				type: 10,
				biome_type: 13,
				x: 63,
				y: 63,
				seed: 91374711,
				flags: '01000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 407,
				type: 0,
				biome_type: 29,
				x: 64,
				y: 43,
				seed: 94437082,
				flags: '0D000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 408,
				type: 10,
				biome_type: 29,
				x: 64,
				y: 44,
				seed: 29630771,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 409,
				type: 0,
				biome_type: 29,
				x: 64,
				y: 45,
				seed: 1893450,
				flags: '07000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 410,
				type: 0,
				biome_type: 29,
				x: 64,
				y: 48,
				seed: 16837462,
				flags: '09000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 411,
				type: 20,
				biome_type: 29,
				x: 64,
				y: 49,
				seed: 51259226,
				flags: '02000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 412,
				type: 0,
				biome_type: 13,
				x: 64,
				y: 53,
				seed: 4772065,
				flags: '0D000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 413,
				type: 0,
				biome_type: 13,
				x: 64,
				y: 54,
				seed: 94246998,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 1,
				border: 46,
				border_direction: -1
			},
			{
				room_id: 414,
				type: 21,
				biome_type: 46,
				x: 64,
				y: 55,
				seed: 55788855,
				flags: '0E000',
				master_room_x: 63,
				master_room_y: 55,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: 13,
				border_direction: 1
			},
			{
				room_id: 415,
				type: 21,
				biome_type: 46,
				x: 64,
				y: 56,
				seed: 55193605,
				flags: '06000',
				master_room_x: 63,
				master_room_y: 55,
				objective_complete: 0,
				objects: [],
				difficulty: 0,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 416,
				type: 0,
				biome_type: 13,
				x: 64,
				y: 58,
				seed: 21546410,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 417,
				type: 0,
				biome_type: 13,
				x: 64,
				y: 61,
				seed: 4194277,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 418,
				type: 20,
				biome_type: 13,
				x: 64,
				y: 63,
				seed: 28449813,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 419,
				type: 0,
				biome_type: 29,
				x: 65,
				y: 42,
				seed: 44163885,
				flags: '08000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 420,
				type: 0,
				biome_type: 29,
				x: 65,
				y: 43,
				seed: 45283236,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 421,
				type: 26,
				biome_type: 29,
				x: 65,
				y: 45,
				seed: 31378995,
				flags: '0C000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 422,
				type: 0,
				biome_type: 29,
				x: 65,
				y: 46,
				seed: 80423831,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 423,
				type: 0,
				biome_type: 29,
				x: 65,
				y: 47,
				seed: 8039438,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 424,
				type: 0,
				biome_type: 29,
				x: 65,
				y: 48,
				seed: 64144794,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 425,
				type: 0,
				biome_type: 13,
				x: 65,
				y: 51,
				seed: 98570077,
				flags: '08000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 426,
				type: 0,
				biome_type: 13,
				x: 65,
				y: 52,
				seed: 95596096,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 427,
				type: 10,
				biome_type: 13,
				x: 65,
				y: 53,
				seed: 40429075,
				flags: '07000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 428,
				type: 0,
				biome_type: 13,
				x: 65,
				y: 58,
				seed: 92263220,
				flags: '0D000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 429,
				type: 10,
				biome_type: 13,
				x: 65,
				y: 59,
				seed: 14062537,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 430,
				type: 0,
				biome_type: 13,
				x: 65,
				y: 60,
				seed: 28303389,
				flags: '0A000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 431,
				type: 0,
				biome_type: 13,
				x: 65,
				y: 61,
				seed: 78602932,
				flags: '0F000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005',
					'00001FFFFFFFF00005'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 432,
				type: 0,
				biome_type: 13,
				x: 65,
				y: 62,
				seed: 98194593,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 433,
				type: 0,
				biome_type: 13,
				x: 65,
				y: 63,
				seed: 4334773,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 434,
				type: 4,
				biome_type: 29,
				x: 66,
				y: 47,
				seed: 36712975,
				flags: '05000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00081FFFFFFFF00000000B3'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 435,
				type: 4,
				biome_type: 13,
				x: 66,
				y: 53,
				seed: 31940507,
				flags: '05000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000095'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 436,
				type: 0,
				biome_type: 13,
				x: 66,
				y: 58,
				seed: 94406017,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 437,
				type: 0,
				biome_type: 13,
				x: 66,
				y: 61,
				seed: 85170051,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 438,
				type: 10,
				biome_type: 29,
				x: 67,
				y: 47,
				seed: 6889848,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 439,
				type: 0,
				biome_type: 13,
				x: 67,
				y: 51,
				seed: 62052283,
				flags: '09000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 440,
				type: 0,
				biome_type: 13,
				x: 67,
				y: 52,
				seed: 887471,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 441,
				type: 0,
				biome_type: 13,
				x: 67,
				y: 53,
				seed: 23130139,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 442,
				type: 26,
				biome_type: 13,
				x: 67,
				y: 54,
				seed: 98377847,
				flags: '0B000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 443,
				type: 0,
				biome_type: 13,
				x: 67,
				y: 55,
				seed: 46207843,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 444,
				type: 0,
				biome_type: 13,
				x: 67,
				y: 56,
				seed: 32022223,
				flags: '0B000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005', '00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 445,
				type: 0,
				biome_type: 13,
				x: 67,
				y: 57,
				seed: 18461258,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 446,
				type: 0,
				biome_type: 13,
				x: 67,
				y: 58,
				seed: 79700045,
				flags: '06000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 447,
				type: 47,
				biome_type: 13,
				x: 67,
				y: 60,
				seed: 88665692,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 13,
				border_direction: -1
			},
			{
				room_id: 448,
				type: 0,
				biome_type: 13,
				x: 67,
				y: 61,
				seed: 82447518,
				flags: '06000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 449,
				type: 47,
				biome_type: 29,
				x: 68,
				y: 43,
				seed: 73837111,
				flags: '08003',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 29,
				border_direction: -1
			},
			{
				room_id: 450,
				type: 70,
				biome_type: 29,
				x: 68,
				y: 44,
				seed: 82794025,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: 41,
				border_direction: -1
			},
			{
				room_id: 451,
				type: 12,
				biome_type: 29,
				x: 68,
				y: 45,
				seed: 78670425,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 452,
				type: 0,
				biome_type: 29,
				x: 68,
				y: 46,
				seed: 76340961,
				flags: '0B000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 453,
				type: 0,
				biome_type: 29,
				x: 68,
				y: 47,
				seed: 59214917,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 454,
				type: 0,
				biome_type: 29,
				x: 68,
				y: 48,
				seed: 51274223,
				flags: '0A000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 455,
				type: 0,
				biome_type: 29,
				x: 68,
				y: 49,
				seed: 47491875,
				flags: '03000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00008', '00001FFFFFFFF00008'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 456,
				type: 0,
				biome_type: 13,
				x: 68,
				y: 51,
				seed: 45787349,
				flags: '04000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 457,
				type: 20,
				biome_type: 13,
				x: 68,
				y: 54,
				seed: 13464874,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 1,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 458,
				type: 10,
				biome_type: 13,
				x: 68,
				y: 56,
				seed: 67905979,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 459,
				type: 27,
				biome_type: 29,
				x: 69,
				y: 46,
				seed: 65722967,
				flags: '04000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 460,
				type: 20,
				biome_type: 29,
				x: 69,
				y: 49,
				seed: 26014567,
				flags: '05000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 461,
				type: 0,
				biome_type: 13,
				x: 69,
				y: 56,
				seed: 51231703,
				flags: '05000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 462,
				type: 10,
				biome_type: 29,
				x: 70,
				y: 48,
				seed: 77445163,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 463,
				type: 0,
				biome_type: 29,
				x: 70,
				y: 49,
				seed: 2679680,
				flags: '06000',
				objective_complete: 0,
				objects: [
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008',
					'00001FFFFFFFF00008'
				],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 464,
				type: 0,
				biome_type: 13,
				x: 70,
				y: 55,
				seed: 76311292,
				flags: '08000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 465,
				type: 0,
				biome_type: 13,
				x: 70,
				y: 56,
				seed: 58785399,
				flags: '0E000',
				objective_complete: 0,
				objects: ['00001FFFFFFFF00005'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 466,
				type: 0,
				biome_type: 13,
				x: 70,
				y: 57,
				seed: 27663843,
				flags: '0A000',
				objective_complete: 0,
				objects: [],
				difficulty: 2,
				border: -1,
				border_direction: -1
			},
			{
				room_id: 467,
				type: 4,
				biome_type: 13,
				x: 70,
				y: 58,
				seed: 73501864,
				flags: '02000',
				objective_complete: 0,
				objects: ['00081FFFFFFFF0000000097'],
				difficulty: 2,
				border: -1,
				border_direction: -1
			}
		]
	}
];

export default GeneratedMaps;
